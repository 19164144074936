import styled from "styled-components";

import { sizes } from "utility/device";

export const StyledCookieMainContainer = styled.div<{ isVisible: boolean }>`
  position: fixed;

  right: 53px;
  bottom: 35px;

  width: 449px;
  height: 155px;

  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-around;

  background-color: ${(props) => props.theme.colors.yellow};

  border-radius: 33px;

  @media all and (max-width: ${sizes.m}) {
    margin: auto;

    left: 0;
    right: 0;
  }

  @media all and (max-width: ${sizes.s}) {
    height: 134px;
    width: 92.7%;

    margin: auto;
    right: 0;
    left: 0;
  }
`;

export const StyledInnerContainer = styled.div`
  margin: 24px 30px 24px 28px;

  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media all and (max-width: ${sizes.s}) {
    margin: 20px 20px 10px 22px;

    justify-content: space-between;
  }
`;

export const StyledCookieParagraph = styled.p`
  font-size: 18px;
  line-height: 121.8%;

  @media all and (max-width: ${sizes.s}) {
    font-size: 14px;
  }

  a {
    text-decoration: underline;
    text-underline-offset: 2.5px;
    line-height: 121.8%;
  }
`;

export const StyledCookieRow = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
`;

export const StyledAgreeRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledDismissParagraph = styled.p`
  text-decoration: underline;
  cursor: pointer;

  margin-top: 8px;
`;

export const Item = styled.label`
  display: flex;
  align-items: center;

  position: relative;

  height: 48px;
`;

export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;

  width: 25px;
  height: 25px;

  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.colors.black};

  background: transparent;
`;

export const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;

  width: 25px;
  height: 25px;

  margin-right: 10px;

  cursor: pointer;

  &:hover ~ ${RadioButtonLabel} {
    background: transparent;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;

      width: 12px;
      height: 12px;

      margin: 7px;

      background: black;
    }
  }

  ${(props) =>
    props.checked &&
    ` 
    &:checked + ${RadioButtonLabel} {
      background: transparent;

      border: 2px solid #000;

      &::after {
        content: "";
        display: block;
        border-radius: 50%;

        width: 12px;
        height: 12px;

        margin: 7px;
        
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
        background: #000;
      }
    }
  `}
`;

export const StyledRadioButtonParagraph = styled.div`
  font-size: 18px;
  line-height: 121.8%;

  margin-top: 8px;
`;
