import { genCustomPropStyle } from "src/styles/globalStyle";
import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${genCustomPropStyle({
    propName: "gap",
    maxSize: 120,
    minSize: 60,
  })}
`;
