import styled from "styled-components";

import { sizes } from "utility/device";

export const StyledContainer = styled.div`
  padding: 60px 0px;
  flex: 1;
  width: 100%;

  @media (max-width: ${sizes.mediumS}) {
    padding: 32px 0px;
  }
`;
