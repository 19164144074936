import React from "react";

import Footer from "src/components/Footer/footer";
import { StyledSeparator } from "src/components/Layout/layoutStyle";

import Form from "./Form/form";
import Hero from "components/Hero/hero";
import HeroParagraph from "components/HeroParagraph/heroParagraph";
import { PageContainer } from "src/styles/globalStyle";
import { InternshipPosition } from "utility/models";

interface IInternshipProps {
  positions: InternshipPosition[];
}

const Internship = ({ positions }: IInternshipProps): JSX.Element => {
  const title = "Internship at\nLambdaWorks"

  return (
    <PageContainer>
      <Hero
        title={title}
        image={{
          src: "hero/applyHero.png",
          mobileSrc: "hero/applyHeroMobile.png",
        }}
      />
      <HeroParagraph
        text="We're excited that you're interested in working with us — we're always looking for awesome people. For consideration, please fill out the below application. We try to respond to everyone, but sometimes it takes us a bit more time. Thanks for the patience."
      />
      <Form positions={positions} />
      <StyledSeparator />
      <Footer />
    </PageContainer>
  );
};

export default React.memo(Internship);
