import React from "react";

import Footer from "src/components/Footer/footer";
import { StyledSeparator } from "src/components/Layout/layoutStyle";

import Form from "./Form/form";
import Hero from "components/Hero/hero";
import HeroParagraph from "components/HeroParagraph/heroParagraph";
import { PageContainer } from "src/styles/globalStyle";

interface ApplyProps {
  position: string;
}

const Apply = ({ position }: ApplyProps): JSX.Element => {
  return (
    <PageContainer>
      <Hero
        title={"Work at\nLambdaWorks"}
        image={{
          src: "hero/applyHero.png",
          mobileSrc:"hero/applyHeroMobile.png",
        }}
      />
      
      <HeroParagraph
        text="We're excited that you're interested in working with us — we're always looking for awesome people. For consideration, please fill out the below application. We try to respond to everyone, but sometimes it takes us a bit more time."
        heading="Thanks for the patience."
        reverse
      />

      <Form positionType={position} />
      
      <StyledSeparator />

      <Footer />
    </PageContainer>
  );
};

export default React.memo(Apply);
