import React, { useState } from "react";
import Slider from "react-slick";
import { Link, navigate } from "gatsby";

import { isTouchDevice } from "utility/functions";
import { CarouselImageProps } from "utility/models";
import CarouselCircle from "src/components/CarouselCircle/carouselCircle";
import ImageHoverIndicator from "src/components/ImageHoverIndicator/imageHoverIndicator";

import {
  StyledCraftingContainer,
  StyledCraftingImage,
  StyledCraftingImageContainer,
  StyledCraftingRowDotMobile,
  StyledCraftingRowDotWeb,
  StyledCraftingTitle,
} from "./carouselStyle";
import { ColumnContainer, ResponsiveColumnContainer } from "src/styles/globalStyle";
import Button from "components/Button/button";

type CarouselProps = {
  images: Array<CarouselImageProps>;
  title: string | JSX.Element;
  titleWebMargin?: number;
};

const CarouselSection = ({
  images,
  title,
  titleWebMargin = 103,
}: CarouselProps): JSX.Element => {
  const [isAnimationEnabled, setIsAnimationEnabled] = useState<boolean>(true);
  const [active, setActive] = useState<CarouselImageProps | undefined>(
    images[0]
  );
  // paragraph with max char length that will be used in the background
  // in order to prevent content from "jumping" when tabs are switched
  const [hovered, setHovered] = useState<CarouselImageProps>();

  const backgroundParagraph: CarouselImageProps | undefined = images.reduce((max, current) => {
    return current.paragraph.length > max.paragraph.length ? current : max;
  }, images[0]);

  // same as backgroundParagraph, just for title
  const backgroundTitle: CarouselImageProps | undefined = images.reduce((max, current) => {
    return current.title.length > max.title.length ? current : max;
  }, images[0]);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    autoplay: isAnimationEnabled,
    speed: 500,
    autoplaySpeed: 3500,
    swipeToSlide: false,
    cssEase: "linear",
    pauseOnHover: false,
    fade: true,
    beforeChange: (_: number, next: number) => {
      setActive(images.find((item) => item.id === next));
      setHovered(undefined);
    },
  };
  let sliderRef: Slider | null = null;

  const [hidden, setHidden] = useState<boolean>(true);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [topLimit, setTopLimit] = useState<number>(0);
  const [bottomLimit, setBottomLimit] = useState<number>(0);
  const [cursor, setCursor] = useState<string>("auto");

  const updateLimits = (layout: HTMLDivElement | null): void => {
    if (layout && layout.offsetTop) {
      setTopLimit(layout.offsetTop);
      if (layout.offsetHeight) {
        setBottomLimit(layout.offsetTop + layout.offsetHeight);
      }
    }
  };

  const onMouseOver = (): void => {
    if (!isTouchDevice && hidden) {
      setHidden(false);
      setCursor("none");
    }
  };

  const onMouseLeave = (): void => {
    if (!isTouchDevice && !hidden) {
      setHidden(true);
      setCursor("auto");
    }
  };

  const onMouseMove = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    if (event.pageY < topLimit || event.pageY > bottomLimit) {
      onMouseLeave();
    } else {
      setPosition({ x: event.clientX, y: event.clientY });
    }
  };

  return (
    <StyledCraftingContainer>
      <ResponsiveColumnContainer maxGap={120} minGap={32}>
        <StyledCraftingTitle>
          <h2>{title}</h2>
        </StyledCraftingTitle>

        <ResponsiveColumnContainer maxGap={60} minGap={32}>
          <ColumnContainer gap={32}>
            <ResponsiveColumnContainer maxGap={24} minGap={8}>
              <Button
                onClick={() => navigate(active?.route ?? "")}
                text={active?.project ?? ""}
                arrowType={null}
                justifyContent="center"
                fontSize={24}
                mobileFontSize={20}
                type={1}
              />
              <h3 style={{ position: "relative", maxWidth: 580 }}>
                <div style={{ position: "absolute" }}>
                  {active?.title}
                </div>

                <div style={{ opacity: 0 }}>
                  {backgroundTitle?.title}
                </div>
              </h3>
            </ResponsiveColumnContainer>
            <h6 style={{ position: "relative", maxWidth: 580 }}>
              <div style={{ position: "absolute" }}>
                {active?.paragraph}
              </div>

              <div style={{ opacity: 0 }}>
                {backgroundParagraph?.paragraph}
              </div>
            </h6>
          </ColumnContainer>
          
          <StyledCraftingRowDotWeb>
            {images.map((item) => (
              <div
                key={item.id}
                onMouseOver={() => setHovered(item)}
                onMouseLeave={() => setHovered(active)}
              >
                <CarouselCircle
                  item={item}
                  index={item.id}
                  activeItem={active}
                  hoveredItem={hovered}
                  marginRight={11}
                  isSameId
                  onClick={() => {
                    setIsAnimationEnabled(false);
                    sliderRef?.slickGoTo(item.id, true);
                  }}
                />
              </div>
            ))}
          </StyledCraftingRowDotWeb>
        </ResponsiveColumnContainer>
      </ResponsiveColumnContainer>
      <StyledCraftingImageContainer ref={(layout) => updateLimits(layout)}>
        <Link
          to={active?.route ?? ""}
          style={{ textDecoration: "none" }}
        >
          <div
            {...(active?.route && {
              style: { cursor },
              onMouseOver,
              onMouseMove,
              onMouseLeave,
            })}
          >
            <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
              {images.map((item) => (
                <StyledCraftingImage url={item.url} key={item.url} />
              ))}
            </Slider>
          </div>
        </Link>
        <ImageHoverIndicator hidden={hidden} position={position} />
      </StyledCraftingImageContainer>
      <StyledCraftingRowDotMobile>
        {images.map((item) => (
          <div
            key={item.id}
            onMouseOver={() => setHovered(item)}
            onMouseLeave={() => setHovered(active)}
          >
            <CarouselCircle
              item={item}
              index={item.id}
              activeItem={active}
              hoveredItem={hovered}
              marginRight={11}
              isSameId
              onClick={() => {
                setIsAnimationEnabled(false);
                sliderRef?.slickGoTo(item.id, true);
              }}
            />
          </div>
        ))}
      </StyledCraftingRowDotMobile>
    </StyledCraftingContainer>
  );
};

export default React.memo(CarouselSection);
