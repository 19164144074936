import styled from "styled-components";
import { genTextStyle } from "src/styles/globalStyle";

export const Subheading = styled.div`
  ${genTextStyle({
    maxFontSize: 32,
    minFontSize: 20,
    maxLineHeight: 36.48,
    minLineHeight: 22.8,
  })}
`;
