import React, { useEffect } from "react";

import Shopify from "src/images/shopify.svg";
import DesignRush from "src/images/designrush.svg";

import {
  StyledMainContainer,
  StyledReviewsImage,
} from "./badgesStyle";
import { LogoList } from "components/LogoList/logoList";

const Badges = (): JSX.Element => {
  useEffect(() => {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.src = "https://widget.clutch.co/static/js/widget.js"
    script.async = true
    document.body.appendChild(script)

    script.onload = () => {
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      window.CLUTCHCO.Init()
    }
    return () => {
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      window.CLUTCHCO.Destroy()
      document.body.removeChild(script)
    }
  })

  return (
    <StyledMainContainer>
      <LogoList
        heading="The Badges."
        breakOnSmallDevices
        logos={[
          <StyledReviewsImage
            className="clutch-widget"
            data-url="https://widget.clutch.co"
            data-widget-type="14"
            data-nofollow="true"
            data-expandifr="true"
            data-clutchcompany-id="1207482"
          />,
          <Shopify />,
          <DesignRush />
        ]}
      />
    </StyledMainContainer>

  );
};

export default Badges;
