import React from "react";

import {
  StyledContainer,
  StyledLi,
  StyledLstContainer,
  StyledTitle,
} from "./industryKnowledgeStyled";

type IndustryKnowledgeProps = {
  title: string;
  listItems: string[];
};

const IndustryKnowledge = ({
  title,
  listItems,
}: IndustryKnowledgeProps): JSX.Element => (
  <StyledContainer>
    <StyledTitle>{title}</StyledTitle>
    <StyledLstContainer>
      <ul style={{ padding: 0, margin: 0 }}>
        {listItems.map((item, i) => (
          <StyledLi key={`${item}${i}`}>{item}</StyledLi>
        ))}
      </ul>
  </StyledLstContainer>
</StyledContainer>
);

export default IndustryKnowledge;
