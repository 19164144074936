import styled from "styled-components";
import { sizes } from "utility/device";
import { theme } from "utility/theme";

export const SelectStyled = styled.select`
  width: 200px;
  height: 318px;
  border: 1px solid red;
`;

export const OptionStyled = styled.option`
  width: 200px;
  height: 60px;
  border: 1px solid blue;
`;

export const Option = styled.div<{
  isSelected?: boolean;
  isLightColor?: boolean;
  isClickable?: boolean;
  isHidden?: boolean;
  highlightColor?: string;
}>`
  padding: 4px 20px;
  border-radius: 32px;
  font-size: 24px;
  border: 2px solid
    ${({ isLightColor, theme }) =>
      isLightColor ? theme.colors.lightGray : theme.colors.black};
  color: ${({ isLightColor, theme }) =>
    isLightColor ? theme.colors.lightGray : theme.colors.black};
  width: fit-content;
  background-color: ${({ isSelected, theme, highlightColor = theme.colors.yellowPastel }) =>
    isSelected ? highlightColor : "unset"};
  ${({ isClickable }) => isClickable && "cursor: pointer"};
  ${({ isHidden }) => isHidden && "display: none;"};

  @media (max-width: ${sizes.minS}) {
    font-size: 14px;
    padding: 2px 10px;
  }
`;

export const OptionInnerWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
`;

export const OptionName = styled.span`
  line-height: 34px;
  white-space: nowrap;
`;

export const OptionTriggerButton = styled.div<{
  open: boolean;
  isLightColor?: boolean;
}>`
  div {
    width: 1.5rem;
    height: 0.125rem;
    background: ${({ isLightColor, theme }) =>
      isLightColor ? theme.colors.lightGray : theme.colors.black};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    width: 18px;

    &:first-child {
      transform: ${({ open }) =>
        open
          ? "translateY(2px) rotate(45deg)"
          : "translateY(5px) rotate(90deg)"};
      margin-top: ${({ open }) => (open ? "15px" : "13px")};
    }

    &:nth-child(2) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
      margin-top: ${({ open }) => (open ? "unset" : "3px")};
    }
  }

  @media (max-width: ${sizes.minS}) {
    scale: 0.8;
  }
`;

export const RadioInputStyled = styled.input`
  appearance: none;
  margin: 0;
`;

export const MainWrapper = styled.div`
  margin-top: 35px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${sizes.mediumS}) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const RadioInputLabel = styled.label`
  margin: 4px 10px 10px 0px;
  cursor: pointer;
  @media (max-width: ${sizes.mediumS}) {
    margin: 0px;
  }
`;

export const OptionMultipleSelect = styled(Option)`
  @media (max-width: ${sizes.mediumS}) {
    padding: 8px 16px;
    max-width: 442px;
  }
  @media (max-width: ${sizes.minS}) {
    max-width: 327px;
  }
`;

export const NameMultipleSelect = styled(OptionName)`
  @media (max-width: ${sizes.mediumS}) {
    white-space: unset;
    font-size: 18px;
    line-height: 22px;
  }
`;
