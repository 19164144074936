import styled from "styled-components";
import { sizes } from "utility/device";

export const StyledButton = styled.button<{
  active: boolean;
  marginRight: number;
}>`
  display: flex;
  min-width: 77px;
  min-height: 77px;
  border: 2px solid ${(props) => props.theme.colors.black};
  border-radius: 50%;
  cursor: pointer;

  justify-content: center;
  align-items: center;
  margin-right: ${({ marginRight }) => `${marginRight}px`};

  font-size: 24px;
  line-height: 24px;

  color: ${({ active, theme }) =>
    active ? theme.colors.white : theme.colors.black};
  
  background: linear-gradient(to bottom, ${({ theme }) => (`${theme.colors.black}, ${theme.colors.black}`)});
  background-repeat: no-repeat;
  background-size: ${({ active }) => (active ? "100% 100%" : "100% 0")};
  transition: 0.2s;
  transform: rotate(180deg);

  @media (max-width: ${sizes.m}) {
    font-size: 18px;
    line-height: 18px;

    min-width: 64px;
    min-height: 64px;

    width: 64px;
    height: 64px;

    margin-right: 15px;
  }
`;

export const StyledButtonHover = styled.div`
  ${StyledButton}:hover {
    @media (pointer: fine) {
      color: ${({ theme }) => (theme.colors.white)};
      background-size: 100% 100%;
    }
  }
`

export const StyledButtonText = styled.div`
  transform: rotate(180deg);
`
