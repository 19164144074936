import styled from "styled-components";
import { theme } from "utility/theme";
import { sizes } from "utility/device";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1080px;
  width: inherit;
  align-self: center;
  gap: 60px;

  @media (max-width: ${sizes.mediumS}) {
    gap: 32px;
  }
`;

export const TopContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1080px;
  width: inherit;
  align-self: center;
  gap: 32px;
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${theme.colors.black};
`;

export const LoaderWrapper = styled.div`
  align-self: center;
`;

export const ButtonWrapper = styled.div`

`;

export const BlogsWrapper = styled.div<{ isError: boolean }>`
  margin-top: ${({ isError }) => (isError ? "32px" : 0)};
`;
