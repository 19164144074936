import styled from "styled-components";
import { sizes } from "utility/device";

export const StyledDottedListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledDottedPositionsListTitle = styled.p`
  font-size: 36px;
  line-height: 150%;
  letter-spacing: -0.01em;

  @media all and (max-width: ${sizes.s}) {
    font-size: 24px;
  }
`;

export const StyledDottedTitleContainer = styled.div<{
  marginBottomTitle: number;
}>`

  @media all and (min-width: ${sizes.xl}) {
    margin-bottom: ${({ marginBottomTitle }) => `${marginBottomTitle}px`};
  }

  margin-bottom: 35px !important;
  white-space: pre-wrap;

  @media all and (max-width: ${sizes.s}) {
    margin-bottom: 27px;
  }
`;

export const StyledListContainer = styled.div<{
  paragraphFont: number;
  paragraphMobileLeftPadding?: number;
}>`
  display: flex;
  flex-direction: row;

  align-items: center;

  p {
    @media all and (min-width: ${sizes.xl}) {
      font-size: 18px;
      padding-left: 28px;
    }
    
    font-size: 18px;
    line-height: 100%;
    letter-spacing: -0.01em;

    padding-left: 28px;

    @media all and (max-width: ${sizes.s}) {
      font-size: 14px;

      padding-left: ${({ paragraphMobileLeftPadding }) =>
        paragraphMobileLeftPadding
          ? `${paragraphMobileLeftPadding}px`
          : "16px"};
    }
  }
`;

export const StyledDottedListParagraphContainer = styled.div`
  margin-bottom: 36px;

  @media all and (max-width: ${sizes.m}) {
    margin-bottom: 0;
  }
`;
