import React, { lazy } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const LetsTalkSection = lazy(() => import("src/components/LetsTalkSection/letsTalkSection"));

const MoreThanCode = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMediumScreen = breakpoints.l;

  return (
    <LetsTalkSection
      link="/contact"
      title={"More than code."}
      paragraphFontSize="30px"
      paragraph={
          "If you need a team of tech-agnostic product specialists to transform your idea into a product, you’ve hit the jackpot. We’re here to realize your idea. To sketch it. To launch it. To scale it."
      }
      maxParagraphWidth={isMediumScreen ? "991px" : "100%"}
      limitParagraph={false}
      question={!isMediumScreen
        ? "Want to build your product?"
        : <div>Want to build<br />your product?</div>
      }
    />
  );
};

export default React.memo(MoreThanCode);
