import styled from "styled-components";
import { sizes } from "utility/device";
import { genTextStyle } from "src/styles/globalStyle";

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: start;
  gap: 8px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;

  @media (max-width: ${sizes.mediumS}) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
`;

export const CreatorText = styled.div`
  font-size: 18px;
  line-height: 21.6px;

  @media (max-width: ${sizes.mediumS}) {
    font-size: 14px;
    line-height: 16.8px;
  }
`;

export const NameText = styled.div`
  font-family: "Poppins";
  font-weight: 500 !important;
  ${genTextStyle({
    maxFontSize: 24,
    minFontSize: 16,
    maxLineHeight: 27.36,
    minLineHeight: 18.24,
    isCustomised: true,
  })}
`;

export const DateText = styled.div`
  ${genTextStyle({
    maxFontSize: 24,
    minFontSize: 16,
    maxLineHeight: 27.36,
    minLineHeight: 18.24,
    isCustomised: true,
  })}
  font-family: "Poppins";
`;
