import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import {
  StyledScaleYourTeamTextContainer,
  StyledScaleYourTeamFirstRow,
  StyledScaleYourTeamParagraph,
  StyledScaleYourTeamRow,
  StarVectorStyled,
  StyledScaleYourTeamRowMobile,
} from "./scaleYourTeamStyle";

const ScaleYourTeams = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isBigScreen = breakpoints.s;
  const paragraph = "We can help by forming an autonomous team that complements your existing setup and provides an opportunity to scale faster or adopt new technologies."

  return isBigScreen ? (
    <StyledScaleYourTeamRow>
      <StyledScaleYourTeamFirstRow>
        <StarVectorStyled />
      </StyledScaleYourTeamFirstRow>

      <StyledScaleYourTeamTextContainer>
        <h2>Scale your team</h2>
        <StyledScaleYourTeamParagraph>
          {paragraph}
        </StyledScaleYourTeamParagraph>
      </StyledScaleYourTeamTextContainer>
    </StyledScaleYourTeamRow>
  ) : (
    <StyledScaleYourTeamRowMobile>
      <StyledScaleYourTeamRow>
        <StyledScaleYourTeamFirstRow>
          <div>
            <h2>Scale your team</h2> 
          </div>
          <StarVectorStyled />
        </StyledScaleYourTeamFirstRow>
      </StyledScaleYourTeamRow>
      <StyledScaleYourTeamParagraph>
        {paragraph}
      </StyledScaleYourTeamParagraph>
    </StyledScaleYourTeamRowMobile>
  );
};

export default React.memo(ScaleYourTeams);
