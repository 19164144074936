import * as Yup from "yup";

import { phoneRegex } from "utility/constants";
import { emailSchema } from 'utility/yupValidator'

export const ApplySchema = Yup.object({
  name: Yup.string()
    .required("Name is required.")
    .min(2, "Name should have at least 2 letters."),
  email: emailSchema,
  phoneNumber: Yup.string().matches(phoneRegex, "Invalid phone number."),
  interest: Yup.string(),
  project: Yup.string(),
  others: Yup.string(),
});

export const ScalaSchema = Yup.object({
  company: Yup.string()
    .required("Company name is required.")
    .min(2, "Company name should have at least 2 letters.")
    .max(50, "Company name should have a maximum of 50 letters."),
  email: emailSchema,
  project: Yup.string(),
  supportRange: Yup.string()
    .trim()
    .required("Support range is required."),
});

export const SubscribeSchema = Yup.object({
  email: emailSchema,
});
