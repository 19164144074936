import React from "react";

import { PROJECT_CONTENT_DATA } from 'utility/projects'
import CarouselSection from "src/components/Carousel/carousel";
import routes from "utility/routes";
import { findStaticImg, mapStaticImgList } from "utility/functions";

const WorkAndEngagements = (): JSX.Element => {
  const projectImgs = mapStaticImgList("projects", [
    PROJECT_CONTENT_DATA.djokovic.poster,
    PROJECT_CONTENT_DATA.manilo.poster,
    PROJECT_CONTENT_DATA.bexio.poster,
  ]);

  const images = [
    {
      id: 0,
      url: findStaticImg(projectImgs, PROJECT_CONTENT_DATA.bexio.poster),
      title: "Extending development\ncapabilities",
      project: "bexio",
      route: routes.BEXIO,
      paragraph:
        "Supporting the growth of one of the most used Swiss SaaS' for small businesses, by extending their development capabilities with the help of a dedicated, standalone LambdaWorks Scala team.",
    },
    {
      id: 1,
      url: findStaticImg(projectImgs, PROJECT_CONTENT_DATA.djokovic.poster),
      title: "Supporting management\nwith business intelligence",
      project: "Novak Djokovic Foundation",
      route: routes.NOVAK_DJOKOVIC_FOUNDATION,
      paragraph:
        "Designing and developing unique data-driven tools that gather, process, visualize and pinpoint the key factors critical to the business performance.",
    },
    {
      id: 2,
      url: findStaticImg(projectImgs, PROJECT_CONTENT_DATA.manilo.poster),
      project: "Manilo",
      title: "Creating\ne-commerce shops",
      route: routes.MANILO,
      paragraph:
        "Designing and developing online shops that provide users with new experiences and open additional revenue streams for the business.",
    },
  ];

  return (
    <CarouselSection
      title="Our work & engagements."
      images={images}
      titleWebMargin={197}
    />
  );
};

export default React.memo(WorkAndEngagements);
