import styled from "styled-components";

import { sizes } from "utility/device";
import { StyledSectionTitle } from "components/SectionTitle/sectionTitleStyle";
import { ContentFrame } from "src/styles/globalStyle";

export const StyledReachPotentialTitle = styled.p`
  font-size: 5vw;
  letter-spacing: -0.01em;
  line-height: 108.3%;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 5.5%;

  @media all and (min-width: ${sizes.xl}) {
    font-size: 96px;
    line-hight: 108px;

    margin-bottom: 62px;
  }

  @media all and (max-width: ${sizes.m}) {
    width: 70%;
  }

  @media all and (max-width: ${sizes.s}) {
    width: 100%;
    font-size: 48px;
    line-height: 108.3%;
    letter-spacing: -0.01em;

    margin-left: 3px;
    margin-bottom: 19px;
  }

  @media all and (max-width: ${sizes.s}) {
   font-size: 46px;
  }
`;

export const StyledReachPotentialMainContainer = styled(ContentFrame)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 80px;
  border: 2px solid ${(props) => props.theme.colors.black};
  position: relative;
`;

export const StyledReachPotentialInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media all and (max-width: ${sizes.m}) {
    width: 100%;
    justify-content: center;
  }
`;

export const StyledReachPotentialMobileContainer = styled(ContentFrame)`
  display: flex;
  flex-direction: column;
  gap: 18px;
  border: 2px solid ${(props) => props.theme.colors.black};
  position: relative;

  @media all and (max-width: ${sizes.m}) {
    width: 100%;
    justify-content: center;
    padding: 20px;
  }
`;

export const StyledSVGlMobileContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 20px;
  margin-right: 20px;
`;

export const ReachPotentialVectorContainer = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 37px;
  margin-bottom: 35px;

  @media all and (max-width: ${sizes.m}) {
    width: 100%;
    justify-content: center;
  }
`;

export const StyledSectionTitleMobile = styled(StyledSectionTitle)`
  @media all and (max-width: ${sizes.m}) {
    width: auto;
  }
`;
