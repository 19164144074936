import React from "react";

import {
  StyledContainer,
  StyledLstContainer,
  StyledTitle,
} from "./keyValuesStyled";

type KeyValuesProps = {
  title: string;
  listItems: string[];
};

const KeyValues = ({
  title,
  listItems,
}: KeyValuesProps): JSX.Element => (
  <StyledContainer>
    <StyledTitle>{title}</StyledTitle>
    <StyledLstContainer>
      {listItems.map((item) => (
        <div key={item}>{item}</div>
      ))}
    </StyledLstContainer>
  </StyledContainer>
);

export default KeyValues;
