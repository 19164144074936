import React from "react";

import {
  StyledContainer,
} from "./whyLambdaWorks.styled";

type WhyLambdaWorksProps = {
  text: string;
};

const WhyLambdaWorks = ({
  text,
}: WhyLambdaWorksProps): JSX.Element => (
  <StyledContainer>
    <h1>{text}</h1>
  </StyledContainer>
);

export default WhyLambdaWorks;
