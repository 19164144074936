import React, { useEffect, useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import Layout from "src/components/Layout/layout";
import CookieConsent from "src/components/CookieConsent/cookieConsent";
import Footer from "src/components/Footer/footer";
import { StyledSeparator } from "src/components/Layout/layoutStyle";
import { theme } from "utility/theme";
import { LocationPropI } from "utility/models";
import Hero from "components/Hero/hero";
import { PageContainer } from "src/styles/globalStyle";

interface PageProps {
  location: LocationPropI;
}

const NotFoundPage = ({ location }: PageProps): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isDesktop = breakpoints.l;
  const locationPath = location.pathname;

  const [menuColor, setMenuColor] = useState(theme.colors.notFoundBackground);
  const [isCookieVisible, setCookieVisible] = useState<boolean>(false);

  const handleColor = (isMenuOpened: boolean) => {
    isMenuOpened
      ? setMenuColor(theme.colors.black)
      : setMenuColor(theme.colors.notFoundBackground);
  };

  useEffect(() => {
    if (isDesktop) {
      setMenuColor(theme.colors.notFoundBackground);
    }
  }, [isDesktop]);

  const handleVisibility = (a: boolean) => {
    setCookieVisible(a);
  }

  return (
    <Layout
      backgroundColor={menuColor}
      headerColor={theme.colors.white}
      setBackgroundColor={handleColor}
      location={locationPath}
      headerRoute={locationPath}
      isCookieVisible={isCookieVisible}
    >
      <PageContainer>
        <Hero
          title={"Whoops,\nthat’s a 404."}
          image={{
            src: "hero/errorHero.png",
            mobileSrc: "hero/errorHeroMobile.png",
          }}
        />
        <StyledSeparator />
        <Footer />
      </PageContainer>
      <CookieConsent getCookieVisibility={handleVisibility} />
    </Layout>
  );
};

export default NotFoundPage;
