import { DocumentNode, ApolloError } from "@apollo/client";

import { client } from "services/ClientApolo";

const mapApolloError = (error: ApolloError) => {
  const apolloError = new ApolloError(error);
  return {
    data: null,
    errors: apolloError.graphQLErrors.map((e) => ({
      message: e.message,
      extensions: {
        code: e.extensions ? e.extensions["code"] : "",
        type: e.extensions ? e.extensions["type"] : "",
      },
    })),
  };
};

export const query = async (query: DocumentNode, variables?: any) => {
  try {
    const data = await client.query({
      query,
      variables,
      fetchPolicy: "network-only",
      errorPolicy: "all",
    });
    return data;
  } catch (error) {
    return mapApolloError(error as ApolloError);
  }
};
