import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { OurCapabilitiesItem, ProjectFilterItem } from "utility/models";

import Accordion from "src/components/Accordion/accordion";

import {
  SectionHeading,
  CategorizedListWrapper,
  SectionWrapper,
  SectionDescription,
} from "./categorizedListsStyle";

interface CategorisedListProps {
  list: ProjectFilterItem[];
  align?: "start" | "center";
}

const CategorisedList = ({ list, align = "center" }: CategorisedListProps): JSX.Element | null => {
  const breakpoints = useBreakpoint();
  const isMobileView = breakpoints.m;

  return isMobileView ? (
    <CategorizedListWrapper justifyContent={align === "center" ? "space-between" : "flex-start"}>
      {list.map(
        (item: OurCapabilitiesItem, index) => (
          <SectionWrapper
            key={`${item.title}${index}`}
            alignItems={align === "center" ? "center" : "flex-start"}
          >
            <SectionHeading>{item.title}</SectionHeading>
            <SectionDescription align={align}>
              {item.paragraph.map((buletPoint, index) => (
                <div key={index}>{buletPoint}</div>
              ))}
            </SectionDescription>
          </SectionWrapper>
        )
      )}
    </CategorizedListWrapper>
  ) : (
    <Accordion list={list} />
  );
};

export default CategorisedList;
