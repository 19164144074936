import routes from 'utility/routes'
import { PROJECT_TAGS } from 'utility/models'

export const PROJECT_CONTENT_DATA = {
  superphone: {
    index: 0,
    title: "SuperPhone",
    year: 2017,
    yearString: "2017",
    subTitle: "Marketing platform for the big leagues",
    label:
      "Technology partner: backend, frontend, mobile development & business intelligence",
    images: ["superPhoneCL.webp", "superPhoneCL_1.webp", "superPhoneCL_2.webp"],
    poster: "superPhoneCL.webp",
    paragraphs: [
      "SuperPhone is a marketing platform that enhances relationships by tracking your texting and calling. SuperPhone allows for individuals and businesses of all types to communicate (via SMS) with their customers.",
      "We are a long-term tech partner to SuperPhone, which took the initial MVP from a cumbersome monolith into the microservice world. Continually shaping SuperPhone into a world-class platform for marketing and sales professionals with a team of 8 developers and a CTO.",
    ],
    route: routes.SUPER_PHONE,
    filters: [
      PROJECT_TAGS.webDevelopment,
      PROJECT_TAGS.mobileDevelopment,
      PROJECT_TAGS.businessAnalytics,
      PROJECT_TAGS.dataEngineering,
    ],
  },
  bexio: {
    index: 1,
    title: "bexio",
    year: 2021,
    yearString: "2021",
    subTitle: "Swiss-made accounting platform",
    label: "Technology partner: backend development",
    images: ["Bexio.webp", "Bexio1.webp"],
    poster: "Bexio.webp",
    paragraphs: [
      "bexio is a Swiss-based SME management platform with over 50,000 paying customers. With customer management, order processing, easy bookkeeping and much more, bexio truly helps people stay on top of things.",
      "We're a long-term technology partner to bexio, working together with various teams within bexio, helping to further grow, modernize and maintain what is an ever-growing platform.",
    ],
    route: routes.BEXIO,
    filters: [
      PROJECT_TAGS.webDevelopment,
      PROJECT_TAGS.infrastructure,
    ],
  },
  everyBite: {
    index: 8,
    title: "EveryBite",
    year: 2023,
    yearString: "2023",
    subTitle: "Transforming Food Ordering with a Dish-Centric Approach",
    label:
      "Technology partner: backend & frontend development, AI, data engineering, infrastructure, business intelligence",
    images: ["EveryBite.webp", "EveryBite_2.webp"],
    poster: "EveryBite.webp",
    paragraphs: [
      "EveryBite is a food ordering platform that shifts the focus from restaurants to dishes. The application, focused on enhancing user experience, curates a diverse selection of meals for users to choose from, exceeding the limitations of existing restaurant-centric models.",
      "We are a long-term tech partner to EveryBite, where we took on the job of web and AI development, including infrastructure and business intelligence. Helping the founding team scale the application and conquer the markets.",
    ],
    route: routes.EVERYBITE,
    filters: [
      PROJECT_TAGS.webDevelopment,
      PROJECT_TAGS.AI,
      PROJECT_TAGS.dataEngineering,
      PROJECT_TAGS.infrastructure,
    ],
  },
  themesKingdom: {
    index: 2,
    title: "ThemesKingdom",
    mobileTitle: "Themes Kingdom",
    year: 2020,
    yearString: "2020",
    subTitle: "A platform for instant website creation",
    label:
      "Technology partner: infrastructure, backend, frontend development & business intelligence",
    images: [
      "themeskingdom_1.webp",
      "themeskingdom_2.webp",
      "themesKingdomTK.webp",
    ],
    poster: "themesKingdomTK.webp",
    paragraphs: [
      "ThemesKingdom provides premium WordPress themes to around 137,000 customers, and counting. The team at TK decided that they want to extend the service aspects of their business with a new product. That's where we come in.",
      "The idea was to make a site-as-a-service product that would allow TK customers to instantly create and host websites using TK themes. We got working on the infrastructure architecture and end-user application, while the crew at TK worked on the immaculate design.",
      "The result is a platform that automates a lot of the processes that were handled manually, either by the user or an outside developer. We also had to take into account that the platform had to be highly scalable while allowing for website isolation, and also to leave room for hosting costs optimization.",
    ],
    route: routes.THEMES_KINGDOM,
    filters: [
      PROJECT_TAGS.webDevelopment,
      PROJECT_TAGS.businessAnalytics,
      PROJECT_TAGS.infrastructure,
    ],
  },
  element: {
    index: 3,
    title: "el3ment",
    year: 2021,
    yearString: "2021",
    subTitle: "A social network built for music creators.",
    label: "Technology partner: backend & mobile development ",
    images: ["el3ment_1.webp", "el3ment_2.webp", "el3ment_3.webp"],
    poster: "el3ment_1.webp",
    paragraphs: [
      "el3ment is a social network for music creators around the world, allowing users to discover and connect with creative partners without language or location barriers. The mobile app enables the search for collaborators globally, adding a new layer of access and creativity to the music creation process.",
      "With a team of 4 engineers, handling mobile and backend development, we helped the el3ment founding team transform their groundbreaking idea into reality. Working closely with the product designer we’ve shaped and continue to shape el3ment into a mobile app that is set to change the way music collaborations are made and essentially the way musicians create their tunes.",
    ],
    route: routes.EL3MENT,
    filters: [
      PROJECT_TAGS.mobileDevelopment,
      PROJECT_TAGS.AI,
      PROJECT_TAGS.infrastructure,
      PROJECT_TAGS.productDiscovery,
    ],
  },
  traderFyles: {
    index: 4,
    title: "TraderFyles",
    year: 2021,
    yearString: "2021",
    subTitle: "Revolutionizing the way trading tax reports are made",
    label:
      "Product development: product management, backend and frontend development, infrastructure",
    images: [
      "TraderFyles_case_study_A.jpg",
      "TraderFyles_case_study_B.png",
      "TraderFyles_case_study_C.jpg",
    ],
    poster: "TraderFyles_case_study_A.jpg",
    paragraphs: [
      "TraderFyles is a platform that handles everything a trader needs to file their trader tax forms. From wash sales and capital gains to trade reconciliation and automatic generation of IRS-ready tax forms.",
      "We started our journey with baby steps. Beginning with a series of initial product workshops, that resulted in us advising that we build the initial proof of concept to validate the idea. Once it was clear that the PoC works we set in motion the plan to deliver a scalable platform. Since then our team has been there to support the product's growth.",
    ],
    route: routes.TRADER_FYLES,
    filters: [
      PROJECT_TAGS.productDiscovery,
      PROJECT_TAGS.webDevelopment,
      PROJECT_TAGS.infrastructure,
    ],
  },
  exportOnline: {
    index: 5,
    title: "ExportOnline",
    year: 2023,
    yearString: "2023",
    subTitle: "Making vehicle exports a breeze",
    label:
      "Product development: product management, product design, backend and frontend development",
    images: ["ExportOnline_case_study_A.jpg", "ExportOnline_case_study_B.jpg"],
    poster: "ExportOnline_case_study_A.jpg",
    paragraphs: [
      "Export Online is an online service that provides customs clearance procedures for the export of vehicles. The service simplifies and accelerates export procedures for physical persons, companies and even forwarding companies.",
      "The company management came to us with an existing app, that had problems with downtime, reliability, and general bad user experience. Our goal was to transform the user-facing part of the platform as well as the back office used by employees. We started by simplifying the user experience and reworking the product. Making a better experience for the users and creating a better work process for the employees. Then we started building the application on a stack that allows for more stability and scalability, resulting in more business opportunities.",
    ],
    route: routes.EXPORT_ONLINE,
    filters: [
      PROJECT_TAGS.productDiscovery,
      PROJECT_TAGS.webDevelopment,
      PROJECT_TAGS.infrastructure,
    ],
  },
  nfme: {
    index: 6,
    title: "NF.Me",
    year: 2022,
    yearString: "2022",
    subTitle: "The social platform for digital assets.",
    label:
      "Technology partner: product design, backend, frontend and web3 development",
    images: ["nf.me_case_study_A.jpg", "nf.me_case_study_B.jpg"],
    poster: "nf.me_case_study_A.jpg",
    paragraphs: [
      "NF.Me is a social platform created for digital asset investors. The platform allows users to link multiple wallets and exchanges, showcase a full view of their NFT and crypto holdings, and track portfolio performance.",
      "Together with the founders, we brainstormed the ideas that we then visualized through the UI. The team in parallel started research on how to do the technical implementation. Once all was validated we began the development of the web platform. Our team is actively involved in the further enhancement of the platform.",
    ],
    route: routes.NFME,
    filters: [
      PROJECT_TAGS.productDiscovery,
      PROJECT_TAGS.webDevelopment,
      PROJECT_TAGS.web3,
      PROJECT_TAGS.businessAnalytics,
      PROJECT_TAGS.infrastructure,
    ],
  },
  bizzit: {
    index: 7,
    title: "BizzIt",
    year: 2019,
    yearString: "2019",
    subTitle: "B2B data experience platform",
    label:
      "Product development: infrastructure, backend & frontend development",
    images: ["BizzitCL.webp"],
    poster: "BizzitCL.webp",
    paragraphs: [
      "BizzIt's SaaS platform crowdsources approved business professionals' insight into other businesses and displays relevant intel sourced based on industry focuses and targets. Helping B2B professionals be efficient by finding new business opportunities.",
      "Our principal challenge was to deliver a web-based platform that is flexible, allowing easy integrations with other platforms and easy customization. On top of that, we have developed a standalone SalesForce application, so BizzIt users would be able to import review data directly from SalesForce.",
    ],
    route: routes.BIZZIT,
    filters: [
      PROJECT_TAGS.webDevelopment,
      PROJECT_TAGS.AI,
      PROJECT_TAGS.infrastructure,
    ],
  },
  lifeLog: {
    index: 9,
    title: "LifeLogs",
    year: 2020,
    yearString: "2020",
    subTitle: "A startup focusing on preserving one's memories",
    label:
      "Product development: mobile & backend development, product management",
    images: ["lifelogsCL.webp", "lifelogs_02.webp", "lifelogs_03.webp"],
    poster: "lifelogs_03.webp",
    paragraphs: [
      "LifeLogs is an autobiographical experience startup focusing on preserving one's memories. The vision behind LifeLogs is to provide people with a Netflix-like experience and access to their and their family's life stories.",
      "What is our engagement with LifeLogs? Product management and technology. Transforming an idea into a working product one step at a time. From initial technical consulting to product discovery workshops, to delivering a working mobile application.",
    ],
    route: routes.LIFE_LOGS,
    filters: [
      PROJECT_TAGS.webDevelopment,
      PROJECT_TAGS.mobileDevelopment,
      PROJECT_TAGS.productDiscovery,
      PROJECT_TAGS.infrastructure,
    ],
  },
  djokovic: {
    index: 10,
    title: "Novak Djokovic Foundation",
    year: 2019,
    yearString: "2019",
    subTitle: "Data engineering for a true cause",
    label: "Technology consulting: data science",
    images: ["NDF1.webp", "NovakDjokovicCL.webp"],
    poster: "NovakDjokovicCL.webp",
    paragraphs: [
      "The NDF is a global charity foundation focused on early-stage child education and development. When we heard that the NDF team was lacking the right tools to help them decide where they should invest their resources towards improving preschool education, we decided to give a helping hand.",
      "We designed and developed a unique data-driven analytics tool that gathers, processes, visualizes and pinpoints the key factors critical to early-stage child development, allowing the team at NDF to better manage their resources, as well as track the performance of their projects in local communities. On top of that, as an added benefit they can present the results of their efforts to donors and the public in an objective data-driven way.",
    ],
    route: routes.NOVAK_DJOKOVIC_FOUNDATION,
    filters: [
      PROJECT_TAGS.businessAnalytics,
      PROJECT_TAGS.dataEngineering,
      PROJECT_TAGS.infrastructure,
    ],
  },
  manilo: {
    index: 11,
    title: "MANILO",
    archiveTitle: "Manilo",
    year: 2020,
    yearString: "2020",
    subTitle: "e-commerce for high fashion",
    label: "e-commerce development: backend, frontend & integrations",
    images: ["ManiloCL.webp"],
    poster: "ManiloCL.webp",
    paragraphs: [
      "MANILO is a Swiss retailer specializing in men's business attire. The founding team wanted to add value to the brand by making a unique shopping experience – an online fashion statement, being able to 100% customize one's business shirts.",
      "The fact that all items are infinitely customizable required us to build a custom engine for choosing various options and instant rendering of the apparel previews, while directly creating orders for the manufacturing. To simplify the shop maintenance and further sales/marketing integrations, we decided to run the store on Shopify CMS.",
    ],
    route: routes.MANILO,
    filters: [ PROJECT_TAGS.ecommerce ],
  },
  nagnag: {
    index: 12,
    title: "NagNag",
    year: 2021,
    yearString: "2021",
    subTitle: "Creating a task force from your network",
    label:
      "Product development: infrastructure, backend & frontend development, business intelligence & product management",
    images: ["nagnag_1.webp", "nagnagCL.webp"],
    poster: "nagnagCL.webp",
    paragraphs: [
      "NagNag is a task management platform that leverages the power of SMS messaging to ensure reliable and hassle-free execution.",
      "NagNag has a simple idea: to bring order to chaos in organizations where there is no streamlined process. The idea is simple, but the technical challenges were not. Generative software design, progressive standards, and complex integrations allow NagNag to be a flexible and adaptable piece of technology.",
    ],
    route: routes.NAG_NAG,
    filters: [
      PROJECT_TAGS.webDevelopment,
      PROJECT_TAGS.mobileDevelopment,
      PROJECT_TAGS.lwProduct,
      PROJECT_TAGS.infrastructure,
    ],
  },
  herald: {
    index: 13,
    title: "Herald",
    year: 2023,
    yearString: "2023",
    subTitle: "Elevating the Notifications Game",
    label:
      "Product development: infrastructure, backend & frontend development, business intelligence & product management",
    images: ["Herald_case_study_1.png"],
    poster: "Herald_case_study_1.png",
    paragraphs: [
      "Herald is a notification management platform that ensures pertinent information reaches the intended recipient.",
      "Herald has a simple premise behind it. In a world flooded with information, it's essential to stay on top of one's priorities. Herald is designed as a mobile application that is versatile enough to be a platform and supports many different environments and a multitude of use cases through customization options.",
    ],
    route: routes.HERALD,
    filters: [
      PROJECT_TAGS.infrastructure,
      PROJECT_TAGS.mobileDevelopment,
      PROJECT_TAGS.lwProduct,
    ],
  },
  knowle: {
    index: 14,
    title: "Knowle",
    year: 2023,
    yearString: "2023",
    subTitle: "The Knowledge Bot for Any Organization",
    label:
      "Bot development: backend development, business intelligence & product management",
    images: ["knowle_case_study_1.png"],
    poster: "knowle_case_study_1.png",
    paragraphs: [
      "Knowle is a chatbot platform designed to simplify knowledge-base management and support in-house agents. It helps businesses efficiently manage information and engage with users through a web application, beacon widget, or Slack integration.",
      "Knowle is built on a robust foundation of TypeScript, incorporating progressive technologies such as Langchain for enhanced language processing and Milvus for efficient database management. It integrates seamlessly with Google Docs and retains IAM rights management, ensuring security and ease of use.",
    ],
    route: routes.KNOWLE_PROJECT,
    filters: [ PROJECT_TAGS.openSource ],
  },
  lambert: {
    index: 15,
    title: "Lambert",
    year: 2021,
    yearString: "2021",
    subTitle: "Tidying Up the Office",
    label:
      "Product development: infrastructure, backend & frontend development, business intelligence & product management",
    images: ["lambert_case_study_1.png"],
    poster: "lambert_case_study_1.png",
    paragraphs: [
      "Lambert serves as an office utility Slack bot, addressing various routine office tasks and handling some of the loose ends.",
      "Lambert came to be out of our own pure need. We had some common office elements that needed to be solved, with a little bit of fun between the lines. As most tech companies use Slack, we decided to build Lambert as a Slack bot, leveraging Slack's framework and designing a custom backend.",
    ],
    route: routes.LAMBERT,
    filters: [
      PROJECT_TAGS.lwProduct,
      PROJECT_TAGS.infrastructure,
    ],
  },
  shelfie: {
    index: 16,
    title: "Shelfie",
    year: 2023,
    yearString: "2023",
    subTitle: "Shopify Sales Management Simplified",
    label:
      "Product development: infrastructure, backend & frontend development, business intelligence & product management",
    images: ["Shelfie_case_study_1.png"],
    poster: "Shelfie_case_study_1.png",
    paragraphs: [
      "Shelfie is a Shopify app that allows merchants to seamlessly manage their sales and product collections, enhancing the Shopify merchant experience.",
      "We built Shelfie when one of our client-partners mentioned the underlying issue he had when making sporadic sales campaigns in his store. We saw this as a great opportunity to build a Shopify app and help the merchant community. Shelfie is built inside the Shopify app ecosystem using Polaris as a framework and a custom backend.",
    ],
    route: routes.SHELFIE,
    filters: [
      PROJECT_TAGS.webDevelopment,
      PROJECT_TAGS.lwProduct,
      PROJECT_TAGS.infrastructure,
    ],
  },
  zio: {
    index: 17,
    title: "ZIO ES",
    year: 2023,
    yearString: "2023",
    subTitle: "Boosting the ZIO ecosystem",
    label: "Library development: backend development",
    images: ["zio_Elasticsearch_case_study_1.png"],
    poster: "zio_Elasticsearch_case_study_1.png",
    paragraphs: [
      "We've developed a ZIO-native library that facilitates seamless communication with Elasticsearch clusters. This library offers a type-safe API, enhancing the developer experience for tasks like indexing documents and constructing queries for searching, filtering, and aggregating data. It also provides convenient tools for decoding results, obtaining highlights, inner-hits, and typed aggregations.",
      "ZIO Elasticsearch harnesses the potent ZIO ecosystem, incorporating well-known libraries like ZIO Schema, ZIO Prelude, and ZIO Json, along with the popular Sttp library. ",
    ],
    route: routes.ZIOES,
    filters: [ PROJECT_TAGS.openSource ],
  },
  posta: {
    index: 18,
    title: "Posta Kopilot",
    year: 2024,
    yearString: "2024",
    subTitle: "AI Copilot for the National Post Provider",
    label: "Product development: product management, product design, backend and frontend development",
    images: ["posta_mobile_app_development.jpg", "posta_mobile_app_development_1.jpg"],
    poster: "posta_mobile_app_development.jpg",
    paragraphs: [
      "Posta Kopilot is an AI-powered application designed to enhance the efficiency of the manual mail sorting process for the National Post of Serbia, which handles over 2,100,000 pieces of mail daily.",
      "Posta Kopilot has been developed to increase the efficiency of the manual sorting process by over 20% — using AI. This has a significant impact on the Post's everyday operations and financials. Another major benefit is that it drastically reduced the employee onboarding process from several months to a few weeks.",
      "The development of Kopilot was facilitated through Serbia's Innovation Fund GovTech initiative, which aims to solve significant problems in the public sector by providing private companies with opportunities to use innovative technologies to address major public issues.",
    ],
    route: routes.POSTA_KOPILOT,
    filters: [
      PROJECT_TAGS.productDiscovery,
      PROJECT_TAGS.AI,
      PROJECT_TAGS.mobileDevelopment,
      PROJECT_TAGS.infrastructure,
    ],
  },
  noon: {
    index: 19,
    title: "NOON",
    year: 2024,
    yearString: "2024",
    subTitle: "Edible wellness e-commerce store",
    label: "Product development: product management, product design, backend and frontend development",
    images: ["noon_cases_study.jpg"],
    poster: "noon_cases_study.jpg",
    paragraphs: [
      "Operating out of New York, Noon is at the forefront of next-generation cognitive wellness. They've designed and developed a range of functional mushroom delights that bridge the gap between neuroscience and natural health.",
      "We developed a headless storefront for Noon, featuring a highly performant custom design, and leveraged the latest Shopify technology for the storefront implementation.",
    ],
    route: routes.NOON,
    filters: [ PROJECT_TAGS.ecommerce ],
  },
}

export const PROJECT_IMAGES_MAP = [
  ...PROJECT_CONTENT_DATA.superphone.images,
  ...PROJECT_CONTENT_DATA.bexio.images,
  ...PROJECT_CONTENT_DATA.everyBite.images,
  ...PROJECT_CONTENT_DATA.themesKingdom.images,
  ...PROJECT_CONTENT_DATA.element.images,
  ...PROJECT_CONTENT_DATA.manilo.images,
  ...PROJECT_CONTENT_DATA.djokovic.images,
  ...PROJECT_CONTENT_DATA.lifeLog.images,
  ...PROJECT_CONTENT_DATA.bizzit.images,
  ...PROJECT_CONTENT_DATA.nagnag.images,
  ...PROJECT_CONTENT_DATA.traderFyles.images,
  ...PROJECT_CONTENT_DATA.nfme.images,
  ...PROJECT_CONTENT_DATA.exportOnline.images,
  ...PROJECT_CONTENT_DATA.herald.images,
  ...PROJECT_CONTENT_DATA.knowle.images,
  ...PROJECT_CONTENT_DATA.lambert.images,
  ...PROJECT_CONTENT_DATA.shelfie.images,
  ...PROJECT_CONTENT_DATA.zio.images,
  ...PROJECT_CONTENT_DATA.posta.images,
  ...PROJECT_CONTENT_DATA.noon.images,
]

export const PROJECT_POSTER_MAP = [
  PROJECT_CONTENT_DATA.superphone.poster,
  PROJECT_CONTENT_DATA.bexio.poster,
  PROJECT_CONTENT_DATA.everyBite.poster,
  PROJECT_CONTENT_DATA.themesKingdom.poster,
  PROJECT_CONTENT_DATA.element.poster,
  PROJECT_CONTENT_DATA.manilo.poster,
  PROJECT_CONTENT_DATA.djokovic.poster,
  PROJECT_CONTENT_DATA.lifeLog.poster,
  PROJECT_CONTENT_DATA.bizzit.poster,
  PROJECT_CONTENT_DATA.nagnag.poster,
  PROJECT_CONTENT_DATA.traderFyles.poster,
  PROJECT_CONTENT_DATA.nfme.poster,
  PROJECT_CONTENT_DATA.exportOnline.poster,
  PROJECT_CONTENT_DATA.herald.poster,
  PROJECT_CONTENT_DATA.knowle.poster,
  PROJECT_CONTENT_DATA.lambert.poster,
  PROJECT_CONTENT_DATA.shelfie.poster,
  PROJECT_CONTENT_DATA.zio.poster,
  PROJECT_CONTENT_DATA.posta.poster,
  PROJECT_CONTENT_DATA.noon.poster,
]
