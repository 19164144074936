import styled from "styled-components";
import { sizes } from "utility/device";

export const StyledListContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;

  p {
    font-size: 30px;
    line-height: 36px;

    @media all and (max-width: ${sizes.m}) {
      font-size: 18px;
      line-height: 23.4px;
    }
  }

  @media all and (max-width: ${sizes.m}) {
    gap: 12px;
  }
`;

export const StyledDottedListParagraphContainer = styled.div`
  @media all and (max-width: ${sizes.m}) {
    margin-bottom: 32px;
  }
`;
