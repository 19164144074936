import React, { useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import { useLocation } from "@reach/router";
import queryString from "query-string";

import { PROJECT_POSTER_MAP } from 'utility/projects'
import ImageHoverIndicator from "src/components/ImageHoverIndicator/imageHoverIndicator";
import { projectArchive } from "utility/lists";
import { findStaticImg, isTouchDevice, mapStaticImgList } from "utility/functions";
import Footer from "src/components/Footer/footer";
import SectionAnimation from "src/components/SectionAnimation/sectionAnimation";
import {
  ProjectsItem,
  ProjectFilters as ProjectFiltersT,
  PROJECT_TAGS,
} from "utility/models";
import { ButtonType, theme } from "utility/theme";
import { StyledSeparator } from "src/components/Layout/layoutStyle";
import SelectMultipleFields from "src/components/SelectField/selectField";
import {
  ButtonWrapper,
  ClearFilters,
  FilterLabel,
  FilterLabelWrapper,
  FilterWrapper,
  FiltersHeading,
  NoResultsHeading,
  NoResultsParagraph,
  NoResultsWrapper,
  StyledArchiveContent,
  StyledArchiveGrid,
  StyledArchiveImage,
  StyledArchiveItemContainer,
  StyledArchiveMobileContent,
  StyledArchiveTitle,
  StyledArchiveYear,
  StyledBottomArchiveGrid,
  StyledSeparatorFullWidth,
} from "./ourWorkStyle";
import { PageContainer } from "src/styles/globalStyle";
import Button from "components/Button/button";

const allProjects: ProjectFiltersI[] = [
  {
    filter: PROJECT_TAGS.mobileDevelopment,
    isActive: false,
  },
  {
    filter: PROJECT_TAGS.webDevelopment,
    isActive: false,
  },
  {
    filter: PROJECT_TAGS.businessAnalytics,
    isActive: false,
  },
  {
    filter: PROJECT_TAGS.AI,
    isActive: false,
  },
  {
    filter: PROJECT_TAGS.infrastructure,
    isActive: false,
  },
  {
    filter: PROJECT_TAGS.productDiscovery,
    isActive: false,
  },
  {
    filter: PROJECT_TAGS.web3,
    isActive: false,
  },
  {
    filter: PROJECT_TAGS.lwProduct,
    isActive: false,
  },
  {
    filter: PROJECT_TAGS.openSource,
    isActive: false,
  },
  {
    filter: PROJECT_TAGS.dataEngineering,
    isActive: false,
  },
  {
    filter: PROJECT_TAGS.ecommerce,
    isActive: false,
  }
];

interface GridProps {
  project: ProjectsItem;
  isLastItem?: boolean;
  isMobileView: boolean;
  buttonType?: ButtonType;
  filters: Array<ProjectFiltersI>;
}

interface ProjectFiltersI {
  filter: ProjectFiltersT;
  isActive: boolean;
}

interface WebViewArchiveItemProps extends GridProps {
  i: number;
  data: string[];
  filters: Array<ProjectFiltersI>;
}

const OurWork = (): JSX.Element => {
  const imagePaths = mapStaticImgList("projects", PROJECT_POSTER_MAP);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);


  const [filters, selectFilters] = useState<Array<ProjectFiltersI>>(allProjects);

  useEffect(() => {
    const { search } = location;
    const searchQuery = queryString.parse(search)
    if (searchQuery) {
      const { filter = '' } = searchQuery || {}
      // @ts-ignore
      const queryString = decodeURIComponent(filter);
      const filterList = queryString.split(',')
      if (filterList) {
        setFilters(filterList);
      }
    }
  }, [])

  const setFilters = (filterList: string[]) => {
    const updatedFilters = filters.map((f) => {
      if (filterList.includes(f.filter)) {
        return { ...f, isActive: !f.isActive };
      }
      return f;
    })
    selectFilters(updatedFilters);
  }

  const selectFilter = (filter: string) => {
    const activeItems: string[] = []
    const updatedFilters = filters.map((f) => {
      if (f.filter === filter) {
        if (!f.isActive) {
          activeItems.push(f.filter);
        }
        return { ...f, isActive: !f.isActive };
      }
      if (f.isActive) {
        activeItems.push(f.filter);
      }
      return f;
    });
    const queryString = encodeURIComponent(activeItems.join(','))
    if (queryString) {
      searchParams.set('filter', queryString);
    } else {
      searchParams.delete('filter');
    }
    navigate(`${location.pathname}?${searchParams.toString()}`);
    selectFilters(updatedFilters);
  };

  const selectedFilters = filters.filter((f) => {
    if (f.isActive) {
      return f.filter;
    }
  });

  const clearFilters = () => {
    const updatedFilters = filters.map((f) => {
      return { ...f, isActive: false };
    });
    selectFilters(updatedFilters);
    navigate(location.pathname);
  };

  const selectedFiltersObjects = projectArchive.filter((p) => {
    if (!selectedFilters.length) {
      return p;
    } else if (!selectedFilters.find((f) => !p.filters.includes(f.filter))) {
      return p;
    }
  });

  return (
    <PageContainer>
      <div>
        <FiltersHeading>Filters:</FiltersHeading>
        <FilterWrapper paddingBottom>
          {filters.map((filter, i) => (
            <SelectMultipleFields
              selectOption={selectFilter}
              optionName={filter.filter}
              isActive={filter.isActive}
              key={`${filter}${i}`}
              highlightColor={theme.colors.pink}
            />
          ))}
          <ClearFilters onClick={clearFilters}>Clear all filters</ClearFilters>
        </FilterWrapper>
        <StyledSeparatorFullWidth />
        {selectedFiltersObjects.length ? (
          selectedFiltersObjects.map((p, i) => (
            <div key={`${p.title}${i}`}>
              <SectionAnimation forceAnimation={!i}>
                <>
                  <WebViewArchiveItem
                    project={p}
                    i={i}
                    data={imagePaths}
                    isMobileView={false}
                    isLastItem={i === selectedFiltersObjects?.length - 1}
                    filters={filters}
                  />
                  <StyledArchiveMobileContent>
                    <Grid
                      project={p}
                      isLastItem={i === selectedFiltersObjects?.length - 1}
                      buttonType={ButtonType.TRANSPARENT}
                      isMobileView
                      filters={filters}
                    />
                  </StyledArchiveMobileContent>
                </>
              </SectionAnimation>
            </div>
          ))
        ) : (
          <>
            <NoResultsWrapper>
              <NoResultsHeading>No matching results</NoResultsHeading>
              <NoResultsParagraph>
                Feel free to adjust your criteria or explore other options to find
                what you're looking for.
              </NoResultsParagraph>
            </NoResultsWrapper>
            <StyledSeparator></StyledSeparator>
          </>
        )}
      </div>

      <StyledSeparator />

      <Footer />
    </PageContainer>

  );
};

const WebViewArchiveItem = ({
  project,
  i,
  data,
  isLastItem,
  isMobileView,
  filters,
}: WebViewArchiveItemProps): JSX.Element => {
  const [hidden, setHidden] = useState<boolean>(true);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [topLimit, setTopLimit] = useState<number>(0);
  const [bottomLimit, setBottomLimit] = useState<number>(0);
  const [cursor, setCursor] = useState<string>("auto");

  const updateWidthLimits = (layout: HTMLDivElement | null): void => {
    if (layout && layout.offsetTop) {
      setTopLimit(layout.offsetTop);
    }
  };

  const updateHeightLimits = (layout: HTMLDivElement | null): void => {
    if (layout && layout.offsetHeight) {
      setBottomLimit(layout.offsetTop + layout.offsetHeight);
    }
  };

  const onMouseOver = (): void => {
    if (!isTouchDevice && hidden) {
      setHidden(false);
      setCursor("none");
    }
  };

  const onMouseLeave = (): void => {
    if (!isTouchDevice && !hidden) {
      setHidden(true);
      setCursor("auto");
    }
  };

  const onMouseMove = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    if (event.pageY < topLimit || event.pageY > bottomLimit) {
      onMouseLeave();
    } else {
      setPosition({ x: event.clientX, y: event.clientY });
    }
  };

  return (
    <StyledArchiveItemContainer
      lastItem={i === projectArchive.length - 1}
      firstItem={i === 0}
      ref={(layout) => updateHeightLimits(layout)}
    >
      <Link to={project.route}>
        <StyledArchiveImage
          src={findStaticImg(data, project.poster)}
          style={{ cursor }}
          ref={(layout) => updateWidthLimits(layout)}
          onMouseOver={onMouseOver}
          onMouseMove={onMouseMove}
          onMouseLeave={onMouseLeave}
        >
          <ImageHoverIndicator hidden={hidden} position={position} />
        </StyledArchiveImage>
      </Link>
      <StyledArchiveContent>
        <Grid
          project={project}
          isLastItem={isLastItem}
          buttonType={ButtonType.TRANSPARENT}
          isMobileView={isMobileView}
          filters={filters}
        />
      </StyledArchiveContent>
    </StyledArchiveItemContainer>
  );
};

const Grid = ({
  project,
  buttonType,
  isMobileView,
  isLastItem,
  filters,
}: GridProps): JSX.Element => {
  const isHidden = (filter: ProjectFiltersT) => {
    const activeFilters = filters.filter((f) => f.isActive);
    if (activeFilters.length) {
      return !activeFilters.map((f) => f.filter).includes(filter);
    } else {
      return false;
    }
  };

  return (
    <StyledArchiveGrid hideBorder={isLastItem}>
      <StyledBottomArchiveGrid>
        <div>
          <StyledArchiveYear>{project.yearString}</StyledArchiveYear>
          <StyledArchiveTitle to={project.route}>
            <h2>
              {isMobileView && project.mobileTitle
                ? project.mobileTitle
                : project.title}
            </h2>
          </StyledArchiveTitle>
          <FilterLabelWrapper>
            {project.filters.map((filter, i, a) => (
              <FilterLabel isHidden={isHidden(filter)} key={`${filter}${i}`}>
                {filter}
                {i < a.length - 1 && ","}
              </FilterLabel>
            ))}
          </FilterLabelWrapper>
        </div>
        <ButtonWrapper>
          <Button
            onClick={() => navigate(project.route)}
            text="View Project"
            fontSize={24}
            mobileFontSize={24}
            arrowType={null}
            justifyContent="center"
            type={buttonType}
          />
        </ButtonWrapper>
      </StyledBottomArchiveGrid>
    </StyledArchiveGrid>
  );
};

export default OurWork;
