import styled from "styled-components";
import { sizes } from "utility/device";
import { Link } from "gatsby";

export const StyledCraftingContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;

  justify-content: space-between;
  align-items: center;

  @media all and (max-width: ${sizes.m}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledCraftingTitle = styled.p`
`;

export const StyledCraftingImageContainer = styled.div`
  flex: 1 auto;
  position: relative;

  align-items: center;

  width: 30%;
  max-width: 571px;

  @media (max-width: ${sizes.minM}) {
    width: 50%;
    max-width: 637px;
  }

  @media (max-width: ${sizes.mediumS}) {
    width: 69%;
  }

  @media (min-width: ${sizes.mediumS}) {
    width: 53%;
  }

  @media (max-width: ${sizes.s}) {
    position: relative;
    width: 100%;

    max-height: 405px;
    max-width: 305px;
  }
`;

export const StyledCraftingProjectButton = styled(Link)`
  margin-bottom: 25px;

  @media all and (max-width: ${sizes.s}) {
    display: none;
  }
`;

export const StyledCraftingRowDotWeb = styled.div`
  display: flex;
  flex-direction: row;

  @media all and (max-width: ${sizes.m}) {
    display: none;
  }
`;

export const StyledCraftingImage = styled.div<{
  url: string;
}>`
  background-image: ${({ url }) => `url(/${url})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 420px;
  max-width: 420px;
  min-height: 560px;
  max-height: 560px;
  border-radius: 80px;

  @media all and (max-width: ${sizes.m}) {
    min-width: 300px;
    max-width: 300px;
    min-height: 300px;
    max-height: 300px;
    border-radius: 40px;
    clip-path: polygon(0 0, 100% 1%, 98% 80%, 0 80%);
  }
`;

export const StyledCraftingRowDotMobile = styled.div`
  display: flex;
  flex-direction: row;

  @media all and (min-width: ${sizes.minM}) {
    display: none;
  }

  @media all and (max-width: ${sizes.m}) {
    margin-top: -70px;
  }

  @media all and (max-width: ${sizes.smallS}) {
    scale: 0.9;
  }
`;
