import React, { createRef } from "react";
import { useState } from "react";

import Button from "src/components/Button/button";
import { MAX_FILE_SIZE, SUPPORTED_FILE_TYPE } from "utility/constants";
import {
  StyledChosenFileName,
  StyledErrorMessage,
  StyledMainContainer,
} from "./fileUploaderStyle";

type FileUploaderProps = {
  onFileSelect: (event: any) => void;
};

const FileUploader = ({ onFileSelect }: FileUploaderProps): JSX.Element => {
  const hiddenFileInput = createRef<HTMLInputElement>();
  const [selectedFile, setSelectedFile] = useState<string>("No file chosen");
  const [error, setError] = useState<boolean>(false);

  const handleClick = (_: any) => {
    hiddenFileInput?.current?.click();
  };

  const handleChange = async (event: any) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded?.size <= MAX_FILE_SIZE && fileUploaded.type === SUPPORTED_FILE_TYPE) {
      setError(false);
      setSelectedFile(fileUploaded.name);
      const convertedFile = await toBase64(fileUploaded);
      onFileSelect([convertedFile]);
    } else {
      setError(true);
    }
  };

  const toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    }
    reader.onerror = error => reject(error);
});

  return (
    <>
      <StyledMainContainer>
        <Button
          text="Choose files"
          fontSize={24}
          mobileFontSize={24}
          onClick={handleClick}
          arrowType={null}
          justifyContent='center'
        />
        <input
          type="file"
          accept=".pdf"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
        />
        <StyledChosenFileName>{selectedFile}</StyledChosenFileName>
      </StyledMainContainer>
      {error ? (
        <StyledErrorMessage>
          There is an error while uploading file. Please check file size and extension.
        </StyledErrorMessage>
      ) : null}
    </>
  );
};
export default React.memo(FileUploader);
