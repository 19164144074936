import styled from "styled-components";
import { sizes } from "utility/device";
import { Link } from "gatsby";

export const StyledSparkInnovationMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

export const StyledSparkInnovationTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  gap: 32px;

  justify-content: space-between;
  align-items: center;

  @media (max-width: ${sizes.m}) {
    flex-direction: column;
  }
`;

export const StyledSparkYourInnovationInnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: ${sizes.m}) {
    align-items: flex-start;
  }
`;

export const StyledCTAArrowWrapper = styled.div`
  margin-right: 50px;
`;

export const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;

  @media (max-width: ${sizes.m}) {
    justify-content: center;
    \margin-top: 25px;
  }
`;
