import styled from "styled-components";

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SectionHeading = styled.div<{
  isOpen: boolean;
}>`
  margin-top: -2px;
  display: flex;
  align-items: center;
  padding: 12px 12px 12px 32px;
  border: 2px solid ${(props) => props.theme.colors.black};
  border-radius: 40px;
  justify-content: space-between;
  font-size: 22px;
  line-height: 24px;

  svg {
    transition: transform 0.4s linear;
    ${(props) => props.isOpen && `transform: rotate(180deg)`};
  }
`;

export const SectionDescriptionWrapper = styled.div<{
  isOpen: boolean;
  isAccordion?: boolean;
}>`

  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s linear;
  ${(props) => (props.isOpen || !props.isAccordion) && "max-height: 300px"};
`;

export const SectionDescription = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BulletItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 12px;
  line-height: 22px;
`;
