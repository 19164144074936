import styled from "styled-components";
import { genCustomPropStyle } from "src/styles/globalStyle";

export const StyledMainWebContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${genCustomPropStyle({
    propName: "gap",
    maxSize: 40,
    minSize: 32,
  })}
`;
