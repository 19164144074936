import React, { useEffect, useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import Layout from "src/components/Layout/layout";
import CookieConsent from "src/components/CookieConsent/cookieConsent";
import Seo from "src/components/seo";
import { theme } from "utility/theme";
import { genSchema, mapBlogWithSuggestions } from "utility/functions";
import routes from "utility/routes";
import { IBlogWithSuggestions, LocationPropI } from "utility/models";
import { SingleBlog } from "src/components/Blog/SingleBlog/singleBlog";
import BlogApi from "services/Blogs.api";
import { LoaderWrapper } from "components/Blog/blogStyle";
import { Loader } from "src/styles/globalStyle";
import { BlogError } from "components/Blog/blogError";
import { STRAPI_STORAGE_URL } from "utility/constants";
import {BLOG_SUFFIX} from 'utility/seo'

interface PageProps {
  location: LocationPropI;
  serverData: IBlogWithSuggestions | null;
}

const blogApi = new BlogApi();

const SingleBlogPageSSR = ({ location, serverData }: PageProps): JSX.Element => {
  const [isLoading, load] = useState<boolean>(false);
  const [menuColor, setMenuColor] = useState(theme.colors.white);
  const [isCookieVisible, setCookieVisible] = useState<boolean>(false);
  const blog = serverData;
  const isError = !serverData;

  const breakpoints = useBreakpoint();
  const isDesktop = breakpoints.l;
  const locationPath = location.pathname;

  const name = blog?.seo?.title || "LambdaWorks • Blog";
  const description = blog?.seo?.description || "Written by the people of LambdaWorks, sharing their thoughts on engineering, design, and management.";

  useEffect(() => {
    if (isDesktop) {
      setMenuColor(theme.colors.white);
    }
  }, [isDesktop]);

  const handleColor = (isMenuOpened: boolean) => {
    isMenuOpened
      ? setMenuColor(theme.colors.black)
      : setMenuColor(theme.colors.white);
  };

  const handleVisibility = (a: boolean) => {
    setCookieVisible(a);
  };

  return (
    <Layout
      backgroundColor={menuColor}
      headerColor={theme.colors.black}
      setBackgroundColor={handleColor}
      location={locationPath}
      headerRoute={routes.BLOG}
      isCookieVisible={isCookieVisible}
      isGetInTouchHidden
    >
      <Seo
        title={`${name} ${BLOG_SUFFIX}`}
        ogImg={!!blog?.seo?.img?.url ? {
          url: `${STRAPI_STORAGE_URL}${blog?.seo?.img?.url}`,
          height: blog.seo.img.height,
          width: blog.seo.img.width,
          mime: blog.seo.img.mime,
        } : null}
        schemaMarkup={genSchema({
          name,
          description,
          route: routes.BLOG,
        })}
      />

      {isLoading || (!blog?.id && !isError) ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
          <>{isError
            ? <BlogError type="blog" location={location} />
            : <SingleBlog blog={blog} location={location} />}
          </>
      )}
      <CookieConsent getCookieVisibility={handleVisibility} />
    </Layout>
  );
};

export default SingleBlogPageSSR;

export const getServerData = async (headers: any): Promise<{ props: IBlogWithSuggestions | null }> => {
  try {
    const slug = headers.params.slug;
    const response = await blogApi.getBlogs({ filters: { slug: { eq: slug } } });
    const data = response.data?.blogs?.data ?? [];
    const blog = mapBlogWithSuggestions(data[0]);
    return { props: blog };
  } catch (error) {
    return { props: null };
  }
}
