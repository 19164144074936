import styled from "styled-components";
import { sizes } from "utility/device";
import { ContentFrame } from "src/styles/globalStyle";

export const KnowleDiagramContainer = styled(ContentFrame)`
  display: flex;
  flex-direction: column;
  gap: 40px;
  box-sizing: border-box;

  @media all and (max-width: ${sizes.mediumS}) {
    gap: 64px;
    border: none;
    padding: 0px;
  }
`;

export const StyledDiagramImg = styled.div<{ src: string }>`
  display: flex;
  flex: 1;
  background-image: ${({ src }) => `url(/${src})`};
  background-size: cover;
  background-position: center;
  aspect-ratio: 1598/655;

  max-width: 1598px;
  max-height: 655px;
`;
