import styled from "styled-components";
import { sizes, specificBreakpoints } from "utility/device";
import ArrowRightBlack from "src/images/arrowRightBlack.svg";
import { genCustomPropStyle, genTextStyle } from "src/styles/globalStyle";

export const StyledRightBlackArrow = styled(ArrowRightBlack)`
  display: none;
  max-height: 64px;
  max-width: 64px;

  @media all and (max-width: ${specificBreakpoints.reviewsMobile}) {
    display: block;
    margin-left: 20px;
    cursor: pointer;
  }

  @media all and (max-width: ${specificBreakpoints.arrowPaddingReviewMobile}) {
    padding-top: 8px;
  }
`;

export const StyledReviewMainContainer = styled.div<{ cursor: string }>`
  position: relative;
  width: 100%;
  cursor: ${({ cursor }) => cursor};
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const StyledReviewContainer = styled.div`
  ${genCustomPropStyle({
    propName: "width",
    maxSize: 1625,
    minSize: 335,
  })}

  @media (max-width: ${sizes.mediumS}) {
    width: 100%;
  }
`;

export const StyledReviewCarouselMainContainer = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  ${genCustomPropStyle({
    propName: "height",
    maxSize: 263,
    minSize: 154,
  })}
`;

export const StyledReviewText = styled.p`
  max-width: 82%;
  color: ${(props) => props.theme.colors.black};
  ${genTextStyle({
    maxFontSize: 36,
    maxLineHeight: 39.6,
    minFontSize: 22,
    minLineHeight: 24,
  })}

  @media (max-width: ${sizes.m}) {
    max-width: 335px;
  }
`;

export const StyledReviewAuthor = styled.p`
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  ${genTextStyle({
    maxFontSize: 24,
    maxLineHeight: 28.8,
    minFontSize: 14,
    minLineHeight: 16.8,
  })}
`;

export const StyledReviewShadowContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  top: 0;
  right: 0;

  height: 100%;
  ${genCustomPropStyle({
    propName: "width", maxSize: 676, minSize: 335,
  })}

  background: linear-gradient(
    270deg,
    #D5FAD9 70.64%,
    rgba(222, 230, 223, 0) 98.28%
  );

  @media (max-width: ${sizes.mediumS}) {
    position: relative;
    width: 100%;
  }
`;

export const StyledNumberRow = styled.h1`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: ${sizes.mediumS}) {
    justify-content: flex-start;
    text-align: left;
  }
`;

export const StyledClutchReviewParagraph = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: ${(props) => props.theme.colors.black};
  ${genTextStyle({
    maxFontSize: 24,
    maxLineHeight: 28.8,
    minFontSize: 18,
    minLineHeight: 21.6,
  })}

  div {
    background: 
    linear-gradient(to right, transparent, transparent),
    linear-gradient(to right, black, black);
    background-size: 100% 2px, 0 2px;
    background-position: bottom left;
    background-repeat: no-repeat;
    transition:
      background-size 0.3s,
      background-position 0s 0.3s;
    text-decoration: none;
  }

  div :hover {
    background-position: bottom right;
    background-size: 0 1px, 100% 1px;
  }

  @media (min-width: ${sizes.xl}) {
    font-size: 24px;
    line-height: 29px;
  }

  @media (max-width: ${sizes.mediumS}) {
    justify-content: flex-start;
  }
`;
