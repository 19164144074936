import React from "react";

import CustomKnowledgeBase from "src/images/customKnowledgeBase.svg";
import GitHub from "src/images/gitHub.svg";
import GoogleDrive from "src/images/googleDrive.svg";
import Sharepoint from "src/images/sharepoint.svg";

import { LogoList } from "components/LogoList/logoList";
import { StyledWrapper } from "./knowledgeBaseIntegrationStyle";

export const KnowledgeBaseIntegration = () => (
  <StyledWrapper>
    <LogoList
      heading="Knowledge base integration."
      breakOnSmallDevices
      logos={[
        <Sharepoint />,
        <GoogleDrive />,
        <GitHub />,
        <CustomKnowledgeBase />
      ]}
    />
  </StyledWrapper>
);
