import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { createFragmentRegistry } from "@apollo/client/cache";

import fragments from "gql/fragments.graphql";

const API_GRAPH = process.env.STRAPI_GQL_DEV_ENDPOINT;

const cache = new InMemoryCache({
  fragments: createFragmentRegistry(fragments),
});

const link = createHttpLink({ uri: API_GRAPH });

export const client = new ApolloClient({
  cache,
  link,
});
