import { SVGAttributes } from "react";

export type Props = {
  colors: {
    backgroundColor: string;
    white: string;
    black: string;
  };
};

export type HomeTitleItem = {
  title: string;
  id: number;
};

export type TitleAnimationProps = {
  item: HomeTitleItem;
  props: any;
  key: string;
};

export type HomeImageItem = {
  id: number;
  url: string;
  alt: string;
};

export type MobileMenuItem = {
  name: string;
  route: string;
  external?: boolean;
};

export type InfoListItem = {
  name: string;
};

export type WebMenuItem = {
  name: string;
  route: string;
};

export type OurCapabilitiesItem = {
  id: number;
  title: string;
  paragraph: Array<string>;
};

export type ExternalLinkItem = {
  title: string;
  link: string;
};

export type CraftingProductsItem = {
  id: number;
  url: string;
  project: string;
  title: string;
  paragraph: string;
};

export type CarouselImageProps = {
  id: number;
  url: string;
  project: string;
  title: string;
  paragraph: string;
  route: string | null;
};

export type OpenPositionsHiddenListItem = {
  title: string;
  list?: Array<string> | undefined;
  content?: string | undefined;
};

export type OpenPositionListItem = {
  type: string;
  title: string;
  paragraph: string;
  hiddenList: Array<OpenPositionListItem>;
};

export type ArrowType = "right" | "down" | "up" | "left";

export const PROJECT_TAGS = {
  webDevelopment: "Web development",
  mobileDevelopment: "Mobile development",
  AI: "AI",
  web3: "Web3",
  businessAnalytics: "Business analytics",
  productDiscovery: "Product discovery",
  infrastructure: "Infrastructure",
  lwProduct: "LW product",
  openSource: "Open source",
  dataEngineering: "Data engineering",
  ecommerce: 'E-commerce',
}

export type ProjectFilters =
  | typeof PROJECT_TAGS.webDevelopment
  | typeof PROJECT_TAGS.mobileDevelopment
  | typeof PROJECT_TAGS.AI
  | typeof PROJECT_TAGS.web3
  | typeof PROJECT_TAGS.businessAnalytics
  | typeof PROJECT_TAGS.productDiscovery
  | typeof PROJECT_TAGS.infrastructure
  | typeof PROJECT_TAGS.lwProduct
  | typeof PROJECT_TAGS.openSource
  | typeof PROJECT_TAGS.dataEngineering
  | typeof PROJECT_TAGS.ecommerce;

export interface ProjectFilterItem {
  id: number;
  title: string;
  paragraph: string[];
}

export type ProjectsItem = {
  index: number;
  title: string;
  mobileTitle?: string;
  archiveTitle?: string;
  year: number;
  yearString: string;
  subTitle: string | JSX.Element;
  label: string | JSX.Element;
  images: string[];
  poster: string;
  paragraphs: string[];
  route: string;
  filters: ProjectFilters[];
};

export type ClientProps = {
  name: string;
  img: string;
  index: number;
  route: string;
  slug: string;
};

export type InternshipOptionList = Array<{
  value: string;
  label: string;
  disabled: boolean;
}>;

export interface LocationPropI {
  key: string;
  pathname: string;
  search: string;
  hash: string;
  state: {
    [key: string]: any;
  };
}

export type TechStackItem = {
  title: string;
  items: string;
};

export interface customPadding {
  paddingTopBottom?: number;
  paddingLeftRight?: number;
  paddingTopBottomMobile?: number;
  paddingLeftRightMobile?: number;
}

export interface IBlog {
  id: string;
  title: string;
  content: string;
  description: string;
  creator: string;
  slug: string;
  suggestions: IBlogSuggestion[];
  seo?: {
    title: string;
    description: string;
    img?: {
      url: string;
      width: string | number;
      height: string | number;
      mime: string;
    }
  }
  tags: Array<{ id: string; tag: string }>;
  publishedAt: string;
}

export interface IBlogSuggestion {
  id: string;
  slug: string;
  title: string;
  content: string;
  description: string;
  creator: string;
}

export interface IBlogWithSuggestions extends IBlog {
  publishedAt: string;
  suggestions: IBlogSuggestion[];
}

export interface RawBlogData {
  attributes: {
    title: string;
    description: string;
    content: string;
    slug: string;
    seo: {
      metaDescription?: string;
      metaTitle?: string;
      metaImage?: {
        media: {
          data: {
            attributes: {
              url: string;
              width: string | number;
              height: string | number;
              mime: string;
            }
          }
        }
      }
    }
    createdBy: {
      id: string;
      firstname: string;
      lastname: string;
    }
    publishedAt: string;
    tags: {
      data: Array<{
        attributes: {
          tag: string;
        };
        id: string;
      }>;
    };
    suggestions?: {
      data: Array<{
        attributes: {
          slug: string;
          title: string;
          content: string;
          description: string;
          createdBy: {
            id: string;
            firstname: string;
            lastname: string;
          };
        };
        id: string;
      }>;
    }
  };
  id: string;
}

interface IBlogPagination {
  pageSize: number;
  page: number;
}

type FilterMatch =
  | "and"
  | "between"
  | "contains"
  | "containsi"
  | "endsWith"
  | "eq"
  | "eqi"
  | "gt"
  | "gte"
  | "in"
  | "it"
  | "ite"
  | "ne"
  | "nei"
  | "not"
  | "notContains"
  | "notContainsi"
  | "notIn"
  | "notNull"
  | "null"
  | "or"
  | "startsWith";

type FilterMatchArray =
  | "and"
  | "between"
  | "in"
  | "notIn"
  | "or";

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> =
  Omit<T, Keys> & { [K in Keys]-?: Required<Pick<T, K>> & Partial<Omit<T, K>> }[Keys];

export type IGetBlogsProps = RequireAtLeastOne<Partial<{
  tagFilter: string | null | Array<string | null>;
  pagination: IBlogPagination;
  filters: RequireAtLeastOne<Partial<{
    slug: Partial<Record<FilterMatch, string> | Record<FilterMatchArray, string[]>>;
    tags: { tag: Partial<Record<FilterMatch, string> | Record<FilterMatchArray, string[]>> };
  }>>;
}>>

export type fetchBlogsT = ({
  page,
  tag,
}: Partial<{
  page: number;
  tag: string | null | (string | null)[];
}>) => Promise<void>;

export type searchBlogsByTag = ({
  tag,
}: {
  tag?: string;
}) => Promise<void>;

export type searchBlogsI = searchBlogsByTag | (() => Promise<void>);

export interface IJobContent {
  type: "paragraph";
  children: {
    type: "text";
    text: string;
  }[]
}

export interface IPositionDetails {
  is: string;
  name: string;
}

export type JobDescription = {
  description: IJobContent[] | null;
  looking: IJobContent[] | null;
  whatWeDo: IJobContent[] | null;
  whyApply: IJobContent[] | null;
  expectation: IPositionDetails[] | null;
  plus: IPositionDetails[] | null;
  skills: IPositionDetails[] | null;
  youWill: IPositionDetails[] | null;
}

export type IJobPosition = JobDescription & {
  id: string;
  position: string;
  title: string;
  active: boolean;
}

export interface IJobRawData {
  data: {
    id: string;
    attributes: {
      active: boolean;
      publishedAt: string;
      positionList: IJobPosition[];
    }
  }
}

export type Job = IJobRawData["data"];

export type JobPositionList = {
  title: string;
  content: string;
} | {
  title: string;
  list: string[];
};

export interface IOpenPosition {
  type: string;
  title: string;
  paragraph: string;
  hiddenList: (JobPositionList | null)[];
}

export type InternshipPosition = {
  id: string;
  name: string;
  active: boolean;
};

export interface IInternshipData {
  data: {
    id: string;
    attributes: {
      active: boolean;
      publishedAt: string;
      positions: InternshipPosition[]
    }
  }
}
