import * as React from "react";
import { Helmet } from "react-helmet";

interface SEOProps {
  lang?: string;
  ogImg?: {
    url: string;
    width: string | number;
    height: string | number;
    mime: string;
  } | null;
  title: string;
  schemaMarkup?: { [key: string]: string | string[] };
}

function Seo({
  lang,
  title,
  ogImg,
  schemaMarkup,
}: SEOProps) {
  return (
    <Helmet
      htmlAttributes={{
        lang: lang || "en",
      }}
      meta={[
        {
          property: "og:image:width",
          content: ogImg?.width ? `${ogImg?.width}` : "1200",
        },
        {
          property: "og:image:height",
          content: ogImg?.height ? `${ogImg?.height}` : "630",
        },
        {
          property: "og:image:type",
          content: ogImg?.mime ? `${ogImg?.mime}` : "image/png",
        },
      ]}
      title={title}
    >
      {schemaMarkup && (
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      )}
    </Helmet>
  );
}

export default Seo;
