import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import Logo from "src/images/logo.svg";
import {
  webMenuItems,
  mobileHeaderPages,
} from "utility/lists";
import { MobileMenuItem, WebMenuItem } from "utility/models";
import { useWindowOffset } from "utility/hooks";
import { animationWindowScrollTreshhold } from "utility/constants";
import { findActiveRoute } from "utility/functions";

import {
  FooterInfo,
  InfoWrapper,
  LogoAndButtonWrapper,
  StyledActiveDot,
  StyledActiveLink,
  StyledActiveLinkExternal,
  StyledActiveLinkWrapper,
  StyledBlackLogoWrapper,
  StyledDot,
  StyledHoverDot,
  StyledLeftContainer,
  StyledLink,
  StyledMainContainer,
  StyledMobileDiv,
  StyledMobileMainContainer,
  StyledMobileNavBar,
  StyledMobileNavTrigger,
  StyledMobileNavTriggerWrapper,
  StyledRightContainer,
} from "./headerStyle"
import FooterBottomMobile from "components/Footer/footerBottomMobile";
import LogoHeaderSVG from "src/svg/logoHeader";
import { theme } from "utility/theme";

interface HeaderProps {
  background: string;
  active?: string;
  headerColor: string;
  contentColorChange: boolean;
  setBackgroundColor?: (isOpened: boolean) => void;
  setScroll: (isMenuOpen: boolean) => void;
}

const Header = ({
  background,
  active,
  headerColor,
  contentColorChange,
  setBackgroundColor,
  setScroll,
}: HeaderProps): JSX.Element => {
  const { scrollY } = useWindowOffset();
  const breakpoints = useBreakpoint();
  const isDesktop = breakpoints.l;

  const [navigationOpen, setNavigationOpen] = useState(false);
  const [hovered, setHovered] = useState<WebMenuItem | null>();
  const [isScrolled, setScrolled] = useState<boolean>(false);

  useEffect(() => {
    if (scrollY > animationWindowScrollTreshhold && !isScrolled) {
      setScrolled(true);
    } else if (scrollY <= animationWindowScrollTreshhold && isScrolled) {
      setScrolled(false);
    }
  }, [scrollY]);

  const setNavigationActive = () => {
    document.body.style.overflowY = !navigationOpen ? "hidden" : "auto";
    setBackgroundColor && setBackgroundColor(!navigationOpen);
    setNavigationOpen(!navigationOpen);
    setScroll(!navigationOpen);
  };

  useEffect(() => {
    if (isDesktop) {
      setScroll(false);
    }
  }, [isDesktop]);

  return (
    <div style={{ position: "relative" }}>
      <StyledMainContainer
        data-background={background}
        data-scrolled={isScrolled}
        data-fixed={true}
        data-header-background={headerColor}
        data-content-color-change={contentColorChange}
        data-testid="header-desktop"
      >
        <div>
          <StyledLeftContainer
            data-scrolled={isScrolled}
            data-content-color-change={contentColorChange}
          >
            <Link
              to="/"
              aria-label="home-icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>
                <Logo />
              </i>
            </Link>
          </StyledLeftContainer>
          <StyledRightContainer>
            {webMenuItems.map((item: WebMenuItem, i) => (
              <StyledLink
                to={item.route}
                key={`${item.name}${i}`}
                aria-label={item.name}
                onMouseOver={() => setHovered(item)}
                onMouseLeave={() => setHovered(null)}
              >
                {active === item.route ?
                  <StyledDot /> :
                  <StyledHoverDot
                    opacity={hovered?.route === item.route ? 1 : 0}
                    display="flex"
                  />
                }
                <p>{item.name}</p>
                </StyledLink>
              )
            )}
          </StyledRightContainer>
        </div>
      </StyledMainContainer>

      <StyledMobileMainContainer
        background={background}
        data-open={navigationOpen}
        data-header-background={headerColor}
        data-scrolled={isScrolled}
        data-testid="header-mobile"
      >
        <LogoAndButtonWrapper open={navigationOpen}>
          <Link to="/" aria-label="home-icon-black">
            <StyledBlackLogoWrapper
              data-scrolled={isScrolled}
              data-content-color-change={contentColorChange}
            >
              <LogoHeaderSVG
                width={158}
                color={navigationOpen ? theme.colors.white : theme.colors.black}
                aria-label="home-icon-black"
              />
            </StyledBlackLogoWrapper>
          </Link>
          <StyledMobileNavTriggerWrapper onClick={setNavigationActive}>
            <StyledMobileNavTrigger
              data-open={navigationOpen}
              data-scrolled={isScrolled}
              data-content-color-change={contentColorChange}
              className={ navigationOpen ? 'menu open' : 'menu' }
            >
              <span />
              <span />
              <span />
              <span />
            </StyledMobileNavTrigger>
          </StyledMobileNavTriggerWrapper>
        </LogoAndButtonWrapper>

        <StyledMobileNavBar open={navigationOpen}>
          <StyledMobileDiv>
            {mobileHeaderPages.map((item: MobileMenuItem, i) => {
              const {
                name,
                route,
                external = false,
              } = item || {}
              return (
                <StyledActiveLinkWrapper key={`${name}${i}`}>
                  {
                    external ?
                      <StyledActiveLinkExternal
                        href={route}
                        rel="noopener noreferrer"
                      >
                        {name}
                      </StyledActiveLinkExternal>
                      :
                      <StyledActiveLink to={route} key={name} aria-label={name}>
                        {name}
                      </StyledActiveLink>
                  }
                  {active ? (
                    findActiveRoute(active) === route ? (
                      <StyledActiveDot />
                    ) : null
                  ) : null}
                </StyledActiveLinkWrapper>
              )
            })}
          </StyledMobileDiv>

          <FooterInfo gap={32}>
            <div>
              office@lambdaworks.io<br />+381 21 303 53 99<br />Miše Dimitrijevića 12<br />Novi Sad, Serbia
            </div>

            <InfoWrapper>
              <FooterBottomMobile mode="light" />
            </InfoWrapper>
          </FooterInfo>


        </StyledMobileNavBar>
      </StyledMobileMainContainer>
    </div>
  );
};

export default React.memo(Header);
