import styled from "styled-components";

import { sizes } from "utility/device";

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  margin-top: 32px;

  @media all and (max-width: ${sizes.s}) {
    flex-direction: column;
    width: fit-content;
    align-items: flex-start;
  }
`;

export const StyledChosenFileName = styled.p`
  font-size: 14px;
  line-height: 143.3%;

  margin-left: 14px;

  @media all and (max-width: ${sizes.s}) {
    margin-top: 10px;
  }
`;

export const StyledErrorMessage = styled.p`
  font-size: 12px;

  color: ${(props) => props.theme.colors.red};

  margin-top: 15px;
`;
