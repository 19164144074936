import React, { useState, useEffect } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import Layout from "src/components/Layout/layout";
import CookieConsent from "src/components/CookieConsent/cookieConsent";
import { theme } from "utility/theme";
import routes from "utility/routes";
import { LocationPropI } from "utility/models";
import Scala from "components/Scala";

interface PageProps {
  location: LocationPropI;
}

const ScalaPage = ({ location }: PageProps): JSX.Element => {
  const [menuColor, setMenuColor] = useState(theme.colors.white);
  const [isCookieVisible, setCookieVisible] = useState<boolean>(false);

  const handleColor = (isMenuOpened: boolean) => {
    isMenuOpened
      ? setMenuColor(theme.colors.black)
      : setMenuColor(theme.colors.white);
  };

  const breakpoints = useBreakpoint();
  const isDesktop = breakpoints.l;
  const locationPath = location.pathname;

  useEffect(() => {
    if (isDesktop) {
      setMenuColor(theme.colors.white);
    }
  }, [isDesktop]);

  const handleVisibility = (a: boolean) => {
    setCookieVisible(a);
  }

  return (
    <Layout
      backgroundColor={menuColor}
      headerColor={theme.colors.enterprisesBackground}
      headerRoute={routes.SCALA}
      setBackgroundColor={handleColor}
      location={locationPath}
      isCookieVisible={isCookieVisible}
    >
      <Scala />
      <CookieConsent getCookieVisibility={handleVisibility} />
    </Layout>
  );
};

export default ScalaPage;
