import styled from "styled-components";

import { sizes } from "utility/device";
import { genCustomPropsStyle, genCustomPropStyle, genSizeStyle, genTextStyle } from "src/styles/globalStyle";

export const StyledContainer = styled.div`
  ${genCustomPropsStyle({
    props: [
      {propName: "padding-top", minSize: 60, maxSize: 60},
      {propName: "padding-bottom", minSize: 32, maxSize: 60},
    ]
  })}
`;
