import React from "react";

import AboutKnowle from "components/Knowle/AboutKnowle/aboutKnowle";
import KnowleDiagram from "components/Knowle/KnowleDiagram/knowleDiagram";
import CustomizedApproach from "components/Knowle/CustomizedApproach/customizedApproach";
import AIProcessing from "components/Knowle/AIProcessing/aiProcessing";
import { KnowledgeBaseIntegration } from "components/Knowle/KnowledgeBaseIntegration/knowledgeBaseIntegration";
import MedianCost from "components/Knowle/MedianCost/medianCost";
import DevelopChatbot from "components/Knowle/DevelopChatbot/developChatbot";
import Hero from "components/Hero/hero";
import { StyledKnowlePageContainer, StyledKnowleTitle } from "./knowleStyle";
import HeroParagraph from "components/HeroParagraph/heroParagraph";
import SectionAnimation from "components/SectionAnimation/sectionAnimation";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { StyledSeparator } from "components/Layout/layoutStyle";

const Knowle = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMedScreen = !breakpoints.midS;
  const isDesktopScreen = !breakpoints.m;
    
  return (
    <StyledKnowlePageContainer>
      <Hero
        title={
          <>
            AI chatbot
            <br />
            development with
            <StyledKnowleTitle />
          </>
        }
        image={{
          src: "hero/knowleHero.png",
          mobileSrc: "hero/knowleHeroMobile.png",
        }}
      />
      <HeroParagraph
        maxWidth={1540}
        text="AI chatbot development from the ground up is often too expensive, making it accessible only to larger organizations. On the other hand, all SaaS AI services carry a great risk of data exposure and pose security risks."
      />

      {!isMedScreen && (
        <StyledSeparator />
      )}

      <SectionAnimation>
        <AboutKnowle />
      </SectionAnimation>

      {isMedScreen && (
        <StyledSeparator />
      )}
      
      <SectionAnimation>
        <KnowleDiagram />
      </SectionAnimation>

      {isMedScreen && (
        <StyledSeparator />
      )}
      
      <SectionAnimation>
        <CustomizedApproach />
      </SectionAnimation>

      {isDesktopScreen && (
        <StyledSeparator />
      )}

      <SectionAnimation>
        <AIProcessing />
      </SectionAnimation>

      {isDesktopScreen && (
        <StyledSeparator />
      )}
      
      <SectionAnimation>
        <KnowledgeBaseIntegration />
      </SectionAnimation>
      
      <SectionAnimation>
       <DevelopChatbot />
      </SectionAnimation>
      
      <SectionAnimation>
        <MedianCost />
      </SectionAnimation>

      <StyledSeparator />

      <div />
    </StyledKnowlePageContainer>
  );
};

export default Knowle;
