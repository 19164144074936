import styled from "styled-components";
import KnowleLogo from "src/images/knowleTitle.svg";

import { genCustomPropStyle, genSizeStyle } from "src/styles/globalStyle";

export const StyledKnowlePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${genCustomPropStyle({
    propName: "gap",
    maxSize: 120,
    minSize: 60,
  })}
`;

export const StyledKnowleTitle = styled(KnowleLogo)`
  display: flex;
  ${genSizeStyle({
      maxWidth: 529,
      minWidth: 263,
      maxHeight: 133,
      minHeight: 66,
  })}
`;