import React from "react";

import { aIProcessingList } from "utility/lists";
import CategorisedList from "components/CategorisedLists/categorizedLists";

import {
  AIProcessingContainer,
} from "./aiProcessingStyle";

const AIProcessing = (): JSX.Element => (
  <AIProcessingContainer>
    <h2>AI processing.</h2>
    <CategorisedList list={aIProcessingList} align="start" />
  </AIProcessingContainer>
);

export default React.memo(AIProcessing);
