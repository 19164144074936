import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { ThemeProvider } from "styled-components";
import FontFaceObserver from "fontfaceobserver";

import { SEO_BY_PAGE } from 'utility/seo'
import Seo from 'components/seo'

import { theme } from "utility/theme";
import { useWindowOffset } from "utility/hooks";
import routes from "utility/routes";
import Header from "src/components/Header/header";
import { StyledHeaderSeparator } from "src/components/Header/headerStyle";
import {
  reachedFooterIndicator,
} from "utility/constants";

import genGlobalStyle, {
  Container,
  FloatingButton,
  MainContainer,
  LoaderWrapper,
} from "./layoutStyle";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Loader } from "src/styles/globalStyle";

interface LayoutProps {
  children: JSX.Element | Array<JSX.Element>;
  location: any;
  backgroundColor: string;
  headerColor: string;
  headerRoute?: string;
  isCookieVisible?: boolean;
  isGetInTouchHidden?: boolean;
  setBackgroundColor?: (isOpened: boolean) => void;
}

const Layout = ({
  children,
  backgroundColor,
  headerColor,
  headerRoute,
  isCookieVisible,
  isGetInTouchHidden,
  setBackgroundColor,
  location,
}: LayoutProps): JSX.Element => {
  const [isMenuOpen, setScroll] = useState(false);
  const breakpoints = useBreakpoint();
  const GlobalStyle = genGlobalStyle({
    backgroundColor,
    isScrollable: !isMenuOpen,
  });
  const isMobile = !breakpoints.midS;
  const { scrollY } = useWindowOffset();
  const [isFooterVisible, setFooterVisibility] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const font = new FontFaceObserver("Moderat-Regular");
    font.load().then(() => {
      setLoading(false);
    });

  }, []);

  useEffect(() => {
    if (!loading) {
      if (document.body.scrollHeight - scrollY < reachedFooterIndicator) {
        setFooterVisibility(true);
      } else if (isFooterVisible) {
        setFooterVisibility(false);
      }
    }
  }, [scrollY, loading]);

  const handleScroll = (isOpen: boolean) => {
    setScroll(isOpen);
  };

  // @ts-ignore
  const seo = SEO_BY_PAGE[location] || null

  return (
    <ThemeProvider theme={theme}>
      <Container background={backgroundColor}>
        {
          seo &&
          <Seo
            title={seo.title}
          />
        }
        <MainContainer>
          <GlobalStyle />
          {loading ? (
            <LoaderWrapper>
              <Loader borderColor={backgroundColor} />
            </LoaderWrapper>
          ) : (
            <>
              <Header
                active={headerRoute}
                background={backgroundColor}
                contentColorChange={headerColor === theme.colors.black}
                headerColor={headerColor}
                setBackgroundColor={setBackgroundColor}
                setScroll={handleScroll}
              />
              <StyledHeaderSeparator />
              {children}
            </>
          )}

        </MainContainer>
        {!isGetInTouchHidden && !isMobile && (
          <Link to={routes.CONTACT}>
            <FloatingButton
              runAnimation={isFooterVisible}
              isCookieVisible={isCookieVisible}
            />
          </Link>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default Layout;
