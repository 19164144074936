import React, { useEffect, useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import {
  SuggestionsWrapper,
  SuggestionsBlogs,
  SuggestionCard,
  SuggestionCardTitle,
  SuggestionCreator,
  SuggestionCreatorWrapper,
  ButtonWrapper,
  SuggestionBody,
  LoaderWrapper,
} from "./blogSuggestionsStyle";
import queryString from "query-string";
import { IBlogSuggestion, IBlogWithSuggestions, LocationPropI, RawBlogData } from "utility/models"
import Button from "components/Button/button";
import { navigate } from "gatsby";
import routes from "utility/routes";
import BlogApi from "services/Blogs.api";
import { SUGGESTIONS_NUM } from "utility/constants";
import { mapBlogs } from "utility/functions";
import { Loader } from "src/styles/globalStyle";

const blogApi = new BlogApi();
const suggestionsTitle = "You might also like reading these blog posts:";

interface IBlogSuggestions {
  blog?: IBlogWithSuggestions | null;
  location: LocationPropI;
}

export const BlogSuggestions = ({ blog, location }: IBlogSuggestions): JSX.Element => {
  const queryTag = queryString.parse(location.search)?.tag;
  const breakpoints = useBreakpoint();
  const isMobile = !breakpoints.midS;
  const [suggestions, setSuggestions] = useState<IBlogSuggestion[]>(blog?.suggestions ?? []);
  const [isLoading, load] = useState<boolean>(false);

  const fetchSuggestions = async (): Promise<void> => {
    load(true);
    try {
      const response = await blogApi.getBlog({ filters: { slug: { eq: blog?.slug } } });
      const blogSuggestions = response.data.blogs.data[0].attributes.suggestions.data as RawBlogData[];
      if ((blogSuggestions?.length ?? 0) < SUGGESTIONS_NUM) {
        const blogs = await blogApi.getBlogs({
          pagination: {
            page: 1,
            pageSize: SUGGESTIONS_NUM,
          },
          filters: {
            slug: {
              ne: blog?.slug,
            }
          }
        });
        const data: IBlogSuggestion[] = [
          ...mapBlogs(blogSuggestions ?? []),
          ...mapBlogs(blogs.data.blogs.data ?? [])]
        ?.filter((item, index, self) =>
          index === self.findIndex((t) => t.id === item.id && t.id !== blog?.id)
        )?.slice(0, SUGGESTIONS_NUM);
        const filteredSuggestions = [...data, ...blogSuggestions ?? []]
          .filter((item, index, self) =>
            index === self.findIndex((t) => t.id === item.id && t.id !== blog?.id))
          .slice(0, SUGGESTIONS_NUM);
        // @ts-ignore
        setSuggestions(filteredSuggestions);
      } else {
        // @ts-ignore
        setSuggestions(blogSuggestions?.slice(0, SUGGESTIONS_NUM) ?? []);
      }
    } catch (error) {
      console.log(error)
    } finally {
      load(false);
    }
  }

  useEffect(() => {
    setSuggestions(blog?.suggestions ?? []);
    if (blog?.suggestions?.length !== SUGGESTIONS_NUM) {
      fetchSuggestions();
    }
  }, [blog?.slug])

  return !isLoading ? (
    <>
      {!!suggestions?.length && (
        <SuggestionsWrapper>
          <h6>{suggestionsTitle}</h6>
          <SuggestionsBlogs>
            {suggestions.map((el) => (
              <SuggestionCard key={el.id}>
                <SuggestionCardTitle>{el.title}</SuggestionCardTitle>
                {!isMobile && <SuggestionCreator>{el.creator}</SuggestionCreator>}
                <SuggestionBody dangerouslySetInnerHTML={{ __html: el.description }} />
                {isMobile && (
                  <SuggestionCreatorWrapper>
                    <SuggestionCreator>
                      {el.creator}
                    </SuggestionCreator>
                  </SuggestionCreatorWrapper>
                )}
                <ButtonWrapper>
                  <Button
                    onClick={() => navigate(`${routes.BLOG}/${el.slug}`)}
                    text="Read"
                    disabled={queryTag === el.title?.toLowerCase()}
                    centerContent={false}
                    justifyContent="center"
                    isfullwidth
                  />
                </ButtonWrapper>
              </SuggestionCard>
            ))}
          </SuggestionsBlogs>
        </SuggestionsWrapper>
      )}
    </>
  ) : (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  );
};
