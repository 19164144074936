import React, { MouseEventHandler } from "react";

import { CarouselImageProps, CraftingProductsItem } from "utility/models";
import { isTouchDevice } from "utility/functions";

import { StyledButton, StyledButtonHover, StyledButtonText } from "./carouselCircleStyle";

type CarouselCircleProps = {
  item: CraftingProductsItem;
  index: number;
  activeItem: CarouselImageProps | undefined;
  hoveredItem?: CarouselImageProps | undefined;
  marginRight?: number;
  isSameId?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | any;
};

const CarouselCircle = ({
  item,
  index,
  activeItem,
  marginRight = 38,
  isSameId,
  onClick,
}: CarouselCircleProps): JSX.Element => {
  const checkItemNumber = (number: number) => {
    return number > 10 ? number : `0${number}`;
  };

  return (
    <StyledButtonHover>
      <StyledButton
        key={index}
        active={
          !isSameId
            ? activeItem === item
            : activeItem?.id === item?.id
        }
        marginRight={marginRight}
        onClick={(event) => !isTouchDevice && onClick && onClick(event)}
        onTouchEnd={() => isTouchDevice && onClick && onClick(event)}
      >
        <StyledButtonText>{checkItemNumber(index + 1)}</StyledButtonText>
      </StyledButton>
    </StyledButtonHover>
  );
};

export default React.memo(CarouselCircle);
