import { useBreakpoint } from "gatsby-plugin-breakpoints";
import React from "react";

import CompanyDottedList from "src/components/Company/CompanyDottedList/companyDottedList";
import { philosophyList } from "utility/lists";

import {
  StyledDottedListContainer,
  StyledMainContainer,
  StyledPhilosophyImage,
} from "./philosophyStyle";

const Philosophy = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMediumScreen = breakpoints.midS;

  return (
    <StyledMainContainer>
      <CompanyDottedList
        title={isMediumScreen ? "Our Philosophy." : "Philosophy."}
        list={philosophyList}
      />
    <StyledPhilosophyImage />
    </StyledMainContainer>
  );
};

export default Philosophy;
