import React from "react";

import { IBlog } from "utility/models";

import { BlogTag } from "components/Blog/Tag/tag";
import { BlogNameAndDate } from "src/components/Blog/NameAndDate/nameAndDate";
import {
  BlogName,
  BlogParagraph,
  BlogPreview,
  DateAndButtonWrapper,
  TagsWrapper,
} from "./blogCardStyle";
import Button from "components/Button/button";
import { navigate } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import routes from "utility/routes";

interface BlogCardPropsI {
  blog: IBlog;
}

export const BlogCard = ({
  blog,
}: BlogCardPropsI): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMobile = !breakpoints.mediumS;
  const { title, description, tags, creator, publishedAt, slug } = blog;

  return (
    <BlogPreview>
      <BlogName to={`${routes.BLOG}/${slug}`}>{title}</BlogName>
      <BlogParagraph>{description}</BlogParagraph>
      {tags?.length > 0 && (
        <TagsWrapper>
          {tags?.map((tag) => (
            <BlogTag tag={tag.tag} />
          ))}
        </TagsWrapper>
      )}

      <DateAndButtonWrapper>
        <BlogNameAndDate creator={creator} date={publishedAt} />
        <Button
          onClick={() => navigate(`${routes.BLOG}/${blog.slug}`)}
          text="Read"
          centerContent={false}
          justifyContent="center"
          isfullwidth={!isMobile}
        />
      </DateAndButtonWrapper>
    </BlogPreview>
  );
};
