import { useEffect, useState } from "react";

export const useWindowOffset = () => {
  const [scrollX, setScrollX] = useState<number>(0);
  const [scrollY, setScrollY] = useState<number>(0);

  useEffect(() => {
    const setScroll = () => {
      setScrollX(window.pageXOffset);
      setScrollY(window.pageYOffset);
    };

    window.addEventListener("scroll", setScroll);

    return () => window.removeEventListener("scroll", setScroll);
  }, []);

  return { scrollX, scrollY };
};
