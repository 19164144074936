import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";

import Button from "src/components/Button/button";
import DottedPositionsList from "src/components/Careers/DottedPositionsList/dottedPositionsList";
import { StyledSeparator } from "src/components/Layout/layoutStyle";
import { IOpenPosition } from "utility/models";
import { ButtonType } from "utility/theme";
import { accessibility } from "utility/constants";

import {
  StyledApplyNowButtonContainer,
  StyledArrow,
  StyledArrowButton,
  StyledButtonContainer,
  StyledHiddenContainer,
  StyledHiddenListContainerRow,
  StyledHiddenTextContainer,
  StyledMainContainer,
  StyledParagraph,
  StyledSeparatorContainer,
  StyledTextContainer,
  StyledTitle,
  StyledType,
} from "./positionItemStyle";
import routes from "utility/routes";

const PositionItem = ({
  type,
  title,
  paragraph,
  hiddenList,
}: IOpenPosition): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <StyledMainContainer onClick={() => setOpen(!open)}>
        <StyledTextContainer>
          <StyledType>{type}</StyledType>
          <StyledTitle>{title}</StyledTitle>
        </StyledTextContainer>
        <StyledButtonContainer>
          <StyledArrowButton
            aria-label={accessibility.CAREERS_FIND_OUT_MORE}
          >
            <StyledArrow open={open} />
          </StyledArrowButton>
        </StyledButtonContainer>
      </StyledMainContainer>

      <StyledHiddenContainer open={open}>
        <StyledSeparatorContainer>
          <StyledSeparator />
        </StyledSeparatorContainer>
        <StyledHiddenListContainerRow>
          <StyledHiddenTextContainer>
            <StyledParagraph>{paragraph}</StyledParagraph>
            {!!hiddenList && hiddenList?.map((item) => !!item && (
              <DottedPositionsList
                paragraphFont={18}
                key={item?.title}
                {...item}
              />
            ))}
          </StyledHiddenTextContainer>
          <StyledApplyNowButtonContainer>
            <Button
              text="Apply now"
              arrowType="right"
              type={ButtonType.BLACK}
              onClick={() => navigate(`${routes.APPLY}?position=${(type.split(" ").join("-"))}`)}
              centerContent
            />
          </StyledApplyNowButtonContainer>
        </StyledHiddenListContainerRow>
      </StyledHiddenContainer>
    </>
  );
};

export default React.memo(PositionItem);
