import React from "react";
import LetsTalkSection from "components/LetsTalkSection/letsTalkSection";

const DevelopChatbot = (): JSX.Element => (
  <LetsTalkSection
    link="/contact"
    title="Develop your chatbot"
    mobileTitle={<>Develop<br />your chatbot</>}
    paragraph = {"Highly secure knowledge chatbot that can be developed on a reasonable budget.\nImplement Knowle in your business now."}
    buttonText="Get in touch"
    page="knowle"
    paragraphFontSize="28px"
    limitParagraph={false}
  />
);

export default React.memo(DevelopChatbot);
