import styled from "styled-components";
import { genCustomPropStyle } from "src/styles/globalStyle";

export const StyledProductInnovationContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${genCustomPropStyle({
    propName: "gap",
    maxSize: 120,
    minSize: 60,
  })}
`;
