import styled from "styled-components";
import { sizes } from "utility/device";
import { ContentFrame } from "src/styles/globalStyle";

export const StyledMainContainer = styled(ContentFrame)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media all and (max-width: ${sizes.m}) {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }
`;
