import React, { useRef, useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { ButtonType } from "utility/theme";
import Button from "src/components/Button/button";
import {
  ButtonHolder,
  ErrorMessage,
  SubscriptionWrapper,
} from "./subscribeFormStyle";
import { StyledLabel } from "src/styles/globalStyle";

const EMAIL_REG = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,}$/;
const ERROR_MESSAGE = "We kindly ask you to enter a valid email.";

const validateEmail = (email: string): boolean => {
  return EMAIL_REG.test(email);
};

export const SubscribeForm = (): JSX.Element | null => {
  const formRef = useRef<HTMLFormElement>(null);
  const [email, setEmail] = useState<string>("");
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [isSubmitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const breakpoints = useBreakpoint();
  const isMobile = !breakpoints.midS;

  return (
    <SubscriptionWrapper id="mc_embed_shell" style={{ display: isSubmitted ? "none" : "flex" }}>
      <StyledLabel>
        {!isMobile
          ? "Sign up to receive a quarterly recap from LambdaWorks"
          : "Sign up to receive a quarterly\nrecap from LambdaWorks"}
      </StyledLabel>
      <form
        ref={formRef}
        action="https://lambdaworks.us22.list-manage.com/subscribe/post?u=cf1eb79ab4b6c134c4b97cbec&amp;id=6b1fd30fea&amp;f_id=00adcee1f0"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
        noValidate={false}
        onSubmit={(e) => {
          setSubmitting(true);
          if (!validateEmail(email)) {
            e.preventDefault();
            setError(ERROR_MESSAGE);
          } else {
            setError(null);
            setSubmitted(true);
            setSubmitting(false);
          }
        }}
      >
        <input
          type="email"
          name="EMAIL"
          id="mce-EMAIL"
          required
          placeholder="Email address"
          value={email}
          autoComplete="off"
          onChange={(e) => {
            const value = e.target.value;
            setEmail(value);
            if (isSubmitted) {
              setError(validateEmail(value) ? null : ERROR_MESSAGE);
            }
          }}
        />
        <div id="mce-responses">
          <div id="mce-error-response"></div>
          <div id="mce-success-response"></div>
        </div>
        <div aria-hidden="true" style={{ position: "absolute", left: "-5000px;", display: "none" }}>
          /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
          <input type="text" name="b_cf1eb79ab4b6c134c4b97cbec_6b1fd30fea" tabIndex={-1} value="" />
        </div>
        <div style={{ display: "none" }}>
          <div>
            <input
              type="submit"
              name="subscribe"
              id="mc-embedded-subscribe"
              value="Subscribe"
            />
            <p
              style={{ margin: "0px auto" }}
            >
              <a
                href="http://eepurl.com/iRrLyw"
                title="Mailchimp - email marketing made easy and fun"
              >
                <span>
                  <img
                    src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg"
                    alt="Intuit Mailchimp"
                    className="width: 220px; height: 40px; display: flex; padding: 2px 0px; justify-content: center; align-items: center;"
                  />
                </span>
              </a>
            </p>
          </div>
        </div>
        {!isMobile && (
          <ButtonHolder>
            <Button
              text="Subscribe"
              disabled={!!error}
              arrowType={null}
              fontSize={36}
              mobileFontSize={24}
              justifyContent="center"
              loading={isSubmitting}
              type={ButtonType.SUBSCRIBE}
              isfullwidth
            />
          </ButtonHolder>
        )}
      </form>
      {error && (
        <ErrorMessage>{error}</ErrorMessage>
      )}
      {isSubmitted && !error && (
        <h6>Thank you for signing up!</h6>
      )}
      {isMobile && (
        <ButtonHolder isMobile>
          <Button
            text="Subscribe"
            disabled={!!error}
            arrowType={null}
            fontSize={36}
            mobileFontSize={24}
            justifyContent="center"
            loading={isSubmitting}
            type={ButtonType.SUBSCRIBE}
            isfullwidth
            onClick={() => formRef?.current?.requestSubmit()}
          />
        </ButtonHolder>
      )}
    </SubscriptionWrapper>
  );
};
