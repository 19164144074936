import styled from "styled-components";
import { sizes } from "utility/device";
import { genCustomPropStyle, genTextLimit, genTextStyle } from "src/styles/globalStyle";

export const SuggestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
`;

export const SuggestionsBlogs = styled.div`
  display: flex;
  gap: 30px;
  flex: 1;
  flex-wrap: wrap;

  @media (max-width: ${sizes.mediumS}) {
    flex-direction: column;
  }
`;

export const SuggestionCard = styled.div`
  gap: 32px;
  ${genCustomPropStyle({
    propName: "padding",
    maxSize: 32,
    minSize: 20,
  })}
  border: 2px solid black;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  flex-shrink: 1;
  min-width: 0;
  max-width: 525px;
  box-sizing: border-box;

  @media (max-width: ${sizes.m}) {
    gap: 16px;
  }
`;

export const SuggestionCardTitle = styled.h6`
  ${genTextLimit({
    maxLinesNum: 1,
  })}
`;

export const SuggestionCreator = styled.div`
  font-size: 16px;
  line-height: 18.24px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
`;

export const SuggestionBody = styled.div`
  font-family: "Poppins";
  ${genTextStyle({
    maxFontSize: 18,
    maxLineHeight: 23.4,
    minFontSize: 16,
    minLineHeight: 18.24,
  })}
  ${genTextLimit({
    maxLinesNum: 3,
  })}
`;

export const SuggestionCreatorWrapper = styled.div`
   display: flex;
   flex-direction: column;
   gap: 8px;
`;

export const ButtonWrapper = styled.div`
    cursor: pointer;
    div{
        width: -webkit-fill-available;
        a{
            width: -webkit-fill-available;
        }
        
    }  
`;

export const LoaderWrapper = styled.div`
  display: flex:
  height: 400px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
