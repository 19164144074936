import React from "react";

import { PROJECT_CONTENT_DATA } from 'utility/projects'

import {
  externalLinkTitle,
  menuItemTitle,
  ourServicesTitle,
  techStackTitles,
} from "./constants";
import {
  ClientProps,
  ProjectFilterItem,
  ProjectsItem,
} from "./models";
import routes from "./routes";

export const infoList = [
  { name: "office@lambdaworks.io" },
  { name: "+381 21 303 53 99" },
  { name: "Miše Dimitrijevića 12" },
  { name: "Novi Sad, Serbia" },
];

export const mobileHeaderPages = [
  { name: menuItemTitle.HOME, route: routes.HOME },
  { name: menuItemTitle.PRODUCT_INNOVATION, route: routes.PRODUCT_INNOVATION },
  { name: menuItemTitle.TECH_EXCELLENCE, route: routes.TECH_EXCELLENCE },
  { name: menuItemTitle.SCALA_SERVICES, route: routes.SCALA},
  { name: menuItemTitle.WORK, route: routes.WORK },
  { name: menuItemTitle.CAREERS, route: routes.CAREERS },
  { name: menuItemTitle.COMPANY, route: routes.COMPANY },
  { name: menuItemTitle.CONTACT, route: routes.CONTACT },
  { name: menuItemTitle.BLOG, route: routes.BLOG },
  { name: menuItemTitle.TECH_RADAR, route: "https://lambdaworks.github.io/tech-radar/", external: true, },
  { name: menuItemTitle.KNOWLE, route: routes.KNOWLE },
  { name: menuItemTitle.PRIVACI, route: routes.PRIVACY_POLICY },
];

export const footerPages = [
  { name: menuItemTitle.HOME, route: routes.HOME },
  { name: menuItemTitle.BLOG, route: routes.BLOG },
  { name: menuItemTitle.TECH_RADAR, route: "https://lambdaworks.github.io/tech-radar/", external: true, },
  { name: menuItemTitle.PRODUCT_INNOVATION, route: routes.PRODUCT_INNOVATION },
  { name: menuItemTitle.CAREERS, route: routes.CAREERS },
  { name: menuItemTitle.KNOWLE, route: routes.KNOWLE },
  { name: menuItemTitle.TECH_EXCELLENCE, route: routes.TECH_EXCELLENCE },
  { name: menuItemTitle.SCALA_SERVICES, route: routes.SCALA },
  { name: menuItemTitle.PRIVACI, route: routes.PRIVACY_POLICY },
  { name: menuItemTitle.WORK, route: routes.WORK },
  { name: menuItemTitle.COMPANY, route: routes.COMPANY },
  { name: menuItemTitle.CONTACT, route: routes.CONTACT },
];

export const mobileActiveMenuRoutes = [
  { route: routes.ZIOES, active: routes.WORK },
  { route: routes.TRADER_FYLES, active: routes.WORK },
  { route: routes.THEMES_KINGDOM, active: routes.WORK },
  { route: routes.SUPER_PHONE, active: routes.WORK },
  { route: routes.SHELFIE, active: routes.WORK },
  { route: routes.NOVAK_DJOKOVIC_FOUNDATION, active: routes.WORK },
  { route: routes.NFME, active: routes.WORK },
  { route: routes.NAG_NAG, active: routes.WORK },
  { route: routes.MANILO, active: routes.WORK },
  { route: routes.LIFE_LOGS, active: routes.WORK },
  { route: routes.LAMBERT, active: routes.WORK },
  { route: routes.KNOWLE_PROJECT, active: routes.WORK },
  { route: routes.HERALD, active: routes.WORK },
  { route: routes.EXPORT_ONLINE, active: routes.WORK },
  { route: routes.EL3MENT, active: routes.WORK },
  { route: routes.BIZZIT, active: routes.WORK },
  { route: routes.BEXIO, active: routes.WORK },
  { route: routes.WORK, active: routes.WORK },
  { route: routes.THANKS, active: routes.CONTACT },
  { route: routes.TECH_EXCELLENCE, active: routes.TECH_EXCELLENCE },
  { route: routes.PRODUCT_INNOVATION, active: routes.PRODUCT_INNOVATION },
  { route: routes.INTERNSHIP, active: routes.CONTACT },
  { route: routes.HOME, active: routes.HOME },
  { route: routes.PRIVACY_POLICY, active: routes.PRIVACY_POLICY },
  { route: routes.CONTACT, active: routes.CONTACT },
  { route: routes.COMPANY, active: routes.COMPANY },
  { route: routes.CAREERS, active: routes.CAREERS },
  { route: routes.APPLY, active: routes.CONTACT },
  { route: routes.APPLIED, active: routes.CONTACT },
  { route: routes.NOT_FOUND, active: undefined },
  { route: routes.SCALA, active: routes.SCALA },
  { route: routes.BLOG, active: routes.BLOG },
  { route: routes.KNOWLE, active: routes.KNOWLE },
];

export const webMenuItems = [
  {
    name: menuItemTitle.PRODUCT_INNOVATION,
    link: routes.PRODUCT_INNOVATION,
    route: routes.PRODUCT_INNOVATION,
  },
  {
    name: menuItemTitle.TECH_EXCELLENCE,
    link: routes.TECH_EXCELLENCE,
    route: routes.TECH_EXCELLENCE,
  },
  { name: menuItemTitle.WORK, link:routes.WORK, route: routes.WORK },
  { name: menuItemTitle.BLOG, link: routes.BLOG, route: routes.BLOG },
];

export const homeTitles = [
  { title: "worthy", id: 0 },
  { title: "smart", id: 3 },
  { title: "elegant", id: 2 },
  { title: "awesome", id: 1 },
];

export const ourServicesButtonList: ProjectFilterItem[] = [
  {
    id: 0,
    title: ourServicesTitle.DISCOVERY,
    paragraph: [
      "Discovery workshops",
      "Product consulting",
      "Product prototyping",
      "UX/UI design",
      "Product management",
    ],
  },
  {
    id: 1,
    title: ourServicesTitle.ENGINEERING,
    paragraph: [
      "Web development",
      "Mobile development",
      "AI enablement",
      "Infrastructure engineering",
      "Quality Assurance",
    ],
  },
  {
    id: 2,
    title: ourServicesTitle.ANALYTICS,
    paragraph: [
      "Business intelligence",
      "Custom product analytics",
      "Data aggregation & analysis",
    ],
  },
];

export const aIProcessingList: ProjectFilterItem[] = [
  {
    id: 0,
    title: "Vector database",
    paragraph: [
      "Milvus",
      "Pinecone",
      "Qdrant",
      "Weaviate",
    ],
  },
  {
    id: 1,
    title: "LLM for request processing",
    paragraph: [
      "OpenAI GPT",
      "Meta LLama",
      "Anthropic Claude",
      "MistralAI Mixtral",
      "Falcon",
    ],
  },
];

export const reviews = [
  {
    text: "I’ve worked with numerous levels of engineering, and their resources are some of the best I’ve ever seen.",
    author: "CEO Bizzit,\nRyan Rudin",
  },
  {
    text: "I was impressed with the granular breakdown of task to time completion.",
    author: "Founder & CEO Rizk Media,\nNick Rizk",
  },
  {
    text: "They show ownership of their work and consistently go above and beyond.",
    author: "co-founder LifeLogs,\nMilos Milosevic",
  },
  {
    text: "Simply great, that's how I'd describe the work from the team.",
    author: "CEO Manilo,\nPascal Egger",
  },
  {
    text: "They operate with a high level of efficiency, transparency, and responsiveness.",
    author: "co-founder ThemesKingdom,\nSinisa Komlenic",
  },
  {
    text: "They are truly dedicated to making this product work, full-stop.",
    author: "PM Novak Djokovic Foundation,\nMarko Kovacevic",
  },
  {
    text: "LambdaWorks has been instrumental in bringing out our idea.",
    author: "Founder Get into Gear,\nMatthew Green",
  },
  {
    text: "They are highly collaborative, and that's what we enjoy the most!",
    author: "co-founder el3ment,\nJessica Rosenbluth",
  },
];

export const externalLinks = [
  {
    title: externalLinkTitle.INSTAGRAM,
    link: "https://www.instagram.com/_lambdaworks/",
  },
  {
    title: externalLinkTitle.LINKEDIN,
    link: "https://www.linkedin.com/company/lambdaworksio/",
  },
  {
    title: externalLinkTitle.TWITTER,
    link: "https://twitter.com/lambdaworkshub/",
  },
];

export const areaOfExpertiseList = [
  "New Product Development",
  "MVP Development",
  "Technical Discovery",
  "Product Design",
  "Prototype Development",
];

export const ourCapabilitiesTechExcellenceList = [
  "Digital Transformation",
  "Scala Team as a Service",
  "Team Augmentation",
  "Technical Consulting",
  "New Product Development",
];

export const knowleList = [
  "Scaffolding framework for AI chatbots. ",
  "Accessible to small to mid-size companies",
  "Reduce time and cost of development",
  "High level of customization",
  "Full control of data and security",
];

export const aiProcessingList = [
  "Agnostic approach to integrating with LLMs and vector bases",
  "Improved decision-making based on company knowledge",
  "Enhanced internal communication",
  "Integration with existing workflow automation",
  "24/7 chatbot availability and rapid support",
  "Real-time knowledge base updates",
  "Deployment to the cloud, web, mobile, widget, communication services (Slack, Teams), and even texting services (SMS/WhatsApp, Telegram)"
];

export const vectorDatabaseList = [
  "Milvus",
  "Pinecone",
  "Qdrant",
  "Weaviate",
];

export const llmRequestProcessingList = [
  "OpenAI GPT",
  "Meta LLama",
  "Anthropic Claude",
  "MistralAI Mixtral",
  "Falcon"
];

export const medianCostList = [
  "Less cost, usual range $20k-$60k ",
  "Faster development, usually 4 weeks to 3 months ",
];

export const coreValuesCareersList = [
  {
    heading: "Improving and Innovating",
    paragraph:
      "We aspire to learn and grow with each step we take, valuing every effort, input, or idea for improvement.",
  },
  {
    heading: "Better Together",
    paragraph:
      "We maintain a community of passionate individuals in which everyone is actively engaged in ensuring the success and improvement of our company as a whole.",
  },
  {
    heading: "Value First and Foremost",
    paragraph:
      "We focus on bringing value first to our partners, the tech community and our products.",
  },
  {
    heading: "Adept to Adapt",
    paragraph:
      "While we maintain a vast expertise in many areas of technology, we're not afraid to explore the unknown in order to choose the right solution to the problem at hand.",
  },
  {
    heading: "Well-placed Trust",
    paragraph:
      "We build a connection of trust where we can rely on our team members to give their best in solving their part of the challenges we're facing.",
  },
];

export const initiativeListLeft = [
  {
    id: 1,
    title: "Partnering with accelerators",
    references: (
      <div>
        WeWork Labs
        <br />
        LeADSports
        <br />
        Bluelion
        <br />
        TeslaNation
      </div>
    ),
  },
  {
    id: 3,
    title: "Contributing to OS projects",
    references: (
      <div>
        ZIO
        <br />
        Scountries
        <br />
        Scurl-detector
      </div>
    ),
  },
  {
    id: 2,
    title: "Our in-house products and startups",
    references: (
      <div>
        NagNag
        <br />
        Lambert
      </div>
    ),
  },
];

export const initiativeListRight = [
  {
    id: 2,
    title: null,
    references: (
      <div>
        Herald
        <br />
        Knowle
      </div>
    ),
  },
  {
    id: 4,
    title: "Participating in accelerator programs",
    references: <div>SAP Accelerator NY</div>,
  },
  {
    id: 5,
    title: "Co-organizing tech events",
    references: (
      <div>
        Scala Serbia
        <br />
        Startup Grind Novi Sad
      </div>
    ),
  },
  {
    id: 6,
    title: "Mentoring the local tech community",
    references: (
      <div>
        Startup Weekend
        <br />
        BioSense accelerator
      </div>
    ),
  },
  {
    id: 7,
    title: "Sharing knowledge",
    references: <div>LambdaWorks lightning talks</div>,
  },
];

export const philosophyList = [
  "Understand the problem",
  "Find the right tool for the job",
  "Align early and regularly",
  "Seek elegance",
  "Set realistic expectations",
  "Embrace change",
];

export const projectsAlt = [
  "application development",
  "app development",
  "software development",
];


export const clientList: ClientProps[] = [
  {
    index: 0,
    name: "SuperPhone",
    img: PROJECT_CONTENT_DATA.superphone.poster,
    route: routes.SUPER_PHONE,
    slug: "superphone",
  },
  {
    index: 1,
    name: "bexio",
    img: PROJECT_CONTENT_DATA.bexio.poster,
    route: routes.BEXIO,
    slug: "bexio",
  },
  {
    index: 6,
    name: "EveryBite",
    img: PROJECT_CONTENT_DATA.everyBite.poster,
    route: routes.EVERYBITE,
    slug: "everybite",
  },
  {
    index: 4,
    name: "TraderFyles",
    img: PROJECT_CONTENT_DATA.traderFyles.poster,
    route: routes.TRADER_FYLES,
    slug: "trader-fyles",
  },
  {
    index: 3,
    name: "el3ment",
    img: PROJECT_CONTENT_DATA.element.poster,
    route: routes.EL3MENT,
    slug: "el3ment",
  },
  {
    index: 17,
    name: "Posta Kopilot",
    img: PROJECT_CONTENT_DATA.posta.poster,
    route: routes.POSTA_KOPILOT,
    slug: "postakopilot"
  },
  {
    index: 9,
    name: "Novak Djokovic Foundation",
    img: PROJECT_CONTENT_DATA.djokovic.poster,
    route: routes.NOVAK_DJOKOVIC_FOUNDATION,
    slug: "novak-djokovic-foundation",
  },
  {
    index: 5,
    name: "ExportOnline",
    img: PROJECT_CONTENT_DATA.exportOnline.poster,
    route: routes.EXPORT_ONLINE,
    slug: "export-online",
  },
  {
    index: 18,
    name: "Noon",
    img: PROJECT_CONTENT_DATA.noon.poster,
    route: routes.NOON,
    slug: "noon"
  },
  {
    index: 7,
    name: "BizzIt",
    img: PROJECT_CONTENT_DATA.bizzit.poster,
    route: routes.BIZZIT,
    slug: "bizzit"
  },
  {
    index: 8,
    name: "LifeLogs",
    img: PROJECT_CONTENT_DATA.lifeLog.poster,
    route: routes.LIFE_LOGS,
    slug: "lifelogs",
  },
  {
    index: 10,
    name: "Manilo",
    img: PROJECT_CONTENT_DATA.manilo.poster,
    route: routes.MANILO,
    slug: "manilo",
  },
  {
    index: 11,
    name: "NagNag",
    img: PROJECT_CONTENT_DATA.nagnag.poster,
    route: routes.NAG_NAG,
    slug: "nagnag",
  },
  {
    index: 12,
    name: "Herald",
    img: PROJECT_CONTENT_DATA.herald.poster,
    route: routes.HERALD,
    slug: "herald",
  },
  {
    index: 13,
    name: "Knowle",
    img: PROJECT_CONTENT_DATA.knowle.poster,
    route: routes.KNOWLE_PROJECT,
    slug: "knowle",
  },
  {
    index: 14,
    name: "Lambert",
    img: PROJECT_CONTENT_DATA.lambert.poster,
    route: routes.LAMBERT,
    slug: "lambert",
  },
  {
    index: 15,
    name: "Shelfie",
    img: PROJECT_CONTENT_DATA.shelfie.poster,
    route: routes.SHELFIE,
    slug: "shelfie",
  },
  {
    index: 16,
    name: "ZIO ES",
    img: PROJECT_CONTENT_DATA.zio.poster,
    route: routes.ZIOES,
    slug: "zioes"
  },
  {
    index: 2,
    name: "Themes Kingdom",
    img: PROJECT_CONTENT_DATA.themesKingdom.poster,
    route: routes.THEMES_KINGDOM,
    slug: "themeskingdom",
  },
];

export const projectsContent: ProjectsItem[] = [
  PROJECT_CONTENT_DATA.superphone,
  PROJECT_CONTENT_DATA.bexio,
  PROJECT_CONTENT_DATA.everyBite,
  PROJECT_CONTENT_DATA.themesKingdom,
  PROJECT_CONTENT_DATA.element,
  PROJECT_CONTENT_DATA.traderFyles,
  PROJECT_CONTENT_DATA.exportOnline,
  PROJECT_CONTENT_DATA.nfme,
  PROJECT_CONTENT_DATA.bizzit,
  PROJECT_CONTENT_DATA.lifeLog,
  PROJECT_CONTENT_DATA.djokovic,
  PROJECT_CONTENT_DATA.manilo,
  PROJECT_CONTENT_DATA.nagnag,
  PROJECT_CONTENT_DATA.herald,
  PROJECT_CONTENT_DATA.knowle,
  PROJECT_CONTENT_DATA.lambert,
  PROJECT_CONTENT_DATA.shelfie,
  PROJECT_CONTENT_DATA.zio,
  PROJECT_CONTENT_DATA.posta,
  PROJECT_CONTENT_DATA.noon,
];

export const projectArchive: ProjectsItem[] = [
  PROJECT_CONTENT_DATA.superphone,
  PROJECT_CONTENT_DATA.bexio,
  PROJECT_CONTENT_DATA.everyBite,
  PROJECT_CONTENT_DATA.posta,
  PROJECT_CONTENT_DATA.themesKingdom,
  PROJECT_CONTENT_DATA.element,
  PROJECT_CONTENT_DATA.traderFyles,
  PROJECT_CONTENT_DATA.exportOnline,
  PROJECT_CONTENT_DATA.noon,
  PROJECT_CONTENT_DATA.nfme,
  PROJECT_CONTENT_DATA.bizzit,
  PROJECT_CONTENT_DATA.lifeLog,
  PROJECT_CONTENT_DATA.djokovic,
  PROJECT_CONTENT_DATA.manilo,
  PROJECT_CONTENT_DATA.nagnag,
  PROJECT_CONTENT_DATA.herald,
  PROJECT_CONTENT_DATA.knowle,
  PROJECT_CONTENT_DATA.lambert,
  PROJECT_CONTENT_DATA.shelfie,
  PROJECT_CONTENT_DATA.zio,
];

export const techStackList = [
  {
    title: techStackTitles.DATABASES,
    items:
      "PostgreSQL\nCassandra\nElasticsearch\nRedis\nApache Druid\nDynamoDB\nRedshift",
  },
  {
    title: techStackTitles.LANGUAGES,
    items: "TypeScript\nJavaScript\nScala\nJava\nKotlin\nPython\nRust",
  },
  {
    title: techStackTitles.TOOLS,
    items: "Play\nServerless\nAkka\nZIO\nReact\nReact Native\nFlutter",
  },
  {
    title: techStackTitles.PLATFORMS,
    items: "AWS\nVercel\nGCP\nHetzner\nDigitalOcean",
  },
];
