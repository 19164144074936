import React from "react";

import Form from "src/components/Contact/Form/form";
import Footer from "src/components/Footer/footer";
import { StyledSeparator } from "src/components/Layout/layoutStyle";
import Hero from "components/Hero/hero";
import HeroParagraph from "components/HeroParagraph/heroParagraph";
import { PageContainer } from "src/styles/globalStyle";

const Contact = (): JSX.Element => {
  return (
    <PageContainer>
      <Hero
        title={"Let's make\nsomething amazing\ntogether."}
        image={{
          src: "hero/contactHero.png",
          mobileSrc: "hero/contactHeroMobile.png",
        }}
      />
      <HeroParagraph
        heading="We'd love to make your next big idea a reality or just lend a helping hand."
      />

      <Form />

      <StyledSeparator />

      <Footer />
    </PageContainer>
  );
};

export default React.memo(Contact);
