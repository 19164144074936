import styled from "styled-components";
import { sizes } from "utility/device";

export const StyledSectionTitle = styled.div`
  font-size: 3.333vw;
  line-height: 4vw;
  white-space: pre-wrap;

  @media all and (max-width: ${sizes.m}) {
    width: 100%;
    font-size: 32px;
    line-height: 38.4px;
  }

  @media all and (min-width: ${sizes.xl}) {
    font-size: 64px;
    line-height: 76.8px;
  }
`;
