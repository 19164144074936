import React, { useState, lazy, Suspense } from "react";
import { navigate } from "gatsby";
import { Formik } from "formik";
import axios from "axios";

import InputField from "src/components/InputField/inputField";
import MultiLineTextInput from "src/components/MultilineTextInput/multiLineTextInput";
import { SelectSingleField } from "src/components/SelectField/selectField";
import Button from "src/components/Button/button";
import { ScalaSchema } from "utility/validation/applySchema";
import {
  StyledButtonContainer,
  StyledErrorContainer,
  StyledInternshipTypeLabel,
  StyledRecaptchaContainer,
} from "src/components/Contact/Form/formStyle";
import { accessibility } from "utility/constants";
import { InternshipOptionList } from "utility/models";
import { StyledMainContainer } from "./formStyled";
import routes from "utility/routes";
import { theme } from "utility/theme";

const Recaptcha = lazy(() => import("react-google-recaptcha"));

interface FormProps {
  company: string;
  email: string;
  project: string;
  supportRange: string;
}

interface ParentFormProps {
  optionList: InternshipOptionList;
}

const Form = ({
  optionList,
}: ParentFormProps): JSX.Element => {
  const [recaptchaResponse, setRecaptchaResponse] = useState();
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const onChangeRecaptcha = (value: any) => {
    setRecaptchaResponse(value);
  };

  const getFormData = (data: FormProps): any => {
    const formData = new FormData();

    Object.entries(data).forEach((item) => {
      formData.append(item[0], item[1]);
    });

    return formData;
  };

  const submitForm = (data: FormProps) => {
    if (!recaptchaResponse) {
      return;
    }
    setLoading(true);

    const { company, email, project, supportRange } = data;

    const formData = getFormData(data);

    if (formData.error) {
      setError(formData.error);
      return;
    }
    axios
    .post(
      `https://ewo0rmtef5.execute-api.us-east-1.amazonaws.com/prod/email?recaptcha=${recaptchaResponse}`,
      formData,
      {
        // @ts-ignore
        email,
        subject: `Scala Project Proposal`,
        message: `Company: ${company}\n
          Email: ${email}\n
          Scala support needed: ${supportRange}\n
          Project description: ${project}`,
    })
    .then(() => {
      const isBrowser = typeof window !== `undefined`;
      if (!isBrowser) return;
      navigate(routes.THANKS);
    })
    .catch(() => setError("Request failed to process."))
    .finally(() => setLoading(false));
  };

  const submitCondition = (
    company: string,
    email: string,
    supportRange: string,
    errors: any,
  ) =>
    Object.keys(errors).length > 0 ||
    !!error ||
    !recaptchaResponse ||
    !company.length ||
    !email.length ||
    !supportRange;

  return (
    <StyledMainContainer>
      <Formik
        initialValues={{
          company: "",
          email: "",
          project: "",
          supportRange: "",
        }}
        validationSchema={ScalaSchema}
        onSubmit={(values) => {
          submitForm({ ...values });
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
        }) => (
          <>
            <InputField
              label="What is your company’s name?*"
              placeholder="Company name"
              id="company"
              name="company"
              value={values.company}
              error={touched.company && errors.company}
              isGoodInput={touched.company && !errors.company}
              onChange={handleChange("company")}
              onBlur={handleBlur("company")}
            />
            {errors.company && touched.company ? (
              <StyledErrorContainer>{errors.company}</StyledErrorContainer>
            ) : null}
            <InputField
              label="Your email*"
              placeholder="your@email.com"
              id="email"
              name="email"
              value={values.email}
              error={touched.email && errors.email}
              isGoodInput={touched.email && !errors.email}
              onChange={handleChange("email")}
              onBlur={handleBlur("email")}
            />
            {errors.email && touched.email ? (
              <StyledErrorContainer>{errors.email}</StyledErrorContainer>
            ) : null}

            <MultiLineTextInput
              label="Tell us about your project."
              ariaLabel={accessibility.INTERESTS}
              placeholder="Tell us about your project so we can help you accelerate product development."
              value={values.project}
              onChange={handleChange("project")}
            />
            <StyledInternshipTypeLabel>Please select your planned budget.*</StyledInternshipTypeLabel>
            <SelectSingleField
              groupName="paymentRangeOptions"
              activeValue={values.supportRange}
              selectOption={handleChange("supportRange")}
              radioButtons={optionList}
              highlightColor={theme.colors.homeBackground}
            />
            {errors.supportRange && touched.supportRange ? (
              <StyledErrorContainer>{errors.supportRange}</StyledErrorContainer>
            ) : null}
            <StyledRecaptchaContainer>
              <Suspense fallback={<div></div>}>
                <Recaptcha
                  className="g-recaptcha"
                  sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY ?? ""}
                  onChange={onChangeRecaptcha}
                />
              </Suspense>
            </StyledRecaptchaContainer>
            <StyledButtonContainer>
              <Button
                text="Send"
                fontSize={36}
                mobileFontSize={24}
                centerContent
                justifyContent="center"
                onClick={handleSubmit}
                isfullwidth={false}
                disabled={submitCondition(
                  values.company,
                  values.email,
                  values.supportRange,
                  errors,
                )}
                loading={loading}
              />
            </StyledButtonContainer>
          </>
        )}
      </Formik>
    </StyledMainContainer>
  );
};

export default React.memo(Form);
