import React from "react";

interface RockerSVGProps {
  strokeWidth?: number;
  width?: string | number;
  height?: string | number;
}

const RockerSVG = ({
  strokeWidth = 2,
  width = 547,
  height = 463,
}: RockerSVGProps): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 547 463" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="296.27" cy="391.876" r="70.1237" stroke="black" strokeWidth={strokeWidth}/>
    <line x1="0.626506" y1="434.772" x2="546.627" y2="214.935" stroke="black" strokeWidth={strokeWidth}/>

    <path id="reachPotential-curvyLine" d="M3.87305 413.428C3.87305 220.891 110.918 82.954 299.863 63.5566" stroke="black" strokeWidth={strokeWidth}/>
    <path id="reachPotential-arrow" d="M270.408 94.4483L299.144 63.5562L264.66 41.2852" stroke="black" strokeWidth={strokeWidth} />

    <line id="reachPotential-line1" x1="414.373" y1="0.335937" x2="414.373" y2="126.778" stroke="black" strokeWidth={strokeWidth}/>
    <line id="reachPotential-line2" x1="363.084" y1="62.5566" x2="466.537" y2="62.5566" stroke="black" strokeWidth={strokeWidth}/>
    <line id="reachPotential-line3" x1="377.529" y1="99.4277" x2="450.681" y2="26.2756" stroke="black" strokeWidth={strokeWidth}/>
    <line id="reachPotential-line4" x1="378.943" y1="26.2753" x2="452.095" y2="99.4274" stroke="black" strokeWidth={strokeWidth}/>
  </svg>
)

export default RockerSVG;
