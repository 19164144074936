import styled from "styled-components";
import { sizes } from "utility/device";
import { ColumnContainerMedium, genCustomPropsStyle, genTextStyle } from "src/styles/globalStyle";

export const MainContainer = styled(ColumnContainerMedium)`
  max-width: 1080px;
  min-width: 0;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: center;
`;

export const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const TagsWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const BlogBody = styled.p`
  font-family: Poppins !important;
  font-weight: 400;
  font-style: normal;
  flex: 1;
  word-break: break-word;
  white-space: pre-wrap;
  font-size: 20px;
  line-height: 33.8px;

  @media all and (max-width: ${sizes.m}) {
    font-size: 18px;
    line-height: 23.4px;
  }

  h1 {
    ${genCustomPropsStyle({
      props: [
        { propName: "font-size", maxSize: 96, minSize: 44 },
        { propName: "line-height", maxSize: 109.44, minSize: 49.36 },
        { propName: "margin-top", maxSize: 60, minSize: 32 },
        { propName: "margin-bottom", maxSize: 60, minSize: 32 },
      ]
    })}
    font-family: Moderat-Regular;

    @media all and (max-width: ${sizes.m}) {
      text-align: left !important;
    }
  }

  h2 {
    ${genCustomPropsStyle({
      props: [
        { propName: "font-size", maxSize: 64, minSize: 44 },
        { propName: "line-height", maxSize: 76.8, minSize: 49.36 },
        { propName: "margin-top", maxSize: 60, minSize: 32 },
        { propName: "margin-bottom", maxSize: 60, minSize: 32 },
      ]
    })}
    font-family: Moderat-Regular;

    @media all and (max-width: ${sizes.m}) {
      text-align: left !important;
    }
  }

  h3 {
    ${genCustomPropsStyle({
      props: [
        { propName: "margin-top", maxSize: 60, minSize: 32 },
        { propName: "margin-bottom", maxSize: 60, minSize: 32 },
      ]
    })}
    font-family: Moderat-Regular;

    @media all and (max-width: ${sizes.m}) {
      text-align: left !important;
    }
  }

  h4 {
    ${genCustomPropsStyle({
      props: [
        { propName: "margin-top", maxSize: 60, minSize: 32 },
        { propName: "margin-bottom", maxSize: 60, minSize: 32 },
      ]
    })}
    font-family: Moderat-Regular;

    @media all and (max-width: ${sizes.m}) {
      text-align: left !important;
    }
  }

  p {
    margin-bottom: 12px;
    font-family: Poppins !important;

    @media all and (max-width: ${sizes.m}) {
      text-align: left !important;
    }

    span {
      font-family: Poppins !important;

      @media all and (max-width: ${sizes.m}) {
        text-align: left !important;
      }
    }
  }

  img {
    max-width: 100%;
    margin: 0px;
    flex: 0 0 auto;
    height: auto;
  }

  a {
    text-decoration: none !important;
    color: #EC77FF !important;
    font-family: Poppins !important;

    &:hover {
      text-decoration: underline !important;
    } 
    
    span {
      text-decoration: none !important;
      color: #EC77FF !important;
      font-family: Poppins !important;

      &:hover {
        text-decoration: underline !important;
      } 
    }
  }

  pre:has(> code:only-child) {
    background: #C7C7C74C;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #C7C7C7;
    overflow-x: auto;
  }

  figure {
    margin: 0;
    padding: 0;
  }

  figure:not(.image-style-side):has(> img:only-child) {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-style-side {
    float: right;
    padding: 0px;
    margin: 0 0 30px 30px;
  }

  blockquote {
    margin: 0;
    padding: 16px;
    border-left: 5px solid #C7C7C7;
    font-family: Poppins !important;
  }

  th, td {
    border: 1px solid;
    border-collapse: collapse;
    padding: 8px;
    white-space: pre-wrap;
  }

  table {
    border: 1px solid;
    border-collapse: collapse;
    margin: 0 auto;
    max-width: 100%;
    min-width: 300px;
  }

  .table {
    padding: 0px;
    margin: 0px;
    overflow-x: auto;
    max-width: 100%;
    margin: 40px 0;
  }

  .marker-blue {
    background: #72CCFD;
  }

  .marker-green {
    background: #62F962;
  }

  .marker-pink {
    background: #FC7899;
  }

  .marker-yellow {
    background: #E6E64D;
  }

  .pen-red {
    color: red;
    background: transparent;
  }

  .pen-green {
    color: green;
    background: transparent;
  }

  .todo-list {
    list-style-type: none;
    padding: 0 0 0 16px;
  }

  figcaption {
    text-align: center;
    font-style: italic;
    font-size: 18px;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  li {
    position: relative;
    font-family: Poppins;
    font-size: 18px;
    margin-left: 14px !important;
    margin-bottom: 16px;
    padding: 0px 0px 0px 14px;
    box-sizing: border-box;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 100%;
      background: ${(props) => props.theme.colors.black};
      width: 5px;
      height: 5px;
      margin: 0px;
      padding: 0px;
      margin-top: 12px;

      @media (min-width: 1700px) {
        margin-top: 15px;
      }
    }
  }
`;
