import React from 'react';
import {
  StyledDragIndicatorContainer,
} from './imageHoverIndicatorStyle';
import { isTouchDevice } from "utility/functions";

interface ImageHoverIndicatorProps {
  hidden: boolean;
  position: { x: number, y: number };
}

const ImageHoverIndicator = ({ hidden, position }: ImageHoverIndicatorProps) => {
  return (
      <StyledDragIndicatorContainer
        hidden={isTouchDevice || hidden}
        style={{ left: `${position.x}px`, top: `${position.y}px` }}
      >
        <div className="drag-spinner">
          <p>View<br />project</p>
        </div>
      </StyledDragIndicatorContainer>
    
  );
};

export default React.memo(ImageHoverIndicator);
