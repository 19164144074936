import React, { lazy, Suspense } from "react";

// import Bloomberg from "src/images/bloomberg.svg";
const Bloomberg = lazy(() => import("src/images/bloomberg.svg"));

// import BusinessInsider from "src/images/businessInsider.svg";
const BusinessInsider = lazy(() => import("src/images/businessInsider.svg"))

// import Cnbc from "src/images/cnbc.svg";
const Cnbc = lazy(() => import("src/images/cnbc.svg"));

// import Forbes from "src/images/forbes.svg";
const Forbes = lazy(() => import('src/images/forbes.svg'));

// import Nasdaq from "src/images/nasdaq.svg";
const Nasdaq = lazy(() => import("src/images/nasdaq.svg"));

// import NewYorker from "src/images/newYorker.svg";
const NewYorker = lazy(() => import("src/images/newYorker.svg"));

// import Tc from "src/images/tc.svg";
const Tc = lazy(() => import("src/images/tc.svg"));
// import Yahoo from "src/images/yahoo.svg";
const Yahoo = lazy(() => import("src/images/yahoo.svg"));

import { LogoList } from "components/LogoList/logoList";

export const NoticedIn = () =>  (
  <Suspense fallback={<div></div>}>
    <LogoList
      heading={"Our work was\nfeatured in."}
      logos={ [
        <Forbes />,
        <Tc />,
        <Cnbc />,
        <Nasdaq />,
        <Yahoo />,
        <Bloomberg />,
        <NewYorker />,
        <BusinessInsider />,
      ]}
    />
  </Suspense>
);
