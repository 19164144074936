import React, { lazy, Suspense } from "react";

import Footer from "src/components/Footer/footer";
import SectionAnimation from "src/components/SectionAnimation/sectionAnimation";
import { StyledSeparator } from "src/components/Layout/layoutStyle";
import Badges from "./Badges/badges";

import InitiativesList from "./InitiativesList/initiativesList";
import Hero from "components/Hero/hero";
import HeroParagraph from "components/HeroParagraph/heroParagraph";
import { PageContainer } from "src/styles/globalStyle";

const Philosophy = lazy(() => import("./Philosophy/philosophy"));

const Company = (): JSX.Element => {
  return (
    <PageContainer>
      <SectionAnimation>
        <Hero
          title={"We're a digital\nproduct agency."}
          image={{
            src: "hero/companyHero.png",
            mobileSrc: "hero/companyHeroMobile.png",
          }}
        />
        <HeroParagraph
          heading="What we believe a digital product agency should stand for? "
          text="A digital product agency isn’t just a software development company. A digital product agency guides you and your product; from the initial idea to concept to a working solution, strategizing, iterating, validating, and testing throughout the process. A digital product agency is a partner instead of a vendor. A digital product agency creates the right product at the right pace to meet the needs of your business."
        />
      </SectionAnimation>
      <SectionAnimation>
        <InitiativesList />
      </SectionAnimation>

      <Suspense fallback={<div></div>}>
        <SectionAnimation>
          <Philosophy />
        </SectionAnimation>
      </Suspense>

      <SectionAnimation>
        <Badges />
      </SectionAnimation>

      <StyledSeparator />
      <Footer />
    </PageContainer>
  );
};

export default React.memo(Company);
