import React from "react";

import { techStackList } from "utility/lists";
import { TechStackItem } from "utility/models";
import { ButtonType } from "utility/theme";
import Button from "src/components/Button/button";
import TechStackColumn from "./techStackColumn";

import {
  Container,
  ContentWrapper,
  MobileTitle,
  StyledTechStackMainContainer,
} from "./techStackStyle";
import SectionTitle from "components/SectionTitle/sectionTitle";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const TechStack = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isDesktopView = breakpoints.m;
  
  return (
    <Container>
      {isDesktopView && <SectionTitle text="Tech we believe in." />}
      <ContentWrapper>
        {!isDesktopView && <MobileTitle>{"Tech we\nbelieve in."}</MobileTitle>}
        <StyledTechStackMainContainer>
          {techStackList.map((item: TechStackItem) => (
            <TechStackColumn item={item} key={item.title} />
          ))}
        </StyledTechStackMainContainer>
      </ContentWrapper>
      <a href={"https://lambdaworks.github.io/tech-radar/"} target="blank">
        <Button
          text="Tech Radar"
          type={ButtonType.BLACK}
        />
      </a>
    </Container>
  );
};

export default TechStack;
