import React, { useState, useEffect } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import CookieConsent from "src/components/CookieConsent/cookieConsent";
import Layout from "src/components/Layout/layout";
import { theme } from "utility/theme";
import routes from "utility/routes";
import OurWork from "components/OurWork/ourWork";
import { LocationPropI } from "utility/models";

interface PageProps {
  location: LocationPropI;
}

const OurWorkPage = ({ location }: PageProps): JSX.Element => {
  const [menuColor, setMenuColor] = useState(theme.colors.white);
  const [isCookieVisible, setCookieVisible] = useState<boolean>(false);

  const handleColor = (isMenuOpened: boolean) => {
    isMenuOpened
      ? setMenuColor(theme.colors.black)
      : setMenuColor(theme.colors.white);
  };

  const breakpoints = useBreakpoint();
  const isDesktop = breakpoints.l;
  const locationPath = location.pathname;

  const handleVisibility = (a: boolean) => {
    setCookieVisible(a);
  };

  useEffect(() => {
    if (isDesktop) {
      setMenuColor(theme.colors.white);
    }
  }, [isDesktop]);

  return (
    <Layout
      backgroundColor={menuColor}
      headerColor={theme.colors.pink}
      headerRoute={routes.WORK}
      setBackgroundColor={handleColor}
      location={locationPath}
      isCookieVisible={isCookieVisible}
    >
      <OurWork />
      <CookieConsent getCookieVisibility={handleVisibility} />
    </Layout>
  );
};

export default OurWorkPage;
