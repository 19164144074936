import React from "react";
import { navigate } from "gatsby";

import { ButtonType } from "utility/theme";
import { StyledSeparator } from "src/components/Layout/layoutStyle";
import Footer from "src/components/Footer/footer";
import { HeadingAndSubheading } from "src/components/Blog/Heading/heading";
import { ButtonWrapper, ContentContainer, TopContentContainer } from "./blogStyle";
import Button from "components/Button/button";
import { SubscribeForm } from "components/SubscribeForm/subscribeForm";
import routes from "utility/routes";
import { BlogSuggestions } from "./BlogSuggestions/blogSuggestions";
import { LocationPropI } from "utility/models";
import { PageContainer } from "src/styles/globalStyle";

const heading = "Sorry something went wrong this time...";
const buttonText = "Return to blog page";

interface IBlogError {
  type: "tag" | "blog";
  location: LocationPropI;
}

export const BlogError = ({ type = "tag", location }: IBlogError) => {
  const subheading =
    `Looks like the ${type} you’re trying to filter is no longer available.`;
  
  const handleClick = () => {
    navigate(routes.BLOG);
  };

  return (
    <PageContainer>
      <ContentContainer>
        <TopContentContainer>
          <HeadingAndSubheading heading={heading} subheading={subheading} />
          <ButtonWrapper>
            <Button
              text={buttonText}
              fontSize={36}
              mobileFontSize={24}
              type={ButtonType.BLACK}
              arrowType={null}
              onClick={handleClick}
            />
          </ButtonWrapper>
        </TopContentContainer>
        
        <BlogSuggestions blog={null} location={location} />
        <SubscribeForm />
      </ContentContainer>
      <StyledSeparator />
      <Footer />
    </PageContainer>
  );
};
