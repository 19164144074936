import React, { useEffect } from "react";
import { useInView, InView } from "react-intersection-observer";

import SectionTitle from "src/components/SectionTitle/sectionTitle";

import {
  StyledDottedListParagraphContainer,
} from "./companyDottedListStyle";
import CompanyDottedListAnimated from "./companyDottedListAnimated";
import { companyAnimationDelay } from "utility/constants"
import { ResponsiveColumnContainer } from "src/styles/globalStyle";

type CompanyDottedListProps = {
  title: string;
  list: Array<string>;
};

const CompanyDottedList = ({
  list,
  title,
}: CompanyDottedListProps): JSX.Element => {
  const [isDisplayed, setDisplayed] = React.useState<boolean>(false);

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    inView && setTimeout(() => setDisplayed(true), companyAnimationDelay);
  }, [inView]);

  return (
    <ResponsiveColumnContainer maxGap={120} minGap={32}>
      <h2>{title}</h2>
      <StyledDottedListParagraphContainer ref={ref}>
        {inView || isDisplayed ? (
          <CompanyDottedListAnimated list={list} />
        ) : (
          <InView as="div" style={{ opacity: 0 }}>
            <CompanyDottedListAnimated list={list} />
          </InView>
        )}
      </StyledDottedListParagraphContainer>
    </ResponsiveColumnContainer>
    );
};

export default React.memo(CompanyDottedList);
