import * as React from "react";
import { StyledSectionTitle } from "./sectionTitleStyle";

export type SectionTitleProps = {
  text: string | JSX.Element;
};

export const SectionTitle = ({ text }: SectionTitleProps) => (
  <StyledSectionTitle>{text}</StyledSectionTitle>
);

export default SectionTitle;
