import React, { useEffect } from "react";

import { areaOfExpertiseList } from "utility/lists";
import DottedList from "src/components/DottedList/dottedList";

import {
  StyledAreaOfExpertiseMainContainer,
} from "./areaOfExpertiseStyle";

const AreaOfExpertise = (): JSX.Element => (
  <StyledAreaOfExpertiseMainContainer>
    <DottedList
      title={"Our areas of expertise."}
      list={areaOfExpertiseList}
    />
  </StyledAreaOfExpertiseMainContainer>
);

export default React.memo(AreaOfExpertise);
