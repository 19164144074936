import React from "react";

import {
  ListItemWrraper,
  StyledDottedListContainer,
  StyledHeading,
  StyledParagraph,
} from "./dottedListStyle";

interface ListWithParagraph {
  heading: string;
  paragraph: string;
}

type DottedListProps = {
  title: string;
  list: Array<string>;
  hidePadding?: boolean;
};

type DottedListWithParagraphProps = Omit<DottedListProps, "list"> & {
  list: Array<ListWithParagraph>;
};

const DottedList = ({
  list,
  title,
  hidePadding = false,
}: DottedListProps): JSX.Element => (
  <StyledDottedListContainer hidePadding={hidePadding}>
    <h2>{title}</h2>
    <ul>
      {list.map((item: string) => (
        <li>{item}</li>
      ))}
    </ul>
  </StyledDottedListContainer>
);

export const DottedListWithParagraph = ({
  list,
  title,
  hidePadding = false,
}: DottedListWithParagraphProps): JSX.Element => (
  <StyledDottedListContainer hidePadding={hidePadding}>
    <h2>{title}</h2>
    <ul>
      {list.map((item) => (
        <li key={item.heading}>
          <ListItemWrraper>
            <StyledHeading>
              {item.heading}
            </StyledHeading>
            <StyledParagraph>{item.paragraph}</StyledParagraph>
          </ListItemWrraper>
        </li>
      ))}
    </ul>
  </StyledDottedListContainer>
);

export default React.memo(DottedList);
