import React from "react";

import {
  StyledDesktopContainer,
  StyledHeroContainer,
  StyledHeroImage,
  StyledHeroParagraph,
  StyledHeroTitle,
  StyledMobileContainer,
  StyledSectionText,
  StyledTextContainer,
} from "./scalaHeroStyled";
import Button from "components/Button/button";
import { ButtonType } from "utility/theme";
import { ColumnContainer } from "src/styles/globalStyle";
import { navigate } from "gatsby";
import routes from "utility/routes";

type ScalaHeroSectionProps = {
  heading: string | JSX.Element;
  title: string | JSX.Element;
  image: {
    src: string;
    mobileSrc?: string;
  }
  paragraph: string | JSX.Element;
  hideButton?: boolean;
};

const ScalaHero = ({
  heading,
  title,
  paragraph,
  image,
  hideButton = false,
}: ScalaHeroSectionProps): JSX.Element => (
  <StyledHeroContainer>
    <StyledTextContainer>
      <StyledSectionText>
        {heading}
      </StyledSectionText>
      <ColumnContainer gap={60}>
        <StyledHeroTitle>{title}</StyledHeroTitle>
        <StyledDesktopContainer gap={60}>
          <StyledHeroParagraph>{paragraph}</StyledHeroParagraph>
          {!hideButton && (
            <Button
              text="Tell us about your project"
              onClick={() => navigate(routes.SCALA_PROJECT_PROPOSAL)}
              type={ButtonType.BLACK}
            />
          )}
          
        </StyledDesktopContainer>
      </ColumnContainer>
    </StyledTextContainer>
    
    <StyledHeroImage url={image.src} mobileUrl={image?.mobileSrc} title="Scala development company" />
    <StyledMobileContainer gap={60}>
      <StyledHeroParagraph>{paragraph}</StyledHeroParagraph>
      {!hideButton && (
        <Button
          text="Tell us about your project"
          onClick={() => navigate(routes.SCALA_PROJECT_PROPOSAL)}
          mobileFontSize={24}
          type={ButtonType.BLACK}
        />
      )}
    </StyledMobileContainer>
  </StyledHeroContainer>
);

export default ScalaHero;
