import { genCustomPropsStyle } from "src/styles/globalStyle";
import styled from "styled-components";

import { sizes } from "utility/device";

export const StyledMainContainer = styled.div`
  max-width: 932px;

  ${genCustomPropsStyle({
      props: [
        { propName: "padding-top", maxSize: 120, minSize: 60 },
        { propName: "padding-bottom", maxSize: 120, minSize: 60 },
      ]
  })}
`;
