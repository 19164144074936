import React from "react";
import { theme } from "utility/theme";

interface ArrowOutRightSVGProps {
  color?: string;
  width?: string | number;
  height?: string | number;
}

const ArrowOutRightSVG = ({
  color = theme.colors.black,
  width = 13,
  height = 13,
}: ArrowOutRightSVGProps): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.0675 0H1.35175V1.21447H10.2945L0 11.509L0.858757 12.3677L11.0675 2.159V10.9302H12.2819V1.21447V0H11.0675Z" fill={color} />
  </svg>

)

export default ArrowOutRightSVG;
