import React, { useState, useEffect } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { navigate } from "gatsby";

import Layout from "src/components/Layout/layout";
import CookieConsent from "src/components/CookieConsent/cookieConsent";
import { theme } from "utility/theme";
import routes from "utility/routes";
import Internship from "components/Internship/internship";
import { InternshipPosition, LocationPropI } from "utility/models";
import CareersApi from "services/Careers.api";
import NotFoundPage from "./404";

const careersApi = new CareersApi();

interface PageProps {
  location: LocationPropI;
  serverData: { isInternshipAvailable: boolean; positions: InternshipPosition[] };
}

const InternshipPage = ({ location, serverData }: PageProps): JSX.Element => {
  const name = "Internship • LambdaWorks";
  const description =
    "We're really excited to get to know you and we're looking forward to our first call.";

  const [menuColor, setMenuColor] = useState(theme.colors.white);
  const [isCookieVisible, setCookieVisible] = useState<boolean>(false);

  const handleColor = (isMenuOpened: boolean) => {
    isMenuOpened
      ? setMenuColor(theme.colors.black)
      : setMenuColor(theme.colors.white);
  };

  const breakpoints = useBreakpoint();
  const isDesktop = breakpoints.l;
  const locationPath = location.pathname;

  useEffect(() => {
    if (isDesktop) {
      setMenuColor(theme.colors.white);
    }
  }, [isDesktop]);

  const handleVisibility = (a: boolean) => {
    setCookieVisible(a);
  };

  useEffect(() => {
    if (!serverData.isInternshipAvailable) {
      navigate(routes.NOT_FOUND, { replace: true });
    }
  }, [serverData?.isInternshipAvailable]);

  if (!serverData.isInternshipAvailable) {
    return <NotFoundPage location={location} />;
  }

  return (
    <Layout
      backgroundColor={menuColor}
      headerColor={theme.colors.orangeYellow}
      setBackgroundColor={handleColor}
      location={locationPath}
      headerRoute={routes.INTERNSHIP}
      isCookieVisible={isCookieVisible}
      isGetInTouchHidden
    >
      <Internship positions={serverData.positions?.map((item) => ({
        value: item.name,
        label: item.name,
        disabled: !item.active,
      } as unknown as InternshipPosition))} />
      <CookieConsent getCookieVisibility={handleVisibility} />
    </Layout>
  );
};

export default InternshipPage;

export const getServerData = async (): Promise<{
  props: {
    isInternshipAvailable: boolean,
    positions?: InternshipPosition[];
  },
}> => {
  try {
    const response = await careersApi.getInternships();
    const positions = response.data.internship.data.attributes.positions.filter((item: any) => item.active);
    const activePositions = positions.filter((item: any) => item.active);
    if (!activePositions.length) {
      return { props: { isInternshipAvailable: false } };
    }
    return { props: { isInternshipAvailable: true, positions } };
  } catch (error) {
    return { props: { isInternshipAvailable: false } };
  }
}
