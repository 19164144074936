import React from "react";

import {
  StyledHeroContainer,
  StyledHeroImage,
  StyledHeroTitle,
} from "./heroStyled";

type HeroSectionProps = {
  title: string | JSX.Element;
  image: {
    src: string;
    mobileSrc?: string;
  }
};

const Hero = ({
  title,
  image,
}: HeroSectionProps): JSX.Element => (
  <StyledHeroContainer>
    <StyledHeroTitle>{title}</StyledHeroTitle>
    <StyledHeroImage url={image.src} mobileUrl={image?.mobileSrc} />
  </StyledHeroContainer>
);

export default Hero;
