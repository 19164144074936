import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import SectionAnimation from "src/components/SectionAnimation/sectionAnimation";
import { StyledSeparator } from "src/components/Layout/layoutStyle";
import PositionItem from "../PositionItem/positionItem";

import {
  StyledOpenPositionMainContainer,
  StyledOpenPositionWrapper,
} from "./openPositionsStyle";
import { IOpenPosition } from "utility/models";

interface OpenPositionProps {
  errorState: boolean;
  openPositions: IOpenPosition[];
}

const OpenPosition = ({ errorState, openPositions }: OpenPositionProps): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isBigScreen = breakpoints.s;

  return (
    <>
      <StyledOpenPositionMainContainer>
        <StyledOpenPositionWrapper>
          <h2>{isBigScreen ? "Open positions" : "Open\npositions"}</h2>
        </StyledOpenPositionWrapper>
      </StyledOpenPositionMainContainer>
      {!errorState && !!openPositions?.length && openPositions.map((item) => (
        <div key={item.type}>
          <SectionAnimation>
            <PositionItem {...item} />
          </SectionAnimation>
          <StyledSeparator />
        </div>
      ))}
    </>
  );
};

export default OpenPosition;
