import styled from "styled-components";
import { sizes } from "utility/device";

export const MedianCostContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 40px;

  justify-content: space-between;

  @media all and (max-width: ${sizes.m}) {
    flex-direction: column;
    padding-bottom: 20px;
  }
`;
