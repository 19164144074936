import styled from "styled-components";
import { sizes } from "utility/device";
import Circle from "src/images/capabilitiesCircle.svg";
import { genSizeStyle } from "src/styles/globalStyle";

export const StyledOCMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @media all and (max-width: ${sizes.m}) {
    flex-direction: column;
  }
`;

export const StyledOCCircleContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  max-width: 496px;
  width: 100%;

  @media all and (max-width: ${sizes.s}) {
    align-items: flex-start;
  }
`;

export const StyledCircle = styled(Circle)`
  ${genSizeStyle({
    maxWidth: 530,
    maxHeight: 260,
    minWidth: 328,
    minHeight: 161,
  })}
`;
