import React from "react";

import LetsTalkSection from "components/LetsTalkSection/letsTalkSection";
import { useBreakpoint } from "gatsby-plugin-breakpoints";


const BringIdeaToLife = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMediumScreen = breakpoints.m;

  return (
    <LetsTalkSection
      link="/contact"
      title={!isMediumScreen ? "We can help bring your next idea to life." : "We can help bring your\nnext idea to life."}
      buttonText="Let's talk"
      page="potential"
    />
  );
};

export default BringIdeaToLife;
