import React, { useEffect, useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import Footer from "src/components/Footer/footer";
import CookieConsent from "src/components/CookieConsent/cookieConsent";
import Layout from "src/components/Layout/layout";
import { theme } from "utility/theme";
import routes from "utility/routes";
import { LocationPropI } from "utility/models";
import Knowle from "components/Knowle";

interface PageProps {
  location: LocationPropI;
}

const KnowlePage = ({ location }: PageProps): JSX.Element => {
  const name = "Knowle • LambdaWorks";
  const description =
    "AI chatbot development from the ground up is often too expensive, making it accessible only to larger organizations. On the other hand, all SaaS AI services carry a great risk of data exposure and pose security risks.";

  const [menuColor, setMenuColor] = useState(theme.colors.lavander);
  const [isCookieVisible, setCookieVisible] = useState<boolean>(false);

  const handleColor = (isMenuOpened: boolean) => {
    isMenuOpened
      ? setMenuColor(theme.colors.black)
      : setMenuColor(theme.colors.lavander);
  };
  const breakpoints = useBreakpoint();
  const isDesktop = breakpoints.l;
  const locationPath = location.pathname;

  useEffect(() => {
    if (isDesktop) {
      setMenuColor(theme.colors.lavander);
    }
  }, [isDesktop]);

  return (
    <Layout
      backgroundColor={menuColor}
      headerColor={theme.colors.black}
      setBackgroundColor={handleColor}
      location={locationPath}
      headerRoute={routes.KNOWLE}
      isCookieVisible={isCookieVisible}
    >
      <Knowle />
      <Footer />
      <CookieConsent getCookieVisibility={setCookieVisible} />
    </Layout>
  );
};

export default KnowlePage;
