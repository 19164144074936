import React from "react";

import { PROJECT_CONTENT_DATA } from 'utility/projects'
import routes from "utility/routes";
import CarouselSection from "src/components/Carousel/carousel";
import { findStaticImg, mapStaticImgList } from "utility/functions";

const CraftingProductsCarousel = (): JSX.Element => {
  const imagePaths = mapStaticImgList("projects", [
    PROJECT_CONTENT_DATA.element.poster,
    PROJECT_CONTENT_DATA.themesKingdom.poster,
    PROJECT_CONTENT_DATA.lifeLog.poster,
    PROJECT_CONTENT_DATA.superphone.poster,
  ]);

  const images = [
    {
      id: 0,
      url: findStaticImg(imagePaths, PROJECT_CONTENT_DATA.element.poster),
      project: "el3ment",
      title: "Transforming ideas into products",
      route: routes.EL3MENT,
      paragraph:
        "Validating the idea by turning it into a product. Working with cross-functional and integrated teams to deliver heaps of value.",
    },
    {
      id: 1,
      url: findStaticImg(imagePaths, PROJECT_CONTENT_DATA.superphone.poster),
      project: "SuperPhone",
      title: "Building world-class platforms",
      route: routes.SUPER_PHONE,
      paragraph:
        "Building world-class platforms Starting small and evolving into years-long partnerships where we keep building and improving the technology behind the product.",
    },
    {
      id: 2,
      url: findStaticImg(imagePaths, PROJECT_CONTENT_DATA.themesKingdom.poster),
      project: "ThemesKingdom Sites",
      title: "Developing complex applications",
      route: routes.THEMES_KINGDOM,
      paragraph:
        "Developing complex applications Conceptualizing the architecture and finding optimal solutions to deliver highly scalable products, while optimizing running costs.",
    },
    {
      id: 3,
      url: findStaticImg(imagePaths, PROJECT_CONTENT_DATA.lifeLog.poster),
      project: "LifeLogs",
      title: "Full product development",
      route: routes.LIFE_LOGS,
      paragraph:
        "Transforming ideas into working products one step at a time. From initial technical consulting, to product workshops, to delivering a working product.",
    },
  ];

  return (
    <CarouselSection
      images={images}
      title={"Crafting digital products\nof any scale."}
    />
  );
};

export default React.memo(CraftingProductsCarousel);
