import styled from "styled-components";

import { sizes } from "utility/device";
import { genTextStyle, MediumHeading } from "src/styles/globalStyle";

export const StyledContainer = styled.div`
  padding-bottom: 60px;
  padding-top: 60px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 32px;

  @media (max-width: ${sizes.mediumS}) {
    flex-direction: column;
  }
`;

export const StyledTitle = styled(MediumHeading)`
  flex: 1;
  max-width: 653px;
  min-width: 255px;
  width: 303.216vw;

  @media (max-width: ${sizes.mediumS}) {
    width: 100%;
  }
`;

export const StyledLstContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1090px;
  width: 63vw;
  ${genTextStyle({
    maxFontSize: 24,
    maxLineHeight: 28.8,
    minFontSize: 18,
    minLineHeight: 23.4,
  })}

  @media (max-width: ${sizes.mediumS}) {
    width: 100%;
  }
`;
