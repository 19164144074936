import React, { useRef, useState, lazy, Suspense } from "react";
import axios from "axios";
import { Formik } from "formik";
import { navigate } from "gatsby";
import Recapcha from "react-google-recaptcha";

import InputField from "src/components/InputField/inputField";
import MultiLineTextInput from "src/components/MultilineTextInput/multiLineTextInput";
import Button from "src/components/Button/button";
import routes from "utility/routes";
import { ContactSchema } from "utility/validation/contactSchema";
import { accessibility } from "utility/constants";

import {
  StyledButtonContainer,
  StyledMainContainer,
  StyledErrorContainer,
} from "./formStyle";

const Recaptcha = lazy(() => import("react-google-recaptcha"));

interface FormProps {
  email: string;
  message: string;
}

const Form = (): JSX.Element => {
  const captchaRef = useRef<Recapcha | null>(null);

  const [recaptchaResponse, setRecaptchaResponse] = useState();
  const [loading, setLoading] = useState<boolean>(false);

  const onChangeRecaptcha = (value: any) => {
    setRecaptchaResponse(value);
  };

  const submitForm = ({
    email,
    message,
  }: FormProps) => {
    if (!recaptchaResponse) {
      return false;
    }
    setLoading(true);

    axios
      .post(
        `https://ewo0rmtef5.execute-api.us-east-1.amazonaws.com/prod/email?recaptcha=${recaptchaResponse}`,
        {
          email: email,
          subject: `Contact submission`,
          message: `From: \n
      Email: ${email}\n
      Message: ${message}\n
      `,
        }
      )
      .then(function (response) {
        if (response.status == 202) navigate(routes.THANKS);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
    return true;
  };

  const submitCondition = (email: string, message: string, errors: any) =>
    !recaptchaResponse || Object.keys(errors)?.length > 0 || !email.length || !message.length;

  return (
    <StyledMainContainer>
      <Formik
        initialValues={{
          email: "",
          message: "",
        }}
        validationSchema={ContactSchema}
        onSubmit={(values) => {
          submitForm(values);
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
        }) => (
          <>
            <InputField
              label="Your email *"
              placeholder="your@email.com"
              id="email"
              name="email"
              value={values.email}
              error={touched.email && errors.email}
              isGoodInput={touched.email && !errors.email}
              onChange={handleChange("email")}
              onBlur={handleBlur("email")}
            />
            {errors.email && touched.email ? (
            <StyledErrorContainer>{errors.email}</StyledErrorContainer>
            ) : null}
            <MultiLineTextInput
              label="How can we help? *"
              ariaLabel={accessibility.MESSAGE}
              placeholder="Your thoughts and ideas."
              value={values.message}
              onChange={handleChange("message")}
            />
            <div style={{ marginTop: 40 }}>
              <Suspense fallback={<div></div>}>
                <Recaptcha
                  ref={captchaRef}
                  className="g-recaptcha"
                  sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY ?? ""}
                  onChange={onChangeRecaptcha}
                />
              </Suspense>
            </div>
            <StyledButtonContainer>
              <Button
                text="Send"
                fontSize={36}
                onClick={handleSubmit}
                disabled={submitCondition(
                  values.email,
                  values.message,
                  errors,
                )}
                loading={loading}
              />
            </StyledButtonContainer>
          </>
        )}
      </Formik>
    </StyledMainContainer>
  );
};

export default React.memo(Form);
