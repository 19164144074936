import React from "react";
import queryString from "query-string";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import { Tag } from "./tagStyle";
import routes from "utility/routes";

interface BlogTagPropI {
  tag: string;
}

export const BlogTag = ({ tag }: BlogTagPropI): JSX.Element => {
  const location = useLocation();

  const isSelected = queryString.parse(location.search)?.tag === tag;
  const handleSelect = () => {
    navigate(`${routes.BLOG}?tag=${encodeURI(tag)}`);
  };

  return (
    <Tag onClick={!isSelected ? handleSelect : () => {}} isSelected={isSelected}>
      {tag}
      <div></div>
    </Tag>
  );
};
