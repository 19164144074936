import styled from "styled-components";

import { sizes } from "utility/device";
import { ColumnContainer, genSizeStyle, genTextStyle } from "src/styles/globalStyle";

export const StyledHeroContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;

  @media (max-width: ${sizes.m}) {
    align-items: center;
  }

  @media (max-width: ${sizes.mediumS}) {
   flex-direction: column;
   align-items: flex-start;
   gap: 60px;
  }
`;

export const StyledDesktopContainer = styled(ColumnContainer)`
  @media (max-width: ${sizes.mediumS}) {
    display: none;
  }
`;

export const StyledMobileContainer = styled(ColumnContainer)`
  padding-bottom: 64px;

  @media (min-width: ${sizes.mediumS}) {
    display: none;
  }
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  padding-bottom: 60px;

  @media (max-width: ${sizes.mediumS}) {
    padding-bottom: 0px;
  }
`;

export const StyledSectionText = styled.div`
  ${genTextStyle({
    maxFontSize: 34,
    maxLineHeight: 39.6,
    minFontSize: 22,
    minLineHeight: 24,
  })}
  color: #00000080;

  @media (max-width: ${sizes.mediumS}) {
    padding-top: 64px;
  }
`;

export const StyledHeroTitle = styled.h1`
  display: inline-block;
  white-space: nowrap;
  overflow: visible;

  @media (max-width: ${sizes.m}) {
    white-space: pre-wrap;
  }
  
  @media (max-width: ${sizes.mediumS}) {
    margin-bottom: 0;
  }
`;

export const StyledHeroParagraph = styled.p`
  ${genTextStyle({
    maxFontSize: 24,
    minFontSize: 18,
    maxLineHeight: 28,
    minLineHeight: 21,
  })}
`;

export const StyledHeroImage = styled.div<{ url: string; mobileUrl?: string; }>`
  ${genSizeStyle({
    maxWidth: 800,
    maxHeight: 900,
    minWidth: 246,
    minHeight: 276.75,
  })}
  background-image: ${({ url }) => `url(/${url})`};
  background-position: bottom-right;
  background-size: contain;
  background-repeat: no-repeat;
  max-height: 40vw;

  @media (max-width: ${sizes.mediumS}) {
    width: 260px;
    height: 260px;
    max-height: 260px;
    align-self: center;
    background-image: ${({ url, mobileUrl }) => `url(/${mobileUrl ?? url})`};
  }
`;
