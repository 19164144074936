import styled from "styled-components";

export const StyledDragIndicatorContainer = styled.div<{
  hidden: boolean,
}>`
  width: 117px;
  height: 117px;
  border-radius: 100px;
  background-color: #000000;
  position: fixed;
  display: ${({ hidden }) => hidden ? "none" : "flex"};
  justify-content: center;
  align-items: center;
  text-align: center;

  transform: translate(-50%, -50%);
  transition: all 100ms ease;
  transition-property: opacity;
  pointer-events: none;
  z-index: 1;
  animation: scale-animation 300ms;

  .drag-spinner {
    animation-delay: 500ms;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
  }

  p {
    color: #FFFFFF;
    font-weight: 400;
    line-height: 22px;
    animation-name: opacity-animation;
    animation-duration: 500ms;
    animation-delay: 100ms;
    animation-fill-mode: forwards;
    opacity: 0;
    margin-bottom: 5px;
  }

  @keyframes scale-animation {
    from { height: 0px; width: 0px; }
    to { height: 117px; width: 117px; }
  }

  @keyframes opacity-animation {
    from { opacity: 0; font-size: 0px; }
    to { opacity: 1; font-size: 18px; }
  }
`;

export const StyledArrowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
