import React from "react";

import routes, { BLOG_ROUTES } from "./routes";

type PageSEO = {
  title: string;
  description: string;
  ogImage: string;
}

export const DEFAULT_SEO_LINKS = {
  links: [
    <link rel="icon" sizes="512x512" type="image/png" href={`${process.env.GATSBY_URL}/favicon/favicon.png`} key="icon" />,
    <link rel="icon" type="image/svg+xml" href={`${process.env.GATSBY_URL}/favicon/favicon.svg`} />,
    <link rel="shortcut icon" href={`${process.env.GATSBY_URL}/favicon/favicon.ico`} />,
    <link rel="apple-touch-icon" href={`${process.env.GATSBY_URL}/favicon/lwRectangle57x57.png`} key="apple57" />,
    <link rel="apple-touch-icon" sizes="72x72" href={`${process.env.GATSBY_URL}/favicon/lwRectangle72x72.png`} key="apple72" />,
    <link rel="apple-touch-icon" sizes="76x76" href={`${process.env.GATSBY_URL}/favicon/lwRectangle76x76.png`} key="apple76" />,
    <link rel="apple-touch-icon" sizes="114x114" href={`${process.env.GATSBY_URL}/favicon/lwRectangle114x114.png`} key="apple114" />,
    <link rel="apple-touch-icon" sizes="120x120" href={`${process.env.GATSBY_URL}/favicon/lwRectangle120x120.png`} key="apple120" />,
    <link rel="apple-touch-icon" sizes="128x128" href={`${process.env.GATSBY_URL}/favicon/lwRectangle128x128.png`} key="apple128" />,
    <link rel="apple-touch-icon" sizes="144x144" href={`${process.env.GATSBY_URL}/favicon/lwRectangle144x144.png`} key="apple144" />,
    <link rel="apple-touch-icon" sizes="152x152" href={`${process.env.GATSBY_URL}/favicon/lwRectangle152x152.png`} key="apple152" />,
    <link rel="apple-touch-icon" sizes="167x167" href={`${process.env.GATSBY_URL}/favicon/lwRectangle167x167.png`} key="apple167" />,
    <link rel="apple-touch-icon" sizes="180x180" href={`${process.env.GATSBY_URL}/favicon/lwRectangle180x180.png`} key="apple180" />,
    <link rel="apple-touch-icon" sizes="192x192" href={`${process.env.GATSBY_URL}/favicon/lwRectangle192x192.png`} key="apple192" />,
    <link rel="icon" type="image/png" sizes="32x32" href={`${process.env.GATSBY_URL}/favicon/favicon-32x32.png`} key="apple32" />,
    <link rel="icon" type="image/png" sizes="16x16" href={`${process.env.GATSBY_URL}/favicon/favicon-16x16.png`} key="apple16" />,
    <link rel="manifest" href={`${process.env.GATSBY_URL}/site.webmanifest`} key="manifest" />,
    <link rel="mask-icon" href={`${process.env.GATSBY_URL}/safari-pinned-tab.svgt`} color="#000000" key="mask-ico" />,
  ],
}

export const BLOG_SUFFIX = '• LambdaWorks Blog'
export const PAGE_SUFFIX = '• LambdaWorks'

const ogUrlImage = `${process.env.GATSBY_URL}/og/home.png`

export const SEO_BY_PAGE: Record<Exclude<keyof typeof routes, typeof routes.NOT_FOUND | typeof routes.PROJECTS>, PageSEO> = {
  [routes.APPLIED]: {
    title: `Successfully applied ${PAGE_SUFFIX}`,
    description: "Hey, thanks for applying for a position at LambdaWorks. We'll try to respond as quickly as we can.",
    ogImage: ogUrlImage,
  },
  [routes.APPLY]: {
    title: `Nice to Get to Know You ${PAGE_SUFFIX}`,
    description: "Hey, thanks for applying for a position at LambdaWorks. We'll try to respond as quickly as we can.",
    ogImage: ogUrlImage,
  },
  [routes.BLOG]: {
    title: `Blog ${PAGE_SUFFIX}`,
    description: "Written by the people of LambdaWorks, sharing their thoughts on engineering, design, and management.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'blog',
  },
  [routes.CAREERS]: {
    title: `Careers ${PAGE_SUFFIX}`,
    description: "We're continuously looking for curious people who are passionate about working with modern technologies.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'careers',
  },
  [routes.COMPANY]: {
    title: `Company ${PAGE_SUFFIX}`,
    description: "We help enterprises and startups design and launch successful digital products, but we also do more than that.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'company',
  },
  [routes.CONTACT]: {
    title: `Get in Touch ${PAGE_SUFFIX}`,
    description: "We'd love to make your next big idea a reality or just lend a helping hand. Looking forward to meeting you.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'contact',
  },
  [routes.HOME]: {
    title: "LambdaWorks • A Digital Product Agency",
    description: "Digital product development agency for startups and enterprise innovators. Shaping ideas into digital products since 2017.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'home',
  },
  [routes.INTERNSHIP]: {
    title: `Internship ${PAGE_SUFFIX}`,
    description: "We're really excited to get to know you and we're looking forward to our first call.",
    ogImage: ogUrlImage,
  },
  [routes.KNOWLE]: {
    title: `Knowle ${PAGE_SUFFIX}`,
    description: "AI chatbot development from the ground up is often too expensive, making it accessible only to larger organizations. On the other hand, all SaaS AI services carry a great risk of data exposure and pose security risks.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'knowle',
  },
  [routes.PRIVACY_POLICY]: {
    title: `Working with Enterprises ${PAGE_SUFFIX}`,
    description: "Working together with forward digital thinking industry leaders to bring innovation into your company.",
    ogImage: ogUrlImage,
  },
  [routes.PRODUCT_INNOVATION]: {
    title: `Product Innovation ${PAGE_SUFFIX}`,
    description: "Working together with founders to make ideas into products and support you in your vision.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'product_innovation',
  },
  [routes.SCALA]: {
    title: `Scala development company ${PAGE_SUFFIX}`,
    description: "LambdaWorks extends your Scala team and accelerates your development.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'scala',
  },
  [routes.SCALA_PROJECT_PROPOSAL]: {
    title: `Scala development contact ${PAGE_SUFFIX}`,
    description: "Extends your Scala team and accelerates your development.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'scalap',
  },
  [routes.THANKS]: {
    title: `Success ${PAGE_SUFFIX}`,
    description: "Hey, thanks for contacting us. We'll try to respond as quickly as we can.",
    ogImage: ogUrlImage,
  },
  // @TODO projects
  [routes.SUPER_PHONE]: {
    title: `Technology Partner for SuperPhone ${PAGE_SUFFIX}`,
    description: "Learn how we helped the founder of SuperPhone maintain and grow a world-class marketing and sales platform.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'superphone',
  },
  [routes.KNOWLE_PROJECT]: {
    title: `Product development for Knowle ${PAGE_SUFFIX}`,
    description: "Learn how we helped transform an idea into a prototype and then transformed that prototype into a serious and scalable product.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'knowleproject',
  },
  [routes.LAMBERT]: {
    title: `Product development for Lambert ${PAGE_SUFFIX}`,
    description: "Learn how we helped transform an idea into a prototype and then transformed that prototype into a serious and scalable product.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'lambert',
  },
  [routes.LIFE_LOGS]: {
    title: `Product Development for LifeLogs ${PAGE_SUFFIX}`,
    description: "Learn how we helped the founders of LifeLogs conceptualize their idea and turn it into a digital product that keeps evolving.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'lifelogs',
  },
  [routes.MANILO]: {
    title: `e-commerce development for Manilo ${PAGE_SUFFIX}`,
    description: "Learn how we helped the Manilo owner build an innovative e-commerce store for men's business attire.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'manilo',
  },
  [routes.NAG_NAG]: {
    title: `Product development for NagNag ${PAGE_SUFFIX}`,
    description: "Learn how we helped transform an idea into a prototype and then transformed that prototype into a serious and scalable product.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'nagnag',
  },
  [routes.NFME]: {
    title: `Product development for NF.Me ${PAGE_SUFFIX}`,
    description: "Learn how we helped transform an idea into a prototype and then transformed that prototype into a serious and scalable product.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'nfme',
  },
  [routes.NOVAK_DJOKOVIC_FOUNDATION]: {
    title: `Technology Consulting for Novak Djokovic Foundation ${PAGE_SUFFIX}`,
    description: "Learn how we helped the Novak Djokovic Foundation management team; get a fresh view on their work with an in-depth data intelligence platform.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'ndfoundation',
  },
  [routes.SHELFIE]: {
    title: `Product development for Shelfie ${PAGE_SUFFIX}`,
    description: "Learn how we helped transform an idea into a prototype and then transformed that prototype into a serious and scalable product.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'shelfie',
  },
  [routes.TECH_EXCELLENCE]: {
    title: `Tech Excellence ${PAGE_SUFFIX}`,
    description: "Working together with forward digital thinking industry leaders to bring innovation into your company.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'tech_excellence',
  },
  [routes.WORK]: {
    title: `Our Work ${PAGE_SUFFIX}`,
    description: "Learn how we helped founders and companies transform their ideas into prototypes and then transformed those prototypes into serious and scalable products.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'work',
  },
  [routes.THEMES_KINGDOM]: {
    title: `Technology Partner for ThemesKingdom ${PAGE_SUFFIX}`,
    description: "Learn how we helped ThemesKingdom founders further develop their business with a site-as-a-service product.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'themes_kingdom',
  },
  [routes.TRADER_FYLES]: {
    title: `Product development for TraderFyles ${PAGE_SUFFIX}`,
    description: "Learn how we helped transform an idea into a prototype and then transformed that prototype into a serious and scalable product.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'trader_fyles',
  },
  [routes.ZIOES]: {
    title: `Product development for ZIOes ${PAGE_SUFFIX}`,
    description: "Learn how we helped transform an idea into a prototype and then transformed that prototype into a serious and scalable product.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'zioes',
  },
  [routes.BEXIO]: {
    title: `Technology Partner bexio ${PAGE_SUFFIX}`,
    description: "Learn how we helped the founder of bexio maintain and grow a world-class marketing and sales platform.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'bexio',
  },
  [routes.BIZZIT]: {
    title: `Product Development Bizzit ${PAGE_SUFFIX}`,
    description: "Learn how we helped the founder of Bizzit transform his idea of a B2B sales platform into an inventive product.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'bizzit',
  },
  [routes.EL3MENT]: {
    title: `Technology Partner for el3ment ${PAGE_SUFFIX}`,
    description: "Learn how we helped el3ments founders build their initial idea into an app used by musicians around the globe.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'element',
  },
  [routes.EVERYBITE]: {
    title: `Technology Partner everybite ${PAGE_SUFFIX}`,
    description: "Learn how we helped el3ments founders build their initial idea into an app used by musicians around the globe.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'everybite',
  },
  [routes.EXPORT_ONLINE]: {
    title: `Product development ExportOnline ${PAGE_SUFFIX}`,
    description: "Learn how we helped transform an idea into a prototype and then transformed that prototype into a serious and scalable product.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'export_online',
  },
  [routes.HERALD]: {
    title: `Product development Herald ${PAGE_SUFFIX}`,
    description: "Learn how we helped transform an idea into a prototype and then transformed that prototype into a serious and scalable product.",
    ogImage: ogUrlImage,
    ogImageDynamic: 'herald',
  },
  // @NOTE BLOG
  [BLOG_ROUTES.DATA_DRIVEN]: {
    title: `Data-driven approach for growing SMBs ${BLOG_SUFFIX}`,
    description: "Building a small business can lead to sidetracking by tasks that seem important but lack clear impact.",
    ogImage: 'https://api.lambdaworks.io/uploads/Open_graph_Lambda_Blog_2db9cfd56a.jpg',
  },
  [BLOG_ROUTES.DLC]: {
    title: `Demystifying SMS Marketing - 10DLC ${BLOG_SUFFIX}`,
    description: "Unlock SMS for customer engagement! With a 98% open rate, it outshines push notifications and emails.",
    ogImage: 'https://api.lambdaworks.io/uploads/blog_stefan_og_82dbe90edc.jpg',
  },
  [BLOG_ROUTES.DAILY_RITUALS]: {
    title: `Daily Rituals for Better Development ${BLOG_SUFFIX}`,
    description: "In today’s fast-paced development environment, where every task feels urgent, from shifting specifications to accelerated and continuous code integration, maintaining and building good habits is essential for achieving a high-quality product.",
    ogImage: 'https://api.lambdaworks.io/uploads/Open_graph_Lambda_Blog_tepic_2024_28bfa5556f.jpg',
  },
  [BLOG_ROUTES.DUE_DILIGENCE]: {
    title: `Understanding Technical Due Diligence ${BLOG_SUFFIX}`,
    description: "Technical due diligence is a critical evaluation process that aims to assess all of the technological aspects of a digital product. It's a comprehensive examination of the overall technical strategy behind the product.",
    ogImage: 'https://api.lambdaworks.io/uploads/og_blog_bojan_blagojevic_1_3d10ecfdc6.jpg',
  },
  [BLOG_ROUTES.SOURCE_MAP]: {
    title: `The Importance of Source Maps on Sentry ${BLOG_SUFFIX}`,
    description: "Using Sentry with TypeScript source maps enhances debugging, accelerates bug fixes, and delivers a more reliable app.",
    ogImage: 'https://api.lambdaworks.io/uploads/og_blog_petakovic_1_881537180a.jpg',
  },
  [BLOG_ROUTES.DESIGN_SPRINTS]: {
    title: `Micro Design Sprints ${BLOG_SUFFIX}`,
    description: "Developing micro design sprints for efficiently scoping projects as a service company",
    ogImage: 'https://api.lambdaworks.io/uploads/og_blog_komnenovic_1_5532411589.jpg',
  },
  [BLOG_ROUTES.DOMAIN_DRIVEN]: {
    title: `Domain-Driven vs Storage-Driven Object Modeling ${BLOG_SUFFIX}`,
    description: "In modern programming, representing business rules is key. OOP is the most common approach, but methods vary.",
    ogImage: 'https://api.lambdaworks.io/uploads/og_blog_arnlod_1_6eac676fc4.jpg',
  },
  [BLOG_ROUTES.AI_HUMAN_EXPERTISE]: {
    title: `Challenges of Combining AI and Human Expertise ${BLOG_SUFFIX}`,
    description: "Balancing tech innovation with human needs is crucial for GovTech solutions like Copilot, as AI automates processes while preserving the human element.",
    ogImage: 'https://api.lambdaworks.io/uploads/og_blog_petakovic_2_1_07aa69ed39.jpg',
  },
  [BLOG_ROUTES.ELEVATING_DESIGN_EFFICIENCY]: {
    title: `Elevating Design Efficiency on Inherited Projects ${BLOG_SUFFIX}`,
    description: "Inheriting a finished project isn't always easy. Branding, colors, spacing, and typescale are set, but adding a new section can still be challenging.",
    ogImage: 'https://api.lambdaworks.io/uploads/og_blog_anja_1_1_5082febd2c.jpg',
  },
  [BLOG_ROUTES.UPDATE_HEAVY_TABLES]: {
    title: "Update-Heavy Tables (DB VACUUM)",
    description: "Update-heavy tables store data frequently updated for real-time needs, like bank balances, train passengers, ticket availability, or active taxi drivers.",
    ogImage: "https://api.lambdaworks.io/uploads/og_blog_mile_1_d84cee99a6.jpg",
  }
};
