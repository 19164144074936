import React from "react";

import CategorisedList from "components/CategorisedLists/categorizedLists";
import { ourServicesButtonList } from "utility/lists";
import { StyledMainWebContainer } from "./ourServicesStyle";

const OurServices = (): JSX.Element | null => (
  <StyledMainWebContainer>
    <h2>Our services.</h2>
    <CategorisedList list={ourServicesButtonList} />
  </StyledMainWebContainer>
);

export default React.memo(OurServices);
