import React, { useEffect } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import routes from "utility/routes";
import { PROJECT_IMAGES_MAP } from 'utility/projects'
import Footer from "src/components/Footer/footer";
import SectionAnimation from "src/components/SectionAnimation/sectionAnimation";
import {
  StyledLabel,
  StyledLabelTitle,
  StyledSubtitle,
  StyledTitle,
  StyledYear,
  StyledTemplateWrapper,
  StyledTemplateRow,
  StyledFirstImageWrapper,
  StyledSecondImageWrapper,
  StyledThirdImageWrapper,
  StyledProjectsImage,
  StyledProjectsParagraph,
  ProjectFiltersContainer,
  ProjectFilter,
  StyledTemplateRowImage,
  StyledTemplateRowContent,
  StyledTitleInner,
} from "./projectsStyle"
import ClientList from "src/components/Home/ClientList/clientList";
import { theme } from "utility/theme";
import { ProjectsItem } from "utility/models";
import { findStaticImg, mapStaticImgList } from "utility/functions";
import { projectsAlt, projectsContent } from "utility/lists";
import BringIdeaToLife from "./bringIdeaToLife";
import { PageContainer } from "src/styles/globalStyle";

interface ProjectsProps {
  route: string;
}

const Projects = ({ route }: ProjectsProps): JSX.Element|null => {
  const breakpoints = useBreakpoint();
  const isBigScreen = breakpoints.s;
  const isMediumScreen = breakpoints.midS;

  const [currentClient, setCurrentClient] = React.useState<ProjectsItem>(
    projectsContent.find((item) => item.route === route) || projectsContent[0]
  );
  const [animateSection, setAnimateSection] = React.useState<boolean>(false);
  const { title, mobileTitle, year, subTitle, label, images, paragraphs, filters } =
    currentClient;

  const isMobile = isMediumScreen || !mobileTitle;
  const projectTitle = isMobile ? title : mobileTitle;

  const [paragraphLength, setParagraphLength] = React.useState<number>(0);

  useEffect(() => {
    if (paragraphs) {
      setParagraphLength(
        paragraphs.map((d) => d.length).reduce((a, b) => a + b, 0)
      );
    }
  }, [paragraphs]);

  const wrapLayout =
    !isMediumScreen && images.length === 2 && paragraphLength > 500;

  const imagePaths = mapStaticImgList("projects", PROJECT_IMAGES_MAP);

  const onClientChange = (index: number): void => {
    setCurrentClient(projectsContent[index]);
    window.scrollTo({ top: 0 });
    setAnimateSection(false);
    setTimeout(() => {
      setAnimateSection(true);
    }, 10);
  };

  const renderImages = () => images.map((image, index) => (
    <StyledFirstImageWrapper>
      <StyledProjectsImage
        url={findStaticImg(imagePaths, image)}
        title={`${title} ${projectsAlt[index]}`}
      />
    </StyledFirstImageWrapper>
  ))

  const renderTitle = () => (
    <>
      <StyledTitle>
        <StyledTitleInner>
          <StyledYear>{year}</StyledYear>
          {projectTitle}
        </StyledTitleInner>
      </StyledTitle>
      <StyledSubtitle>{subTitle}</StyledSubtitle>
      {
        isMediumScreen ? (
          <ProjectFiltersContainer>
            {
              filters.map((filter) => (
                <ProjectFilter
                  to={`${routes.WORK}?filter=${encodeURIComponent(filter)}`}
                >
                  {filter}
                </ProjectFilter>
              ))
            }
          </ProjectFiltersContainer>
        ) : (<></>)
      }
      <StyledLabelTitle>Our role</StyledLabelTitle>
      <StyledLabel>{label}</StyledLabel>
    </>
  )

  return (
    <PageContainer>
      <SectionAnimation forceAnimation={animateSection}>
        {isMediumScreen ? <></> : renderTitle()}
        <StyledTemplateWrapper>
          <StyledTemplateRowImage>
            { renderImages() }
          </StyledTemplateRowImage>
          <StyledTemplateRow>
            <StyledTemplateRowContent>
              {isMediumScreen ? renderTitle() : <></>}
              <StyledProjectsParagraph>
                {paragraphs.map((p, i) => (
                  <div key={i.toString() + p}>
                    {p}
                    <br />
                    <br />
                  </div>
                ))}
              </StyledProjectsParagraph>
            </StyledTemplateRowContent>
          </StyledTemplateRow>
        </StyledTemplateWrapper>
      </SectionAnimation>

      <SectionAnimation>
        <BringIdeaToLife />
      </SectionAnimation>

      <SectionAnimation>
        <div style={{ flex: 1 }}>
          <ClientList
            title=""
            revealAnimationColor={theme.colors.white}
            showSVG={false}
            onClientChangeCallback={onClientChange}
            viewOurWork
          />
        </div>
      </SectionAnimation>

      <Footer />
    </PageContainer>
  );
};

export default React.memo(Projects);
