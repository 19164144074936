import React from "react";

import {
  StyledInput,
  StyledLabel,
  StyledMainContainer,
} from "./multiLineTextInputStyle";

type MultilineTextInputProps = {
  label: string;
  ariaLabel: string;
  placeholder: string;
  required?: boolean;
  value: string;
  onChange: (e: string | React.ChangeEvent<any>) => void;
};

const MultilineTextInput = ({
  label,
  ariaLabel,
  placeholder,
  required = true,
  value,
  onChange,
}: MultilineTextInputProps): JSX.Element => {
  return (
    <StyledMainContainer>
      <StyledLabel>{label}</StyledLabel>
      <StyledInput
        placeholder={placeholder}
        aria-label={ariaLabel}
        required={required}
        rows={7}
        value={value}
        onChange={onChange}
      />
    </StyledMainContainer>
  );
};

export default React.memo(MultilineTextInput);
