import React from "react";

import { knowleList } from "utility/lists";
import DottedList from "src/components/DottedList/dottedList";

import {
  AboutKnowleContainer,
} from "./aboutKnowleStyle";

const AboutKnowle = (): JSX.Element => {
  return (
    <AboutKnowleContainer>
      <DottedList
        title="What is Knowle."
        list={knowleList}
      />
    </AboutKnowleContainer>
  );
};

export default React.memo(AboutKnowle);
