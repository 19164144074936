import styled from "styled-components";
import Philosophy from "src/images/philosophy.svg";
import JapaneseSymbol from "src/images/japaneseSymbol.svg";

import { sizes } from "utility/device";

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  @media all and (max-width: ${sizes.mediumS}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const StyledDottedListContainer = styled.div`
`;

export const StyledPhilosophyImage = styled(Philosophy)`
  width: min(18.229vw, 350px);
  height: min(16.25vw, 312px);

  @media all and (max-width: ${sizes.mediumS}) {
    width: 134px;
    height: 119px;
    display: flex;
    align-self: end;
  }
`;

export const StyledJapaneseSymbolWrapper = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  @media all and (min-width: ${sizes.xl}) {
    width: 1800px;
  }

  @media all and (max-width: ${sizes.mediumS}) {
    margin-left: 30px;
  }
`;

export const StyledJapaneseSymbol = styled(JapaneseSymbol)`
  width: 20vw;
  height: 15vw;
  margin-bottom: 2vw;

  @media all and (min-width: ${sizes.xl}) {
    width: 356.75px;
    height: 247.05px;
    margin-bottom: 100px;
  }

  @media all and (max-width: ${sizes.mediumS}) {
    width: 264.2px;
    height: 156px;
  }
`;
