import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import {
  StyledMainContainer,
  StyledTextContainer,
} from "./initiativeStyle";

type InitiativeProps = {
  title: string;
  isSelected: boolean;
  description?: string | JSX.Element;
};

const Initiative = ({ title, description, isSelected }: InitiativeProps): JSX.Element => (
  <StyledMainContainer
    alignItems={!!description ? "flex-start" : "center"}
  >
    {title ? (
      <li>
        <StyledTextContainer isSelected={isSelected}>
          {title}
          {description}
        </StyledTextContainer>
      </li>
    ) : null}
  </StyledMainContainer>
);

export default React.memo(Initiative);
