import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import ReachPotentialVectorMobile from "src/images/ctaSeesaw.svg";

import {
  StyledReachPotentialInnerContainer,
  StyledReachPotentialMainContainer,
  StyledReachPotentialMobileContainer,
  StyledSVGlMobileContainer,
} from "./reachPotentialStyle";
import Button from "components/Button/button";
import { navigate } from "gatsby";
import routes from "utility/routes";
import AnimatedSeesawSVG from "src/svg/animatedSeesaw";


const ReachPotential = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMediumScreen = breakpoints.m;

  const productInnovationTextMobile = "Product\nInnovation";
  const productInnovationTextDesktop = "Product Innovation";
  const techExcellenceMobile = "Tech\nExcellence";
  const techExcellenceDesktop = "Tech Excellence";

  const isMobile = breakpoints.s;
  const isTablet = breakpoints.m;
  const vectorStyle = { display: "flex", alignItems: "flex-end", gap: 32 };

  return (
    <>
        <StyledReachPotentialMainContainer style={isMediumScreen ? {} : { display: "none" }}>
          <StyledReachPotentialInnerContainer>
              <h1>
                Helping startups and<br />enterprises.
              </h1>
              <div style={{ height: 60 }} />
              <Button
                onClick={() => navigate(routes.PRODUCT_INNOVATION)}
                text={productInnovationTextDesktop}
              />
              <Button
                onClick={() => navigate(routes.TECH_EXCELLENCE)}
                text={techExcellenceDesktop}
              />
          </StyledReachPotentialInnerContainer>
          <AnimatedSeesawSVG width={318.5} height={258.7} />
        </StyledReachPotentialMainContainer>
      
        <StyledReachPotentialMobileContainer style={isMediumScreen ? { display: "none" } : {}}>
          <div style={ isTablet ? vectorStyle : { ...vectorStyle, marginTop: 20 }}>
            {isTablet ? (
              <h2>Helping startups and enterprises.</h2>
            ) : (
              <h2>Helping<br />startups and<br />enterprises.</h2>
            )}
            
            <StyledSVGlMobileContainer>
              <ReachPotentialVectorMobile
                width={68.5}
                height={58.8}
                style={{ display: "flex" }}
              />
            </StyledSVGlMobileContainer>
            
          </div>
        <div style={{ marginTop: 22 }}>
          <Button
            onClick={() => navigate(routes.PRODUCT_INNOVATION)}
            text={
              isMobile
                ? productInnovationTextDesktop
                : productInnovationTextMobile
            }
              marginBottom="18px"
              isfullwidth
          />
          <Button
            text={isMobile ? techExcellenceDesktop : techExcellenceMobile}
            onClick={() => navigate(routes.TECH_EXCELLENCE)}
            isfullwidth
          />
        </div>
      </StyledReachPotentialMobileContainer>
    </>
  );
};

export default ReachPotential;
