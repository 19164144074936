import React from "react";

import Footer from "src/components/Footer/footer";
import SectionAnimation from "src/components/SectionAnimation/sectionAnimation";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { StyledScalaContainer } from "./scalaProposalStyled";
import ScalaHero from "components/Scala/ScalaHero";
import Form from "./Form";

const ScalaProjectProposal = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMediumScreen = breakpoints.m;

  return (
    <StyledScalaContainer>
      <SectionAnimation>
        <>
          <ScalaHero
            heading="Scala development services"
            title={isMediumScreen ? (
              <div>
                Tell us about your<br />
                project.
              </div>
            ) : (
              <>
                Tell us about your project.
            </>
            )}
            paragraph="We're excited that you're interested in working with us."
            image={{
              src: "hero/scalaHero.png",
              mobileSrc: "hero/scalaHeroMobile.png",
            }}
            hideButton
          />
        </>
      </SectionAnimation>

      <SectionAnimation>
        <Form
          optionList={[
            { value: "Less then 50.000$", label: "Less then 50.000$", disabled: false },
            { value: "50.000-150.000$", label: "50.000-150.000$", disabled: false },
            { value: "150.000-350.000$", label: "150.000-350.000$", disabled: false },
            { value: "350.000$ or more", label: "350.000$ or more", disabled: false },
            { value: "To be determined", label: "To be determined", disabled: false },
          ]}
        />
      </SectionAnimation>
      
      
      {isMediumScreen && <SectionAnimation><div /></SectionAnimation>}

      <SectionAnimation>
        <Footer />
      </SectionAnimation>
    </StyledScalaContainer>
  );
};

export default React.memo(ScalaProjectProposal);
