import React from "react";

import Footer from "src/components/Footer/footer";
import { StyledSeparator } from "src/components/Layout/layoutStyle";
import Hero from "components/Hero/hero";
import HeroParagraph from "components/HeroParagraph/heroParagraph";
import { ColumnContainer, PageContainer } from "src/styles/globalStyle";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const Applied = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMobile = !breakpoints.s;

  return (
    <PageContainer>
      <ColumnContainer gap={0}>
        <Hero
          title={isMobile ? "Thank you\nfor your\nmessage 👋" : "Thank you for your message 👋"}
          image={{
            src: "hero/appliedHero.png",
            mobileSrc: "hero/appliedHeroMobile.png",
          }}
        />
        <HeroParagraph
          text="We’ve got your application. We try to be as fast as we can, but sometimes it takes us a bit more to get back. "
          heading="Thanks for the patience. Have a great day!"
          reverse
        />
      </ColumnContainer>

      <StyledSeparator />

      <Footer />
    </PageContainer>
  );
};

export default Applied;
