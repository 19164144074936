import styled from "styled-components";
import { sizes } from "utility/device";

export const StyledMainWrapper = styled.div`
  margin-top: 10vw;

  @media (min-width: ${sizes.xl}) {
    margin-top: 192px;
  }
`;

export const StyledContentWrapper = styled.div`
  width: 50vw;
  padding-bottom: 20vw;

  @media (max-width: ${sizes.m}) {
    width: 80vw;
  }

  @media (min-width: ${sizes.xl}) {
    width: 944px;
    padding-bottom: 384px;
  }
`;

export const StyledTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  line-height: 112.17%;
  font-size: 5vw;
  letter-spacing: -0.01em;
  color: #000000;
  margin-bottom: 10vw;
  margin-top: 10vw;

  @media(min-width: ${sizes.xl}) {
    font-size: 96px;
    margin-bottom: 192px;
    margin-top: 192px;
  }

  @media (max-width: ${sizes.m}) {
    font-size: 46px;
  }
`;

export const StyledParagraph = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 142%;
  color: #000000;
  
  a {
    text-decoration: underline;
    text-underline-offset: 2.5px;
  }

  @media all and (max-width: ${sizes.l}) {
    font-size: 2vw;
  }

  @media (max-width: ${sizes.m}) {
    font-size: 18px;
  }
`;

export const StyledLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 142%;
  color: #000000;
  opacity: 0.3;
  padding-bottom: 3vw;
  

  @media all and (max-width: ${sizes.l}) {
    font-size: 2vw;
  }

  @media (max-width: ${sizes.m}) {
    font-size: 18px;
  }

  @media (min-width: ${sizes.xl}) {
    padding-bottom: 57.5px;
  }
`;

export const StyledSubtitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 142%;
  color: #000000;
  padding-bottom: 5vw;

  @media all and (max-width: ${sizes.m}) {
    font-size: 24px;
  }

  @media all and (max-width: ${sizes.s}) {
    font-size: 18px;
  }

  @media(min-width: ${sizes.xl}) {
    padding-bottom: 96px;
  }
`;

export const ParagraphSeparator = styled.div`
  width: 100%;
  height: 4vw;

  @media(min-width: ${sizes.xl}) {
    height: 77px;
  }
`;
