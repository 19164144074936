import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import Layout from "src/components/Layout/layout";
import Apply from "src/components/Apply/apply";
import CookieConsent from "src/components/CookieConsent/cookieConsent";
import { theme } from "utility/theme";
import routes from "utility/routes";
import { LocationPropI } from "utility/models";
import CareersApi from "services/Careers.api";
import NotFoundPage from "./404";

const careersApi = new CareersApi();

interface PageProps {
  location: LocationPropI;
  serverData: { isJobAvailable: boolean };
}

const ApplyPage = ({ location, serverData }: PageProps): JSX.Element => {
  const [menuColor, setMenuColor] = useState(theme.colors.white);
  const [isCookieVisible, setCookieVisible] = useState<boolean>(false);

  const query = new URLSearchParams(location.search);
  const position = query.get("position")

  const handleColor = (isMenuOpened: boolean) => {
    isMenuOpened
      ? setMenuColor(theme.colors.black)
      : setMenuColor(theme.colors.white);
  };

  const breakpoints = useBreakpoint();
  const isDesktop = breakpoints.l;
  const locationPath = location.pathname;

  useEffect(() => {
    if (!serverData?.isJobAvailable) {
      navigate(routes.NOT_FOUND, { replace: true });
    }
  }, [serverData?.isJobAvailable]);

  useEffect(() => {
    if (isDesktop) {
      setMenuColor(theme.colors.white);
    }
  }, [isDesktop]);

  if (!serverData?.isJobAvailable) {
    return <NotFoundPage location={location} />;
  }

  const handleVisibility = (a: boolean) => {
    setCookieVisible(a);
  };

  return (
    <Layout
      backgroundColor={menuColor}
      headerColor={theme.colors.orangeYellow}
      setBackgroundColor={handleColor}
      location={locationPath}
      headerRoute={routes.CAREERS}
      isCookieVisible={isCookieVisible}
      isGetInTouchHidden
    >
      <Apply position={(position ?? "").split("-").join(" ")} />
      <CookieConsent getCookieVisibility={handleVisibility} />
    </Layout>
  );
};

export default ApplyPage;

export const getServerData = async (): Promise<{ props: { isJobAvailable: boolean } }> => {
  try {
    const response = await careersApi.getJobs();
    if (!response.data.job.data.attributes.positionList.filter((item: any) => item.active).length) {
      return { props: { isJobAvailable: false } };
    }
    return { props: { isJobAvailable: true } };
  } catch (error) {
    return { props: { isJobAvailable: false } };
  }
}
