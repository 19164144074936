import styled from "styled-components";
import { theme } from "utility/theme";
import { sizes } from "utility/device";
import { Link } from "gatsby";
import { genTextLimit, genTextStyle, underlineAnimation } from "src/styles/globalStyle";

export const BlogPreview = styled.div`
  padding-bottom: 60px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 32px;

  @media (max-width: ${sizes.mediumS}) {
    padding-bottom: 32px;
  }
`;

export const BlogParagraph = styled.p`
  font-family: "Poppins", sans-serif;
  ${genTextStyle({
    maxFontSize: 26,
    minFontSize: 18,
    maxLineHeight: 33.8,
    minLineHeight: 23.4,
  })}

  ${genTextLimit({
    maxLinesNum: 2,
    breakPoints: { [sizes.mediumS]: 3 },
  })}
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media (max-width: ${sizes.mediumS}) {
    gap: 4px;
  }
`;

export const DateAndButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${sizes.minS}) {
    flex-direction: column;
    gap: 16px;

    div:nth-child(2) {
      width: 100%;
    }
  }
`;

export const Separator = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${theme.colors.black};
`;

export const BlogName = styled(Link)`
  text-decoration: none;
  align-self: start;
  font-family: Moderat-Regular, Arial, Helvetica, sans-serif;

  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 12px;
  } 

  ${genTextStyle({
    maxFontSize: 48,
    minFontSize: 32,
    maxLineHeight: 52.8,
    minLineHeight: 38.4,
    isCustomised: true,
  })}
`;
