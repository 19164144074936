import React, { useEffect, useRef, useState } from "react";
import { isScrolledIntoView } from "utility/functions";

interface Props {
  width?: number | string;
  height?: number | string;
  strokeWidth?: number;
}

const AnimatedSeesawSVG = ({
  width = 547,
  height = 463,
  strokeWidth = 2,
}: Props) => {
  const [isAnimated, setAnimated] = useState<boolean>(false);
  const rootRef = useRef<SVGSVGElement>(null);
  const curvyLineRef = useRef<SVGPathElement>(null);
  const arrowRef = useRef<SVGPathElement>(null);
  const firstLineRef = useRef<SVGLineElement>(null);
  const secondLineRef = useRef<SVGLineElement>(null);
  const thirdLineRef = useRef<SVGLineElement>(null);
  const fourthLineRef = useRef<SVGLineElement>(null);

  useEffect(() => {
    const animateCTA = () => {
      const isBrowser = typeof window !== "undefined";
      if (isBrowser) {
        window.addEventListener("scroll", () => {
          if (isScrolledIntoView(rootRef?.current) && !isAnimated) {
            rootRef?.current?.setAttribute("class", "root");
            curvyLineRef?.current?.setAttribute("class", "curvy-line");
            arrowRef?.current?.setAttribute("class", "arrow");
            firstLineRef?.current?.setAttribute("class", "line first");
            secondLineRef?.current?.setAttribute("class", "line second");
            thirdLineRef?.current?.setAttribute("class", "line third");
            fourthLineRef?.current?.setAttribute("class", "line fourth");
            setAnimated(true);
          }
        });
      }
    };

    animateCTA();
  }, []);

  return (
    <svg
      width={width}
      height={height}
      ref={rootRef}
      viewBox="0 0 547 463"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>
        {`
          .root {
            transform: rotate(21deg);
            animation-name: rotate;
            animation-duration: 300ms;
            animation-timing-function: linear; 
            animation-fill-mode: forwards;
            animation-delay: 1000ms;
          }
      
          .curvy-line {
            stroke-dasharray: 1700;
            stroke-dashoffset: 1700;
            animation-delay: 1300ms;
            animation-name: dash;
            animation-duration: 2s;
            animation-timing-function: linear; 
            animation-fill-mode: forwards;
          }
      
          .arrow {
            stroke-dasharray: 100;
            stroke-dashoffset: 100;
            animation-delay: 1800ms;
            animation-name: dash;
            animation-duration: 300ms;
            animation-timing-function: linear; 
            animation-fill-mode: forwards;
          }
      
          .line {
            stroke-dasharray: 200;
            stroke-dashoffset: 200;
            animation-name: dash;
            animation-duration: 300ms;
            animation-timing-function: linear; 
            animation-fill-mode: forwards;
          }
      
          .first {
            animation-delay: 1800ms;
          }
      
          .second {
            animation-delay: 2000ms;
          }
      
          .third {
            animation-delay: 2200ms;
          }
      
          .fourth {
            animation-delay: 2400ms;
          }
      
          @keyframes rotate {
            to {
              transform: rotate(0deg);
            }
          }
      
          @keyframes dash {
            to {
              stroke-dashoffset: 0;
            }
          }
        `}
      </style>
      <circle
        cx="296.27"
        cy="391.876"
        r="70.1237"
        stroke="black"
        strokeWidth={strokeWidth}
      />
      <line
        x1="0.626506"
        y1="434.772"
        x2="546.627"
        y2="214.935"
        stroke="black"
        strokeWidth={strokeWidth}
      />

      <path
        strokeDasharray="1700"
        d="M3.87305 413.428C3.87305 220.891 110.918 82.954 299.863 63.5566"
        stroke="black"
        strokeWidth={strokeWidth}
        ref={curvyLineRef}
      />
      <path
        strokeDasharray="100"
        d="M270.408 94.4483L299.144 63.5562L264.66 41.2852"
        stroke="black"
        strokeWidth={strokeWidth}
        ref={arrowRef}
      />
      <line
        strokeDasharray="200"
        x1="414.373"
        y1="0.335937"
        x2="414.373"
        y2="126.778"
        stroke="black"
        strokeWidth={strokeWidth}
        ref={firstLineRef}
      />
      <line
        strokeDasharray="200"
        x1="363.084"
        y1="62.5566"
        x2="466.537"
        y2="62.5566"
        stroke="black"
        strokeWidth={strokeWidth}
        ref={secondLineRef}
      />
      <line
        strokeDasharray="200"
        x1="377.529"
        y1="99.4277"
        x2="450.681"
        y2="26.2756"
        stroke="black"
        strokeWidth={strokeWidth}
        ref={thirdLineRef}
      />
      <line
        strokeDasharray="200"
        x1="378.943"
        y1="26.2753"
        x2="452.095"
        y2="99.4274"
        stroke="black"
        strokeWidth={strokeWidth}
        ref={fourthLineRef}
      />
    </svg>
  );
};

export default AnimatedSeesawSVG;

