import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import {
  StyledDottedListContainer,
  StyledDottedListParagraphContainer,
  StyledDottedPositionsListTitle,
  StyledDottedTitleContainer,
  StyledListContainer,
} from "./dottedPositionsStyle";
import { StyledParagraph, StyledSectionParagraph } from "../PositionItem/positionItemStyle";

type DottedListProps = {
  title: string;
  list?: Array<string>;
  content?: string;
  marginBottomTitle?: number;
  paragraphFont?: number;
  paragraphMobileLeftPadding?: number;
};

const DottedPositionsList = ({
  list,
  title,
  content,
  marginBottomTitle = 129,
  paragraphFont = 48,
  paragraphMobileLeftPadding = 21,
}: DottedListProps): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMediumScreen = breakpoints.m;

  return (
    <StyledDottedListContainer>
      <StyledDottedTitleContainer marginBottomTitle={marginBottomTitle}>
        <StyledDottedPositionsListTitle>{title}</StyledDottedPositionsListTitle>
      </StyledDottedTitleContainer>
      <StyledDottedListParagraphContainer>
        {list ? list.map((item: string) => (
          <div key={item}>
            <StyledListContainer
              paragraphFont={paragraphFont}
              paragraphMobileLeftPadding={paragraphMobileLeftPadding}
            >
              <div
                style={{
                  width: isMediumScreen ? "16px" : "10px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <svg
                  width={isMediumScreen ? "16" : "10"}
                  height={isMediumScreen ? "16" : "10"}
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12.5" cy="12.5" r="12.5" fill="black" />
                </svg>
              </div>
              <p>{item}</p>
            </StyledListContainer>
            <br />
          </div>
        )) : (
          <StyledSectionParagraph>{content}</StyledSectionParagraph>
        )}
      </StyledDottedListParagraphContainer>
    </StyledDottedListContainer>
  );
};

export default React.memo(DottedPositionsList);
