import styled from "styled-components";
import { sizes } from "utility/device";
import ProductInnovationSvg from "src/images/moreThanJustCode.svg";
import EnterprisesSvg from "src/images/partneringForInnovation.svg";
import CareersSvg from "src/images/careersTalkSection.svg";
import KnowleLogoSvg from "src/images/knowleLogo.svg";
import KnowleTitleSvg from "src/images/knowleTitle.svg";
import { ContentFrame } from "src/styles/globalStyle";

export const StyledLetsTalkContainer = styled(ContentFrame)<{ isCentered?: boolean }>`
  padding: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: ${({ isCentered = false }) => isCentered ? "center" : "flex-start"};
  width: 100%;
  position: relative;

  @media all and (max-width: ${sizes.m}) {
    padding: 20px;
    flex-direction: column-reverse;
    gap: 40px;
  }

  p {
    margin: 0;
  }
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;

  @media (max-width: ${sizes.minM}) {
    box-sizing: border-box;
    padding-right: 65px;
  }
`;

export const StyledContentSVGContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
`;

export const StyledMobileSVGContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 16px;
  margin-right: 16px;

  @media (max-width: ${sizes.minM}) {
    max-width: 57px;
      
    svg {
      width: 100%;
    }
  }
`;

export const StyledTitleMobileContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  margin-top: 24px;
    
  h2 {
    @media (max-width: ${sizes.smallS}) {
      font-size: max(3.3333333333333335vw, 40px);
    }
  }
`;

export const StyledSVGContainer = styled.div`
  display: flex;

  @media all and (max-width: ${sizes.l}) {
    margin-bottom: 40px;
  }

  @media all and (min-width: ${sizes.m}) {
    justify-content: flex-end;
    align-items: flex-end;
  }

  @media all and (max-width: ${sizes.s}) {
    justify-content: center;
    align-items: center;
  }
`;

export const StyledProductInnovationSVG = styled(ProductInnovationSvg)`
  width: max(20.26vw, 256px);
  height: max(14vw, 150px);

  @media all and (min-width: ${sizes.xl}) {
    width: 389px;
    height: 269px;
  }
`;

export const StyledEnterprisesSVG = styled(EnterprisesSvg)`
  width: 25vw;
  height: 25vw;

  @media all and (min-width: ${sizes.xl}) {
    width: 480px;
    height: 480px;
  }

  @media all and (max-width: ${sizes.l}) {
    width: 270px;
    height: 270px;
  }
`;

export const StyledCareersSVG = styled(CareersSvg)`
  width: 25vw;
  height: 25vw;

  @media all and (min-width: ${sizes.xl}) {
    width: 480px;
    height: 480px;
  }

  @media all and (max-width: ${sizes.l}) {
    width: 270px;
    height: 270px;
  }
`;

export const StyledKnowleSVGContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  margin: 0;
  max-height: 300px;
`;

export const StyledKnowleLogoSVG = styled(KnowleLogoSvg)`
  max-width: 184px;
  max-height: 203px;

  @media all and (max-width: ${sizes.s}) {
    width: 120px;
    height: 160px;
  }
`;

export const StyledKnowleTitleSVG = styled(KnowleTitleSvg)`
  width: 361px;
  height: 87px;

  @media all and (max-width: ${sizes.s}) {
    width: 100%
  }
`;

export const StyledLetsTalkParagraph = styled.div<{
  limitParagraph?: boolean;
  fontSize?: number | string;
  maxParagraphWidth?: string;
}>`
  font-size: ${({ fontSize = "1.9vw" }) => fontSize};
  line-height: 120%;

  margin-top: 36px;

  ${({ limitParagraph }) => limitParagraph ? "width: 41.719vw;" : `width: 100%;`}
  ${({  maxParagraphWidth = "1239px" }) => `width: 100%; max-width: ${maxParagraphWidth};`}
  
  @media all and (min-width: ${sizes.xl}) {
    font-size: ${({ fontSize = "36px" }) => fontSize};
    line-height: 43.2px;
    width: 790px;
    ${({ limitParagraph }) => limitParagraph ? "width: 41.719vw" : "width: 100%"};
    margin-top: 36px;
  }

  @media all and (max-width: ${sizes.m}) {
    font-size: 18px;
    line-height: 21.6px;
    ${({ limitParagraph }) => limitParagraph ? "width: 41.719vw" : "width: 100%"};
  }

  @media all and (max-width: ${sizes.s}) {
    font-size: 18px;
    width: 100%;
  }
`;

export const StyledLetsTalkLine = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 60px;

  & > * {
    margin-right: 30px;
  }

  @media all and (min-width: ${sizes.m}) {
    align-items: center;
  }

  @media all and (max-width: ${sizes.m}) {
    flex-direction: column;
    gap: 32px;
    width: 100%;

    & > * {
      display: flex;
      margin-right: 0;
    }
  }

  @media all and (max-width: ${sizes.s}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin: 0;
    margin-top: 20px;
    gap: 32px;
  }
`;

export const StyledLetsTalkQuestions = styled.div`
  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  
    font-size: 1.25vw;
    line-height: 1.5vw;
  
    @media all and (max-width: ${sizes.m}) {
      font-size: 18px;
      line-height: 22px;
      justify-content: center;
      width: 100%;
    }

    @media all and (min-width: ${sizes.xl}) {
      font-size: 24px;
      line-height: 28.8px;
    }
  }
`;
