import styled from "styled-components";
import { theme } from "utility/theme";
import { sizes } from "utility/device";
import { genTextStyle } from "src/styles/globalStyle";

export const SubscriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  form {
    flex: 1;
    display: flex;
    flex-display: row;
    border-radius: 60px;
    overflow: hidden;
    border: 2px solid ${({ theme }) => theme.colors.black};
  }

  input {
    flex: 1;
    flex-shrink: 0;
    min-width: 0;
    border: none;
    outline: none;
    box-sizing: border-box;
    padding-left: 40px;
    font-weight: 400;
    font-size: 30px;

    @media (max-width: ${sizes.mediumS}) {
      font-size: 18px;
      padding: 12px;
    }
  }

  input::placeholder {
    color: #0000001A;
  }
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
`;

export const ButtonHolder = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-shrink: 0;
  width: 306px;
  margin-top: -2px;
  margin-bottom: -2px;
  margin-right: -2px;
  ${({ isMobile }) => isMobile && `
    flex: 1;
    width: 100%;
  `}
`;
