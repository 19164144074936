import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { ButtonType } from "utility/theme";

import {
  StyledButtonWrapper,
  StyledCTAArrowWrapper,
  StyledSparkInnovationMainContainer,
  StyledSparkInnovationTopContainer,
} from "./sparkYourInnovationStyle";
import SparkYourInnovationSVG from "src/svg/animatedArrowDownSwirl";
import { navigate } from "gatsby";
import routes from "utility/routes";
import Button from "components/Button/button";


const SparkYourInnovation = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMediumScreen = breakpoints.m;

  return (
    <StyledSparkInnovationMainContainer>
      <StyledSparkInnovationTopContainer>
        <h1>Here to build<br />your next digital venture.</h1>
        <StyledCTAArrowWrapper>
          <SparkYourInnovationSVG
            width={(!isMediumScreen ? 134 : 267.44) as number}
            height={!isMediumScreen ? 104 : 208}
            strokeWidth={!isMediumScreen ? 4 : 2}
          />
        </StyledCTAArrowWrapper>
      </StyledSparkInnovationTopContainer>
      <StyledButtonWrapper>
        <Button
          onClick={() => navigate(routes.CONTACT)}
          text="Get in touch"
          type={ButtonType.BLACK}
        /> 
      </StyledButtonWrapper>
    </StyledSparkInnovationMainContainer>
  );
};



export default React.memo(SparkYourInnovation);
