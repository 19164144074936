import React, { useState } from "react";

import { OurCapabilitiesItem } from "utility/models";
import { theme } from "utility/theme";
import {
  SectionHeading,
  SectionDescription,
  BulletItem,
  SectionDescriptionWrapper,
  AccordionContainer,
} from "./accordionStyle";


interface AccordionProps {
  list: Array<OurCapabilitiesItem>;
  isAccordion?: boolean;
}

const Accordion = ({
  list,
  isAccordion = true,
}: AccordionProps): JSX.Element => {
  const [activeSection, setActiveSection] = useState<number | null>(null);

  const selectSection = (id: number) => {
    if (activeSection == id) {
      setActiveSection(null);
    } else {
      setActiveSection(id);
    }
  };

  return (
    <AccordionContainer>
      {list.map((item, index) => (
        <div
          key={`${index}`}
          onClick={() => selectSection(item.id)}
        >
          <SectionHeading isOpen={item.id === activeSection}>
            {item.title}
            <svg
              style={{ display: !isAccordion ? "none" : "unset" }}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m18.6 15.485-6.442 6.813-.019-.02L5.4 15.562l.856-.8 5.317 5.305V1.799h1.17v18.265l5.002-5.38.855.8Z"
                fill={theme.colors.black}
              />
            </svg>
          </SectionHeading>
          <SectionDescriptionWrapper
            isOpen={item.id === activeSection}
            isAccordion={isAccordion}
          >
            <SectionDescription>
              {item.paragraph.map((pItem, i) => (
                <BulletItem key={`${i}`}>
                  <div>{pItem}</div>
                </BulletItem>
              ))}
            </SectionDescription>
          </SectionDescriptionWrapper>
        </div>
      ))}
    </AccordionContainer>
  );
};

export default React.memo(Accordion);
