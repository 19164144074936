import styled from "styled-components";

import { sizes } from "utility/device";

export const StyledMainContainer = styled.div`
  max-width: 922px;
`;

export const StyledButtonContainer = styled.div`
  margin-top: 32px;
`;

export const StyledErrorContainer = styled.div`
  color: ${(props) => props.theme.colors.black};
  opacity: 0.5;
  margin-top: 10px;

  font-size: 16px;
`;

export const StyledResumeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledResumeLabelContainer = styled.div`
  display: flex;
  flex-direction: row;

  align-items: flex-end;
  margin-top: 20px;

  @media all and (max-width: ${sizes.s}) {
    flex-direction: column;
    margin-top: 32px;
    height: unset;
    align-items: flex-start;
  }
`;

export const StyledResumeFirstLabel = styled.p`
  font-size: 18px;
`;

export const StyledResumeSecondLabel = styled.p`
  font-size: 12px;
  line-height: 20px;

  margin-bottom: -2px;
`;

export const StyledInternshipTypeLabel = styled.p`
  font-size: 18px;
  margin-top: 65px;
`;

export const StyledRecaptchaContainer = styled.div`
  margin-top: 32px;
  
  @media all and (max-width: ${sizes.s}) {
    margin-top: 32px;
  }
`;
