import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Arrow from "src/images/arrowBlack.svg";
import { reviews } from "utility/lists";

import {
  StyledClutchReviewParagraph,
  StyledNumberRow,
  StyledReviewAuthor,
  StyledReviewCarouselMainContainer,
  StyledReviewContainer,
  StyledReviewMainContainer,
  StyledReviewShadowContainer,
  StyledReviewText,
  StyledRightBlackArrow,
} from "./reviewCarouselStyle";
import DragIndicator from "src/components/DragIndicator/dragIndicator";
import { isTouchDevice } from "utility/functions";

const ReviewCarousel = (): JSX.Element => {
  const [currSlide, setCurrSlide] = useState<number>(1);
  const [hidden, setHidden] = useState<boolean>(true);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [topLimit, setTopLimit] = useState<number>(0);
  const [bottomLimit, setBottomLimit] = useState<number>(0);
  const [cursor, setCursor] = useState<string>("auto");
  const [drag, setDrag] = useState<boolean>(true);
  const sliderRef = React.useRef(null);

  const updateLimits = (layout: HTMLDivElement | null): void => {
    if (layout && layout.offsetTop) {
      setTopLimit(layout.offsetTop);
      if (layout.offsetHeight) {
        setBottomLimit(layout.offsetTop + layout.offsetHeight);
      }
    }
  }

  const onMouseOver = (): void => {
    if (!isTouchDevice && hidden) {
      setHidden(false);
      setCursor("none");
    }
  }

  const onMouseLeave = (): void => {
    if (!isTouchDevice && !hidden) {
      setHidden(true);
      setCursor("auto");
    }
  }

  const onMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (event.pageY < topLimit || event.pageY > bottomLimit) {
      onMouseLeave();
    } else {
      setPosition({ x: event.clientX, y: event.clientY });
    }
  }

  const handleSlideChange = (): void => {
    if(sliderRef.current) {
      // @ts-ignore
      sliderRef.current?.slickNext();
    }
  }

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 4000,
    cssEase: "linear",
    slidesToShow: 2,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: false,
    afterChange: (current: number) => setCurrSlide(current + 1),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 968,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <StyledReviewMainContainer
      ref={(layout) => updateLimits(layout)}
      cursor={cursor}>
      <StyledReviewContainer
        onMouseOver={onMouseOver}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        onMouseUp={() => setDrag(true)}
        onMouseDown={() => setDrag(false)}
      >
        <DragIndicator hidden={hidden} position={position} dragging={drag} />
        <Slider ref={sliderRef} {...settings}>
          {reviews.map((item) => (
            <StyledReviewCarouselMainContainer
              key={item.author}
            >
              <StyledReviewText>{item.text}</StyledReviewText>
              <StyledReviewAuthor>{item.author}</StyledReviewAuthor>
            </StyledReviewCarouselMainContainer>
          ))}
        </Slider>
      </StyledReviewContainer>
      <StyledReviewShadowContainer>
        <StyledNumberRow>
          {currSlide} of {reviews.length}
          <StyledRightBlackArrow onClick={handleSlideChange} />
        </StyledNumberRow>
        <StyledClutchReviewParagraph
          href="https://clutch.co/profile/lambdaworks"
          target="_blank"
        >
          <div>Read all our reviews on Clutch&ensp;</div>
          <Arrow />
        </StyledClutchReviewParagraph>
      </StyledReviewShadowContainer>
    </StyledReviewMainContainer>
  );
};

export default React.memo(ReviewCarousel);
