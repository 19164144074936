import { Link } from "gatsby";

import styled from "styled-components";
import { isMobile, isSafari } from "react-device-detect";
import { sizes } from "utility/device";

export const StyledTitle = styled.div`
  font-size: 5vw;
  line-height: 112.17%;
  color: #000000;
  position: relative;
  display: flex;
  flex-direction: column;

  @media all and (min-width: ${sizes.xl}) {
   font-size: 96px;
  }
    
  @media (max-width: ${sizes.mediumS}) {
    display: block;
    margin-top: 26px;
    font-size: 48px;
  }
  @media (max-width: ${sizes.smallS}) {
      font-size: 38px;
  }
`;

export const StyledTitleInner = styled.div`
  @media (max-width: ${sizes.mediumS}) {
    display: inline-block;
    position: relative;
    padding-right: 30px;
  }
`

export const StyledYear = styled.div`
  font-size: 1.25vw;
  line-height: 1;
  max-height: 2.5vw;
  margin-bottom: 5px;

  @media (max-width: ${sizes.m}) {
    font-size: 16px;
    max-height: 30px;
  }

  @media all and (min-width: ${sizes.xl}) {
    font-size: 24px;
    max-height: 52px;
  }
    
  @media (max-width: ${sizes.mediumS}) {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const StyledSubtitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 2vw;
  line-height: 112.17%;
  letter-spacing: 0.01em;
  color: #000000;
  margin-top: 32px;
  max-width: 565px;
    
  @media (max-width: ${sizes.mediumS}) {
    margin-bottom: 60px;
    font-size: 24px;
  }
`;

export const StyledTemplateWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
    
  @media (max-width: ${sizes.mediumS}) {
    flex-direction: column;
  }
`;

export const StyledTemplateRowImage = styled.div`
  max-width: 536px;
  flex-shrink: 0;
  width: 100%;
  margin-right: 80px;
    
  @media (max-width: ${sizes.l}) {
    width: 40%;
    margin-right: 7%;
  }
    
  @media (max-width: ${sizes.mediumS}) {
    width: 100%;
    margin: 0;
    max-width: 630px;
  }
`

export const StyledTemplateRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const StyledTemplateRowContent = styled.div`
  max-width: 793px;
`

export const StyledLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 128.3%;
  color: #000000;
  opacity: 0.4;
  max-width: 427px;
    
  @media (max-width: ${sizes.mediumS}) {
    margin-bottom: 60px;
    font-size: 14px;
  }
`;

export const StyledLabelTitle = styled.div`
  margin-top: 120px;
  font-size: 30px;
  line-height: 128.3%;
  color: #000000;

  @media (max-width: ${sizes.m}) {
    font-size: 14px;
    margin-top: 32px;
  }

  @media (max-width: ${sizes.m}) {
    display: none;
  }
`;

export const StyledFirstImageWrapper = styled.div`
  margin-bottom: 60px;
    
  @media (max-width: ${sizes.mediumS}) {
    margin-bottom: 32px;
    box-sizing: border-box;
    padding: 0 12px;
  }
`;

export const StyledSecondImageWrapper = styled.div`
  margin-top: 10vw;

  @media (min-width: ${sizes.xl}) {
    margin-top: 192px;
  }
`;

export const StyledThirdImageWrapper = styled.div`
  margin-top: 15vw;

  @media (min-width: ${sizes.xl}) {
    margin-top: 288px;
  }
`;

export const StyledProjectsImage = styled.div<{ url: string }>`
  background-size: cover;
  background-position: center top;
  background-image: ${({ url }) => (`url(/${url})`)};
  border-radius: 4.167vw;

  ${isMobile && "min-width: 200px; min-height: 399px;"}
  aspect-ratio: 70/93;
  position: relative;

  @media (max-width: ${sizes.m}) {
    border-radius: 2.604vw;
  }

  @media (max-width: ${sizes.s}) {
    border-radius: 40px;
  }

  @media (min-width: ${sizes.xl}) {
    border-radius: 80px;
  }
`;

export const StyledProjectsParagraph = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: max(1.875vw, 18px);
  line-height: max(2.063vw, 23.4px);
  color: #000000;
  margin-top: 80px;

  @media (min-width: ${sizes.xl}) {
    font-size: 36px;
    line-height: 39.6px;
  }
    
  @media (max-width: ${sizes.mediumS}) {
    margin-top: 28px;
  }
`;

export const ClientListWrapper = styled.div`
  margin-top: 6vw;

  @media (max-width: ${sizes.m}) {
    display: none;
  }
`;

export const OurWorkLink = styled(Link)`
  margin-top: 55px;
  text-decoration: none;
  font-size: 24px;
  line-height: 112.174%;
  display: flex;
  gap: 8px;
`;

export const LinkTextSpan = styled.span`
  padding-top: 2px;
`;

export const ProjectFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 32px;
  gap: 16px;

  @media (max-width: ${sizes.m}) {
    margin-top: 32px;
  }
`;

export const ProjectFilter = styled(Link)`
  font-size: 18px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.lightGray};
  border-radius: 32px;
  padding: 8px 16px;
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
`;
