import styled from "styled-components";
import { sizes } from "utility/device";

export const AboutKnowleContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  @media all and (max-width: ${sizes.m}) {
    flex-direction: column;
  }
`;
