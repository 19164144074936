import React from 'react';
import Arrow from "src/images/arrowLeft.svg";
import {
  StyledDragIndicatorContainer,
  StyledArrowWrapper,
  StyledArrowRow,
} from './dragIndicatorStyle';
import { isTouchDevice } from 'utility/functions';

interface DragIndicatorProps {
  hidden: boolean;
  position: { x: number, y: number };
  dragging: boolean;
}

const DragIndicator = ({ hidden, position, dragging }: DragIndicatorProps) => {
  return (
      <StyledDragIndicatorContainer
        hidden={isTouchDevice || hidden}
        style={{ left: `${position.x}px`, top: `${position.y}px` }}
      >
        {dragging ? (
          <div className="drag-spinner">
            <p>Click+Drag</p>
          </div>
        ) : (
          <StyledArrowWrapper>
            <StyledArrowRow>
              <Arrow />
            </StyledArrowRow>
            <StyledArrowRow rotate>
              <Arrow />
            </StyledArrowRow>
          </StyledArrowWrapper>
        )}
      </StyledDragIndicatorContainer>
    
  );
};

export default React.memo(DragIndicator);
