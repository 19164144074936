import React, { useState, lazy, Suspense } from "react";
import { navigate } from "gatsby";
import { Formik } from "formik";
import axios from "axios";

import InputField from "src/components/InputField/inputField";
import MultiLineTextInput from "src/components/MultilineTextInput/multiLineTextInput";
import Button from "src/components/Button/button";
import FileUploader from "src/components/FileUploader/fileUploader";
import { ApplySchema } from "utility/validation/applySchema";
import routes from "utility/routes";
import {
  StyledButtonContainer,
  StyledMainContainer,
  StyledErrorContainer,
  StyledResumeContainer,
  StyledResumeLabelContainer,
  StyledResumeFirstLabel,
  StyledResumeSecondLabel,
} from "src/components/Contact/Form/formStyle";
import { accessibility } from "utility/constants";

const Recaptcha = lazy(() => import("react-google-recaptcha"));

interface FormProps {
  name: string;
  email: string;
  phoneNumber: string;
  interests: string;
  projects: string;
  others: string;
  positionType: string;
}

interface ParentFormProps {
  positionType: string;
}

const Form = ({ positionType }: ParentFormProps): JSX.Element => {
  const [files, setFiles] = useState<any[]>([]);
  const [recaptchaResponse, setRecaptchaResponse] = useState();
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const onChangeRecaptcha = (value: any) => {
    setRecaptchaResponse(value);
  };

  const getFormData = (data: FormProps): any => {
    const formData = new FormData();
    formData.append("attachment", files[0]);

    Object.entries(data).forEach((item) => {
      formData.append(item[0], item[1]);
    });

    return formData;
  };

  const submitForm = (data: FormProps) => {
    if (!recaptchaResponse) {
      return;
    }

    const {
      name,
      email,
      phoneNumber,
      interests,
      projects,
      others,
      positionType,
    } = data;

    const formData = getFormData(data);

    if (formData.error) {
      setError(formData.error);
      return;
    }
    setLoading(true);

    axios
      .post(
        `https://ewo0rmtef5.execute-api.us-east-1.amazonaws.com/prod/email?recaptcha=${recaptchaResponse}`,
        formData,
        {
          // @ts-ignore
          email: email,
          subject: `Apply for job - ${name}`,
          message: `From:
      Position: ${positionType}\n
      Name: ${name}\n
      Email: ${email}
      Phone: ${phoneNumber}\n\n
      Personal interests and hobbies: ${interests ? interests : "N/A"}\n\n
      Side projects: ${projects ? projects : "N/A"}\n\n
      Anything else you would like to share with us: ${
        others ? others : "N/A"
      }`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(() => {
        const isBrowser = typeof window !== `undefined`;
        if (!isBrowser) return;
        navigate(routes.THANKS);
      })
      .catch(() => setError("Request failed to process."))
      .finally(() => setLoading(false));
  };

  const submitCondition = (name: string, email: string, errors: any) =>
    Object.keys(errors).length > 0 || !!error ||
      !recaptchaResponse ||
      files.length === 0 ||
      files.length > 25000 ||
      !name.length ||
      !email.length;

  return (
    <StyledMainContainer>
      <Formik
        initialValues={{
          name: "",
          email: "",
          phoneNumber: "",
          interests: "",
          projects: "",
          others: "",
        }}
        validationSchema={ApplySchema}
        onSubmit={(values) => {
          if (files) {
            submitForm({ ...values, positionType });
          }
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
        }) => (
          <>
            <InputField
              label="What is your name?*"
              placeholder="Your name"
              id="name"
              name="name"
              value={values.name}
              error={touched.name && errors.name}
              isGoodInput={touched.name && !errors.name}
              autoFocus
              onChange={handleChange("name")}
              onBlur={handleBlur("name")}
            />
            {errors.name && touched.name ? (
              <StyledErrorContainer>{errors.name}</StyledErrorContainer>
            ) : null}
            <InputField
              label="Your email *"
              placeholder="your@email.com"
              id="email"
              name="email"
              value={values.email}
              error={touched.email && errors.email}
              isGoodInput={touched.email && !errors.email}
              onChange={handleChange("email")}
              onBlur={handleBlur("email")}
            />
            {errors.email && touched.email ? (
              <StyledErrorContainer>{errors.email}</StyledErrorContainer>
            ) : null}
            <InputField
              label="What is your phone number?"
              placeholder="Phone number (optional)"
              id="phoneNumber"
              name="phoneNumber"
              required={false}
              value={values.phoneNumber}
              error={touched.phoneNumber && errors.phoneNumber}
              isGoodInput={
                touched.phoneNumber &&
                !errors.phoneNumber &&
                values.phoneNumber !== ""
              }
              onChange={handleChange("phoneNumber")}
              onBlur={handleBlur("phoneNumber")}
            />
            {errors.phoneNumber && touched.phoneNumber ? (
              <StyledErrorContainer>{errors.phoneNumber}</StyledErrorContainer>
            ) : null}
            <MultiLineTextInput
              label="Personal interest or hobbies"
              ariaLabel={accessibility.INTERESTS}
              placeholder="If you’d like to share, we’d love to know your interests outside the office."
              value={values.interests}
              onChange={handleChange("interests")}
            />
            <MultiLineTextInput
              label="Side projects"
              ariaLabel={accessibility.PROJECTS}
              placeholder="If you’d have any personal projects, side projects you worked on with friends, OS projects you contribute to, anything that you think is interesting to share with us."
              value={values.projects}
              onChange={handleChange("projects")}
            />
            <MultiLineTextInput
              label="Anything else you would like to share with us?"
              ariaLabel={accessibility.OTHERS}
              placeholder=""
              value={values.others}
              onChange={handleChange("others")}
            />
            <StyledResumeContainer>
              <StyledResumeLabelContainer>
                <StyledResumeFirstLabel>
                  CV/Resume* &nbsp;
                </StyledResumeFirstLabel>
                <StyledResumeSecondLabel>
                  only pdf please, maximum upload size 25MB
                </StyledResumeSecondLabel>
              </StyledResumeLabelContainer>
              <FileUploader onFileSelect={(files: any) => setFiles(files)} />
            </StyledResumeContainer>
            <div style={{ marginTop: 90 }}>
              <Suspense fallback={<div></div>}>
                <Recaptcha
                  className="g-recaptcha"
                  sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY ?? ""}
                  onChange={onChangeRecaptcha}
                />
              </Suspense>
            </div>
            <StyledButtonContainer>
              <Button
                text="Submit"
                fontSize={36}
                mobileFontSize={24}
                customPadding={
                  {
                    paddingTopBottom: 16,
                    paddingLeftRightMobile: 60,
                  }
                }
                onClick={handleSubmit}
                disabled={submitCondition(values.name, values.email, errors)}
                loading={loading}
              />
            </StyledButtonContainer>
          </>
        )}
      </Formik>
    </StyledMainContainer>
  );
};

export default React.memo(Form);
