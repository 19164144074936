import styled from "styled-components";
import { sizes } from "utility/device";
import Arrow from "src/images/arrowDown.svg";

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: space-between;
  cursor: pointer;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledArrowButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  
  @media all and (max-width: ${sizes.m}) {
    left: 0;
  }
`;

export const StyledArrow = styled(Arrow)<{open: boolean}>`
  transform: rotate(${({ open }) => (open ? "180deg" : "0deg")});
  transition: transform 0.4s;

  @media all and (max-width: ${sizes.m}) {
    width: 32.42px;
    height: 39px;
    right: 0;
  }
`;

export const StyledType = styled.p`
  font-size: 18px;
  line-height: 17.69px;
  letter-spacing: -0.01em;

  margin-bottom: 18px;
  margin-top: 83px;

  @media all and (max-width: ${sizes.s}) {
    margin-top: 69px;
    margin-bottom: 19px;
  }
`;

export const StyledTitle = styled.p`
  font-size: 48px;
  line-height: 130%;
  letter-spacing: -0.01em;

  margin-bottom: 39px;

  @media all and (max-width: ${sizes.m}) {
    font-size: 24px;
    line-height: 31.2px;
    letter-spacing: -1%;
    margin-bottom: 36px;
    width: 70vw;
  }
`;

export const StyledParagraph = styled.p`
  font-size: 24px;
  line-height: 140%;
  letter-spacing: -0.01em;
  white-space: pre-wrap;

  width: 90%;

  margin-bottom: 22px;

  @media (max-width: ${sizes.s}) {
    font-size: 18px;
    line-height: 25.2px;
    letter-spacing: -1%;
    width: 100%;
  }
`;

export const StyledSectionParagraph = styled.p`
  font-size: 18px;
  line-height: 140%;
  letter-spacing: -0.01em;
  white-space: pre-wrap;

  width: 90%;

  margin-bottom: 22px;

  @media (max-width: ${sizes.s}) {
    font-size: 10px;
    line-height: 25.2px;
    letter-spacing: -1%;
    width: 100%;
  }
`;

export const StyledButtonContainer = styled.div`
  width: 75%;
  display: flex;

  justify-content: flex-end;
  align-items: flex-end;

  margin-bottom: 39px;
`;

export const StyledApplyNowButtonContainer = styled.div`
  width: 75%;
  display: flex;

  justify-content: flex-end;
  align-items: flex-end;

  margin-bottom: 39px;

  @media all and (max-width: ${sizes.m}) {
    justify-content: flex-start;

    width: 100%;
    max-width: 368px;

    margin-bottom: 63px;
  }
`;

export const StyledHiddenContainer = styled.div<{ open: boolean }>`
  max-height: ${({ open }) => (open ? "4000px" : "0")};
  transition: max-height 0.5s ease-in-out;
  flex-direction: column;
  overflow: hidden;

  width: 100%;
`;

export const StyledSeparatorContainer = styled.div`
  margin-bottom: 46px;
`;

export const StyledHiddenTextContainer = styled.div`
  width: 100%;
`;

export const StyledHiddenListContainerRow = styled.div`
  display: flex;
  flex-direction: row;

  @media all and (max-width: ${sizes.m}) {
    flex-direction: column;
  }
`;
