import React from "react";

import LetsTalkSection from "src/components/LetsTalkSection/letsTalkSection";

interface INoOpeningSectionProps {
  noJobs: boolean;
  noInternships: boolean;
}

const NoOpeningSection = ({ noJobs, noInternships }: INoOpeningSectionProps): JSX.Element => (
  <LetsTalkSection
    link="/apply"
    title={`No ${
      noJobs ? "job" : ""
    }${
      noInternships &&
      noJobs
        ? " or "
        : ""
    }${
      noInternships
        ? "internship"
        : ""
    } openings \nat the moment.`}
    paragraph={"Do keep an eye open, as things may change soon."}
    page="careers"
    noOpenings
  />
);

export default NoOpeningSection;
