import { genTextStyle } from "src/styles/globalStyle";
import styled from "styled-components";

export const StyledHeroParagraphContainer = styled.div<{ maxWidth?: number; }>`
  width: 100%;
  max-width: ${({ maxWidth = 1080 }) => maxWidth}px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const StyledHeroParagraphText = styled.div`
  ${genTextStyle({
    maxFontSize: 24,
    maxLineHeight: 28.8,
    minFontSize: 14,
    minLineHeight: 16.8,
  })}
`;
