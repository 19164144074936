export enum ButtonType {
  BLACK = 0,
  TRANSPARENT = 1,
  SUBSCRIBE = 2,
}

interface ButtonThemeProps {
  backgroundColor: string;
  color: string;
  hoverBackgroundColor: string;
  hoverColor: string;
  borderColor: string;
}

export const theme = {
  colors: {
    homeBackground: "#D5FAD9",
    startupsBackground: "#FFE7E7",
    enterprisesBackground: "#EFDAFF",
    careersBackground: "#FDFFAD",
    notFoundBackground: "#FF8E4E",
    companyBackground: "#8BBAFF",
    white: "#FFFFFF",
    black: "#000000",
    red: "#FF1717",
    grey: "rgba(0, 0, 0, 0.3)",
    lightGray: "#BDBDBD",
    lightGray2: "#E6E6E6",
    orange: "#FF6B00",
    oliveGreen: "#B5DBC9",
    pink: "#FFE7E7",
    yellowPastel: "#FDFFAD",
    cream: "#E7DDD1",
    purple: "#D0CAF2",
    orangeYellow: "#FFD76F",
    yellow: "#FFF730",
    error: "#FF0000",
    lavander: "#D4D4FF",
  },
  blackButtonTheme: {
    backgroundColor: "#000000",
    color: "#FFFFFF",
    hoverBackgroundColor: "transparent",
    hoverColor: "#000000",
    borderColor: "#000000",
    disabledOpacity: 0.5,
  },
  subscribeButtonTheme: {
    backgroundColor: "#000000",
    color: "#FFFFFF",
    hoverBackgroundColor: "transparent",
    hoverColor: "#000000",
    borderColor: "#000000",
    disabledBackgroundColor: "#000000",
    disabledColor: "#FFFFFF66",
    disabledOpacity: 1,
  },
  transparentButtonTheme: {
    backgroundColor: "transparent",
    color: "#000000",
    hoverBackgroundColor: "#000000",
    hoverColor: "#FFFFFF",
    borderColor: "#000000",
    disabledOpacity: 0.5,
  },
};

const buttonTheme = (
  buttonType: ButtonType = ButtonType.TRANSPARENT
): ButtonThemeProps => {
  switch (buttonType) {
    case ButtonType.TRANSPARENT:
      return theme.transparentButtonTheme;
    case ButtonType.BLACK:
      return theme.blackButtonTheme;
    case ButtonType.SUBSCRIBE:
      return theme.subscribeButtonTheme;
    default:
      return theme.blackButtonTheme;
  }
};

export default buttonTheme;
