import styled from "styled-components";
import { sizes } from "utility/device";
import { genCustomPropStyle, genTextStyle } from "src/styles/globalStyle";

export const StyledDottedListContainer = styled.div<{ hidePadding: boolean }>`
  display: flex;
  flex-direction: column;
  ${genCustomPropStyle({
    propName: "gap",
    maxSize: 80,
    minSize: 60,
  })}
`;

export const ListItemWrraper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  gap: 8px;

  ${genCustomPropStyle({
    propName: "padding-left", maxSize: 24, minSize: 12,
  })}
`;

export const StyledHeading = styled.h6`
  ${genTextStyle({
    maxFontSize: 30,
    maxLineHeight: 36,
    minFontSize: 18,
    minLineHeight: 23.4,
  })}
`;

export const StyledParagraph = styled.div`
  font-size: max(0.938vw, 14px);
  line-height: max(1.125vw, 16.8px);
  max-width: 1003px;
  width: 100%;
  padding-left: 12px;
  box-sizing: border-box;

  @media all and (min-width: ${sizes.xl}) {
    font-size: 18px;
    line-height: 21.6px;
  }

  @media all and (max-width: ${sizes.m}) {
    padding-left: 0px;
  }
`;
