import React from 'react';
import {
  Back,
  Container,
  Front,
  Top,
  Rectangle,
  Bottom,
  Mask,
} from './cubeSpinnerStyle';

interface ICubeSpinner {
  display: [string, string, string, string];
  background: string;
  maxWidth: number;
  maxHeight: number;
  minWidth: number;
  minHeight: number;
}

const CubeSpinner = ({
  display,
  ...props
}: ICubeSpinner) => (
  <Container>
    <Rectangle>
      <Front {...props}>{display[0]}</Front>
      <Back {...props}>{display[1]}</Back>
      <Top {...props}>{display[2]}</Top>
      <Bottom {...props}>{display[3]}</Bottom>
    </Rectangle>
    <Mask>awesome</Mask>
  </Container>
);
  
export default CubeSpinner;
  