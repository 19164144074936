import React from "react";

import { dateConverter } from "utility/functions";
import {
  ColumnContainer,
  CreatorText,
  DateText,
  NameText,
  RowContainer,
} from "./nameAndDateStyle";

interface BlogNameAndDatePropsI {
  creator?: string;
  date: string;
  isNodePage?: boolean;
}

export const BlogNameAndDate = ({
  creator,
  date,
  isNodePage = false,
}: BlogNameAndDatePropsI): JSX.Element => {
  return (
    <ColumnContainer>
      {!isNodePage && <CreatorText>Creator:</CreatorText>}
      <RowContainer>
        <NameText>{creator}</NameText>
        <DateText>{dateConverter(date)}</DateText>
      </RowContainer>
    </ColumnContainer>
  );
};
