import styled from "styled-components";
import { sizes } from "utility/device";
import { Link } from "gatsby";
import { genCustomPropStyle, genTextStyle } from "src/styles/globalStyle";

export const StyledArchiveItemContainer = styled.div<{
  lastItem?: boolean;
  firstItem?: boolean;
}>`
  position: relative;
  display: flex;
  flex: 1;
  margin-bottom: ${({ lastItem }) => (lastItem ? "0px" : "64px")};
  margin-top: ${({ firstItem }) => (firstItem ? "64px" : "0px")};
  flex-direction: row;
  ${genCustomPropStyle({
    propName: "gap",
    maxSize: 53,
    minSize: 16,
  })}

  @media (max-width: ${sizes.mediumS}) {
    justify-content: center;
    align-items: center;
    margin-bottom: 10vw;
    gap: 32px;
  }

  @media (max-width: ${sizes.minS}) {
    margin-bottom: 10px;
  }

  @media (min-width: ${sizes.xl}) {
    margin-bottom: ${({ lastItem }) => (lastItem ? "0px" : "165px")};
    margin-top: ${({ firstItem }) => (firstItem ? "165px" : "0px")};
  }
`;

export const StyledArchiveImage = styled.div<{ src?: string }>`
  display: flex;
  flex: 1;
  background-image: ${({ src }) => `url(/${src})`};
  background-size: cover;
  background-position: center top;
  aspect-ratio: 3/4;
  border-radius: 40px;

  max-width: 300px;
  min-width: 300px;
  max-height: 400px;
  min-height: 400px;

  @media (max-width: ${sizes.mediumS}) {
    max-width: 100%;
    min-width: 100%;
    min-height: 107vw;
    max-height: 107vw;
  }
`;

export const StyledArchiveContent = styled.div<{ src?: string }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-content: flex-end;

  @media (max-width: ${sizes.mediumS}) {
    display: none;
  }
`;

export const StyledArchiveMobileContent = styled.div<{
  src?: string;
  lastItem?: boolean;
}>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-content: flex-end;
  margin-left: 2.5vw;
  margin-bottom: ${({ lastItem }) => (lastItem ? "0px" : "10vw")};

  @media (min-width: ${sizes.mediumS}) {
    display: none;
  }
`;

export const StyledArchiveGrid = styled.div<{ src?: string; hideBorder?: boolean }>`
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-content: flex-end;
  ${({ hideBorder }) => !hideBorder && "border-bottom: 2px solid #000000"};

  @media (max-width: ${sizes.mediumS}) {
    padding-top: 20px;
  }
`;

export const StyledBottomArchiveGrid = styled.div<{ src?: string }>`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2vw;
  gap: 20px;

  @media (max-width: ${sizes.m}) {
    margin-bottom: 40px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media (max-width: ${sizes.mediumS}) {
    justify-content: center;
    align-items: flex-start;
    text-align: left;
  }

  @media (min-width: ${sizes.xl}) {
    margin-bottom: 40px;
  }
`;

export const StyledArchiveYear = styled.div`
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 112.17%;
  letter-spacing: -0.02em;
  color: #000000;

  @media all and (max-width: ${sizes.l}) {
    font-size: 2vw;
  }

  @media (max-width: ${sizes.m}) {
    font-size: 18px;
  }
`;

export const StyledArchiveTitle = styled(Link)`
  &:hover {
    cursor: pointer;
  }
`;

export const FilterWrapper = styled.div<{ paddingBottom?: boolean }>`
  display: flex;
  padding-top: 20px;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 100%;
  ${({ paddingBottom }) => paddingBottom && "padding-bottom: 40px"};

  @media (max-width: ${sizes.minS}) {
    gap: 8px;
  }
`;

export const FilterLabelWrapper = styled.div<{ paddingBottom?: boolean }>`
  display: flex;
  padding-top: 20px;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 1146px;
  ${({ paddingBottom }) => paddingBottom && "padding-bottom: 40px"};

  @media (max-width: ${sizes.minS}) {
    gap: 2px;
  }
`;

export const FilterLabel = styled.div<{ isHidden?: boolean }>`
  ${genTextStyle({
    maxFontSize: 24,
    maxLineHeight: 28.8,
    minFontSize: 14,
    minLineHeight: 16.8,
  })}
  color: ${({ theme }) => theme.colors.lightGray};
`;

export const FiltersHeading = styled.div`
  font-size: 36px;
  line-height: 36px;
`;

export const ClearFilters = styled.div`
  padding: 10px 20px;
  white-space: nowrap;
  align-self: flex-end;
  font-size: 24px;
  cursor: pointer;

  @media (max-width: ${sizes.minS}) {
    font-size: 18px;
  }
`;

export const ButtonWrapper = styled.div`
  min-width: 236px;

  @media (max-width: ${sizes.mediumS}) {
    display: flex;
    align-self: center;
  }
`;

export const NoResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding: 140px 0px;

  @media (max-width: ${sizes.mediumS}) {
    gap: 40px;
    padding: 80px 0px;
  }
`;

export const NoResultsHeading = styled.div`
  font-size: 96px;
  line-height: 102px;

  @media (max-width: ${sizes.mediumS}) {
    font-size: 56px;
    line-height: 62px;
  }
`;

export const NoResultsParagraph = styled.div`
  font-size: 44px;
  line-height: 52px;
  max-width: 729px;

  @media (max-width: ${sizes.mediumS}) {
    font-size: 28px;
    line-height: 38px;
    max-width: 350px;
    padding-bottom: 0px;
  }
`;

export const StyledSeparatorFullWidth = styled.div`
  height: 2px;
  width: 100vw;
  background-color: ${(props) => props.theme.colors.black};
  margin-left: -60px;

  margin-left: -3.4rem;
  box-sizing: border-box;

  @media (max-width: ${sizes.l}) {
    margin: 0;
    width: 100%;
  }
`;
