import styled from "styled-components";

import { sizes } from "utility/device";
import { ColumnContainer, genCustomPropsStyle } from "src/styles/globalStyle";

export const StyledContainer = styled(ColumnContainer)`
  padding-bottom: 60px;
  gap: 60px;
  ${genCustomPropsStyle({
    props: [
      { propName: "padding-top", minSize: 0, maxSize: 60 },
      { propName: "gap", minSize: 32, maxSize: 60 },
    ]
  })}
`;

export const StyledLi = styled.li`
  font-size: 30px;
  line-height: 36px;

  @media (max-width: ${sizes.m}) {
    font-size: 20px;
    line-height: 24px;
  }
`;
