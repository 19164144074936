import React from "react";

import { medianCostList } from "utility/lists";
import DottedList from "src/components/DottedList/dottedList";

import {
  MedianCostContainer,
} from "./medianCostStyle";

const MedianCost = (): JSX.Element => {
  return (
    <MedianCostContainer>
      <DottedList
        title="Median cost and timeline."
        list={medianCostList}
      />
    </MedianCostContainer>
  );
};

export default React.memo(MedianCost);
