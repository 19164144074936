import { StyledSeparator } from "src/components/Layout/layoutStyle";
import * as React from "react";
import Footer from "src/components/Footer/footer";
import SectionAnimation from "src/components/SectionAnimation/sectionAnimation";

import {
  StyledTitle,
  StyledParagraph,
  StyledContentWrapper,
  StyledSubtitle,
  ParagraphSeparator,
  StyledLabel,
  StyledMainWrapper,
} from "./privacyPolicyStyle";

export const PrivacyPolicy = () => (
  <SectionAnimation>
    <StyledMainWrapper>
      <StyledContentWrapper>
        <StyledTitle>Privacy Policy</StyledTitle>
        <StyledParagraph>
          Your privacy is important to us.<br /><br />
          It is LamdbaWorks’ policy to respect your privacy regarding any information we may collect while operating our website. Accordingly, we have developed this privacy policy in order for you to understand how we collect, use, communicate, disclose and otherwise make use of personal information. We have outlined our privacy policy below.<br /><br />
          We will collect personal information by lawful and fair means and, where appropriate, with the knowledge or consent of the individual concerned.<br /><br />
          Before or at the time of collecting personal information, we will identify the purposes for which information is being collected.<br /><br />
          We will collect and use personal information solely for fulfilling those purposes specified by us and for other ancillary purposes, unless we obtain the consent of the individual concerned or as required by law.<br /><br />
          Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.<br /><br />
          We will protect personal information by using reasonable security safeguards against loss or theft, as well as unauthorized access, disclosure, copying, use or modification.<br /><br />
          We will make readily available to customers information about our policies and practices relating to the management of personal information.<br /><br />
          We will only retain personal information for as long as necessary for the fulfilment of those purposes.<br /><br />
          We are committed to conducting our business in accordance with these principles in order to ensure that the confidentiality of personal information is protected and maintained. LamdbaWorks may change this privacy policy from time to time at LamdbaWorks’ sole discretion.<br /><br />
          For contact please send an email to:<br />
          <a href="mailto:office@lambdaworks.io">office@lambdaworks.io</a>
        </StyledParagraph>

        <StyledTitle>Cookies</StyledTitle>

        <StyledSubtitle>What Are Cookies</StyledSubtitle>

        <StyledParagraph>
          As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites functionality.
        </StyledParagraph>

        <ParagraphSeparator />
        <StyledSubtitle>How We Use Cookies</StyledSubtitle>
        <StyledParagraph>
          We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.
        </StyledParagraph>

        <ParagraphSeparator />
        <StyledSubtitle>Disabling Cookies</StyledSubtitle>
        <StyledParagraph>
          You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies.
        </StyledParagraph>

        <ParagraphSeparator />
        <StyledSubtitle>The Cookies We Set</StyledSubtitle>
        <StyledLabel>Forms related cookies</StyledLabel>
        <StyledParagraph>
          When you submit data to through a form such as those found on contact pages or comment forms cookies may be set to remember your user details for future correspondence.
        </StyledParagraph>

        <ParagraphSeparator />
        <StyledLabel>Third Party Cookies</StyledLabel>
        <StyledParagraph>
          In some cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.
        </StyledParagraph>

        <ParagraphSeparator />
        <StyledLabel>Google Analytics</StyledLabel>
        <StyledParagraph>
          This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content.<br /><br />
          For more information on Google Analytics cookies, see the official <a href="https://policies.google.com/privacy?hl=en-US">Google Analytics page</a>.
        </StyledParagraph>

        <ParagraphSeparator />
        <StyledLabel>FullStory</StyledLabel>
        <StyledParagraph>
          We use the web analysis tool FullStory to continuously develop our website and provide visitors with the best possible experience and customer service. FullStory also uses cookies, which enable session-based analysis of your use of the website.<br />
          The data generated by the cookie and other records about visits to our websites are sometimes stored, processed and made available to us by FullStory. FullStory only records your IP address and deletes it automatically after a period of 30 days at the latest. By using the "Discard user IP addresses" extension, we ensure that FullStory does not transmit your IP address to us. FullStory further records your name and email address. The personal data recorded by FullStory is sometimes stored, processed and made available to us by FullStory for customer service activities. All personal data is being anonymized or deleted 30 days after recording at the latest.<br /><br />
          For more information, please refer to <a href="https://www.fullstory.com/legal/privacy-policy/">FullStory's privacy policy.</a><br /><br />
          Third party analytics are used to track and measure usage of this site so that we can continue to produce engaging content. These cookies may track things such as how long you spend on the site or pages you visit which helps us to understand how we can improve the site for you.
        </StyledParagraph>
      </StyledContentWrapper>
      <StyledSeparator />
      <Footer />
    </StyledMainWrapper>
  </SectionAnimation>
);

export default PrivacyPolicy;
