import React, { useState, useEffect } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import Footer from "src/components/Footer/footer";
import CookieConsent from "src/components/CookieConsent/cookieConsent";
import Layout from "src/components/Layout/layout";
import { StyledSeparator } from "src/components/Layout/layoutStyle";
import { theme } from "utility/theme";
import { LocationPropI } from "utility/models";
import routes from "utility/routes";
import Hero from "components/Hero/hero";
import { PageContainer } from "src/styles/globalStyle";

interface PageProps {
  location: LocationPropI;
}

const ThanksPage = ({ location }: PageProps): JSX.Element => {
  const [menuColor, setMenuColor] = useState(theme.colors.white);
  const [isCookieVisible, setCookieVisible] = useState<boolean>(false);

  const handleColor = (isMenuOpened: boolean) => {
    isMenuOpened
      ? setMenuColor(theme.colors.black)
      : setMenuColor(theme.colors.white);
  };

  const locationPath = location.pathname;
  const breakpoints = useBreakpoint();
  const isDesktop = breakpoints.l;
  const isMobile = !breakpoints.s;

  const handleVisibility = (a: boolean) => {
    setCookieVisible(a);
  };

  useEffect(() => {
    if (isDesktop) {
      setMenuColor(theme.colors.white);
    }
  }, [isDesktop]);

  return (
    <Layout
      backgroundColor={menuColor}
      headerColor={theme.colors.oliveGreen}
      setBackgroundColor={handleColor}
      isCookieVisible={isCookieVisible}
      location={locationPath}
      headerRoute={routes.THANKS}
      isGetInTouchHidden
    >
      <PageContainer>
        <Hero
          title={isMobile ? "Thank you\nfor your\nmessage 👋" : "Thank you for your message 👋"}
          image={{
            src: "hero/appliedHero.png",
            mobileSrc: "hero/appliedHeroMobile.png",
          }}
        />
        <StyledSeparator />
        <Footer />
      </PageContainer>
      
      <CookieConsent getCookieVisibility={handleVisibility} />
    </Layout>
  );
};

export default ThanksPage;
