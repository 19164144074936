import React, { lazy, Suspense, useEffect, useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import Footer from "src/components/Footer/footer";
import { DottedListWithParagraph } from "src/components/DottedList/dottedList";
import SectionAnimation from "src/components/SectionAnimation/sectionAnimation";
import { coreValuesCareersList } from "utility/lists";
import LookingForAnIntership from "./LookingForAnIntership/lookingForAnIntership";

import OpenPosition from "./OpenPositions/openPositions";
import Hero from "components/Hero/hero";
import { PageContainer } from "src/styles/globalStyle";
import { mapOpenJobPosition } from "utility/functions";
import { InternshipPosition, IOpenPosition, Job } from "utility/models";
import CareersApi from "services/Careers.api";

const DontFitDescriptionSection = lazy(
  () => import("./DontFitDescriptionSection/dontFitDescriptionSection")
);
const NoOpeningSection = lazy(() => import("./NoOpenPositions/noOpening"));

const careersApi = new CareersApi();

const Careers = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMediumScreen = breakpoints.m;

  const [openJobPositions, setOpenJobPositions] = useState<IOpenPosition[]>([]);
  const [noJobs, setNoJobs] = useState<boolean>(false);

  const [openInternshipPositions, setOpenInternshipPositions] = useState<string[]>([]);
  const [noInternships, setNoInternships] = useState<boolean>(false);

  const fetchJobs = async (): Promise<void> => {
    try {
      const response = await careersApi.getJobs();
      const job: Job = response.data.job.data;
      if (!job) {
        setNoJobs(true);
        return;
      }
      setNoJobs(false);
      setOpenJobPositions(mapOpenJobPosition(job));
    } catch (error) {
      setNoJobs(true);
      setOpenJobPositions([]);
    }
  }

  const fetchInternships = async (): Promise<void> => {
    try {
      const response = await careersApi.getInternships();
      if (!response) {
        setNoInternships(true);
        return;
      }
      const positions: InternshipPosition[] = response.data.internship.data.attributes.positions;
      const openPositions = positions.filter((position) => position.active).map((position) => position.name);
      setOpenInternshipPositions(openPositions)
      setNoInternships(!openPositions?.length);
    } catch (error) {
      console.log({error})
      setNoInternships(true);
    }
  }

  useEffect(() => {
    fetchJobs();
    fetchInternships();
  }, []);

  return (
    <PageContainer>
      <SectionAnimation>
        <Hero
          title={isMediumScreen
            ? "Work with\npassionate, curious\npeople."
            : "Work with\npassionate,\ncurious\npeople."
          }
          image={{
            src: "hero/careersHero.png",
            mobileSrc: "hero/careersHeroMobile.png",
          }}
        />
      </SectionAnimation>

      {!noInternships && (
        <SectionAnimation>
          <LookingForAnIntership internships={openInternshipPositions} />
        </SectionAnimation>
      )}

      <SectionAnimation>
        <DottedListWithParagraph
          title="Our core values."
          list={coreValuesCareersList}
        />
      </SectionAnimation>

      {!noJobs && (
        <>
          <SectionAnimation>
            <OpenPosition
              openPositions={openJobPositions}
              errorState={noJobs}
            />
          </SectionAnimation>

          <Suspense fallback={<div></div>}>
            <SectionAnimation>
              <DontFitDescriptionSection />
            </SectionAnimation>
          </Suspense>
        </>
      )}

      {isMediumScreen && <div />}

      {noJobs && noInternships && (
        <Suspense fallback={<div></div>}>
          <SectionAnimation>
            <NoOpeningSection noJobs={noJobs} noInternships={noInternships} />
          </SectionAnimation>
        </Suspense>
      )}

      {isMediumScreen && <div />}
      
      <Footer />
    </PageContainer>
  );
};

export default React.memo(Careers);
