import React from "react";

import LetsTalkSection from "components/LetsTalkSection/letsTalkSection";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { StyledContainer } from "./extendDevelopmentStyled";
import routes from "utility/routes";


const ExtendDevelopmentTeam = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMediumScreen = breakpoints.m;

  return (
    <StyledContainer>
      <LetsTalkSection
        link={routes.SCALA_PROJECT_PROPOSAL}
        title={!isMediumScreen ? "Extend your Scala development team!" : "Extend your Scala\ndevelopment team!"}
        buttonText="Tell us about your project"
        mobileButtonFontSize={12}
        page="scala"
      />
    </StyledContainer>
  );
};

export default ExtendDevelopmentTeam;
