import React from "react";

import KnowleDiagramSVGMobile from "images/knowleDiagramMobile.svg";
import KnowleDiagramSVG from "images/knowleDiagram.svg";
import { KnowleDiagramContainer } from "./knowleDiagramStyle";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const KnowleDiagram = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isNarrowScreen = !breakpoints.m;
  
  return (
    <KnowleDiagramContainer>
      <h1>How it works.</h1>
      {!isNarrowScreen ? (
        <KnowleDiagramSVG width="100%" />
      ) : (
          <KnowleDiagramSVGMobile width="100%" />
      )}
    </KnowleDiagramContainer>
  );
};

export default React.memo(KnowleDiagram);
