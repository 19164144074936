import styled from "styled-components";
import { theme } from "utility/theme";
import { sizes } from "utility/device";
import { genTextStyle } from "src/styles/globalStyle";

export const Tag = styled.div<{ isSelected?: boolean }>`
  padding: 4px 16px;
  border-radius: 24px;
  cursor: ${({ isSelected }) => isSelected ? "default" : "pointer"};
  background-color: ${theme.colors
    .homeBackground};
  text-transform: uppercase;
  ${({ isSelected }) =>
  isSelected && `border: 1px solid ${theme.colors.black}`};
  color: ${theme.colors.black};
  ${genTextStyle({
    maxFontSize: 18,
    minFontSize: 14,
    maxLineHeight: 21.6,
    minLineHeight: 16.8,
    isCustomised: true,
  })};

  div {
    background: linear-gradient(to right, transparent, transparent),
      linear-gradient(to right, black, black);
    background-size: 100% 2px, 0 2px;
    background-position: bottom left;
    background-repeat: no-repeat;
    transition: background-size 0.3s, background-position 0s 0.3s;
    text-decoration: none;
    height: 1px;
  }

  ${({ isSelected }) => !isSelected && `
    &:hover {
      div {
        background-position: bottom right;
        background-size: 0 2px, 100% 2px;
      }
    }
  `}

  @media (max-width: ${sizes.m}) {
    div {
      background-position: bottom right;
      background-size: 0 2px, 100% 2px;
    }
  }
`;
