import { mobileActiveMenuRoutes } from "./lists";
import { IBlog, IBlogWithSuggestions, IJobContent, IJobPosition, IOpenPosition, IPositionDetails, Job, JobPositionList, RawBlogData } from "./models";

export const isScrolledIntoView = (ref: SVGSVGElement | SVGPathElement | null): boolean => {
  if (ref) {
    const elementBottom = ref.getBoundingClientRect().bottom;

    if (elementBottom <= window.innerHeight) {
      return true;
    }
  }
  return false;
};

export const isElementVisible = (el: HTMLElement, offset: number = 0) => {
  var rect = el.getBoundingClientRect(),
    vWidth = window.innerWidth || document.documentElement.clientWidth,
    vHeight = window.innerHeight || document.documentElement.clientHeight,
    efp = function (x: any, y: any) {
      return document.elementFromPoint(x, y + offset);
    };

  if (
    rect.right < 0 ||
    rect.bottom < 0 ||
    rect.left > vWidth ||
    rect.top > vHeight
  )
    return false;

  return (
    el.contains(efp(rect.left, rect.top)) ||
    el.contains(efp(rect.right, rect.top)) ||
    el.contains(efp(rect.right, rect.bottom)) ||
    el.contains(efp(rect.left, rect.bottom))
  );
};

export const setStaticViewportListener = (
  elementId: string,
  callback: (scrolled: boolean) => void,
  offset?: number
) => {
  const isBrowser = typeof window !== "undefined";

  if (isBrowser) {
    window.addEventListener("scroll", () => {
      const element = document.getElementById(elementId);
      if (element && isElementVisible(element, offset ?? 0)) {
        callback(true);
      }
    });
  }
};

export const setViewportListener = (elementId: string) => {
  const isBrowser = typeof window !== "undefined";

  if (isBrowser) {
    window.addEventListener("scroll", () => {
      const svg = document.getElementById(elementId);
      if (svg) {
        if (
          isElementVisible(svg, 200) &&
          svg.getAttribute("class") !== elementId
        ) {
          svg?.setAttribute("class", elementId);
        }
      }
    });
  }
};

export const isTouchDevice =
  typeof window !== "undefined" &&
  ("ontouchstart" in window || "onmsgesturechange" in window);

type StaticFolderName = "projects" | "hero" | "og";

export const mapStaticImgList = (
  folderName: StaticFolderName,
  data: string[],
) => data.map((src) => `${folderName}/${src}`);

export const findStaticImg = (
  data: string[],
  img: string,
) => data.find((src) => src.includes(img)) || data[0];

interface SchemaProps {
  name: string;
  description: string;
  route: string;
  logo?: string;
}

export const genSchema = ({
  name,
  description,
  route,
  logo = "../images/logo.svg",
}: SchemaProps) => ({
  "@context": "https://schema.org",
  "@type": "Corporation",
  name: name,
  description: description,
  url: `https://lambdaworks.io/${route}`,
  logo: "../images/logo.svg",
  sameAs: [
    "https://www.facebook.com/lambdaworksio",
    "https://twitter.com/lambdaworkshub",
    "https://www.instagram.com/_lambdaworks/",
    "https://www.linkedin.com/company/lambdaworksio/",
  ],
});

export const findActiveRoute = (searchRoute: string): string | undefined => {
  const routeData = mobileActiveMenuRoutes.find(
    (routeData: {route: string}) => routeData.route === searchRoute
  );

  return routeData ? routeData.active : undefined;
};

export const dateConverter = (rawDate: string) => {
  const dateFromStr = new Date(rawDate.split("T")[0]);
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(dateFromStr);
};

export const mapBlog = (rawBlog: RawBlogData): IBlog => ({
  id: rawBlog.id,
  ...rawBlog.attributes,
  creator: `${rawBlog.attributes.createdBy?.firstname ?? ""} ${rawBlog.attributes.createdBy?.lastname ?? ""}`?.trim(),
  tags: (rawBlog.attributes?.tags?.data ?? [])?.map((tag) => ({
    tag: tag.attributes?.tag || "",
    id: tag.id,
  })),
  suggestions: rawBlog.attributes?.suggestions?.data?.map((item) => ({
    id: item.id,
    slug: item.attributes.slug,
    title: item.attributes?.title,
    description: item?.attributes?.description,
    content: item?.attributes?.content,
    creator: `${item?.attributes?.createdBy?.firstname ?? ""} ${item?.attributes?.createdBy?.lastname ?? ""}`.trim(),
  })) ?? [],
  seo: {
    title: rawBlog.attributes.seo?.metaTitle ?? "",
    description: rawBlog.attributes.seo?.metaDescription ?? "",
    img: {
      url: rawBlog.attributes.seo?.metaImage?.media?.data?.attributes?.url ?? "",
      height: rawBlog.attributes.seo?.metaImage?.media?.data?.attributes?.height ?? 0,
      width: rawBlog.attributes.seo?.metaImage?.media?.data?.attributes?.width ?? 0,
      mime: rawBlog.attributes.seo?.metaImage?.media?.data?.attributes?.mime ?? "",
    }
  }
});

export const mapBlogWithSuggestions = (rawBlog: RawBlogData): IBlogWithSuggestions => ({
  id: rawBlog?.id,
  ...rawBlog.attributes,
  creator: `${rawBlog.attributes?.createdBy?.firstname ?? ""} ${rawBlog.attributes?.createdBy?.lastname ?? ""}`?.trim(),
  tags: (rawBlog.attributes?.tags?.data ?? [])?.map((tag) => ({
    tag: tag.attributes?.tag || "",
    id: tag.id,
  })),
  suggestions: rawBlog.attributes?.suggestions?.data?.map((item) => ({
    id: item.id,
    slug: item.attributes.slug,
    title: item.attributes?.title,
    description: item?.attributes?.description,
    content: item?.attributes?.content,
    creator: `${item?.attributes?.createdBy?.firstname ?? ""} ${item?.attributes?.createdBy?.lastname ?? ""}`.trim(),
  })) ?? [],
  seo: {
    title: rawBlog.attributes.seo?.metaTitle ?? "",
    description: rawBlog.attributes.seo?.metaDescription ?? "",
    img: {
      url: rawBlog.attributes.seo?.metaImage?.media?.data?.attributes?.url ?? "",
      height: rawBlog.attributes.seo?.metaImage?.media?.data?.attributes?.height ?? 0,
      width: rawBlog.attributes.seo?.metaImage?.media?.data?.attributes?.width ?? 0,
      mime: rawBlog.attributes.seo?.metaImage?.media?.data?.attributes?.mime ?? "",
    }
  }
});

export const mapBlogs = (blogs: Array<RawBlogData>) => {
  return blogs.map((rawBlog) => mapBlog(rawBlog));
};

export const mapOpenJobPosition = (job: Job): IOpenPosition[] => {
  return (job.attributes.positionList?.filter(item => item.active).map(item => {
    const extractText = (content: IJobContent[] | null) =>
      content?.flatMap(c => c.children.map(child => child.text)).join("\n") ?? "";

    const extractList = (details: IPositionDetails[] | null) =>
      details?.flatMap(detail => detail.name) ?? [];

    return {
      type: item.position,
      title: item.title,
      paragraph: extractText(item.description),
      hiddenList: [
        !!item.whatWeDo ? { title: "What we do:", content: extractText(item.whatWeDo) } : null,
        !!item.looking ? { title: "Who we're looking for:", content: extractText(item.looking) } : null,
        !!item.expectation?.length ? { title: "What we expect you to know:", list: extractList(item.expectation) } : null,
        !!item.plus?.length ? { title: "What's a plus:", list: extractList(item.plus) } : null,
        !!item.skills?.length ? { title: "Skills to have:", list: extractList(item.skills) } : null,
        !!item.youWill?.length ? { title: "You will:", list: extractList(item.youWill) } : null,
        !!item.whyApply ? { title: "Why should you apply?", content: extractText(item.whyApply) } : null
      ].filter(Boolean)
    };
  }));
};

export const roundNumber = (num: number): number => (
  Math.round((num + Number?.EPSILON) * 100) / 100
);

export const capitalizeFirstLetters = (str: string): string => (
  !str ? str : str.charAt(0).toUpperCase() + str.slice(1)
);
