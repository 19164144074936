import { genTextStyle } from "src/styles/globalStyle";
import styled from "styled-components";

import { sizes } from "utility/device";

export const StyledMainContainer = styled.div`
  max-height: 150px;
  position: relative;
`;

export const StyledLabel = styled.p`
  display: flex;
  align-items: flex-end;
  height: 65px;

  font-size: 18px;

  @media all and (max-width: ${sizes.s}) {
    margin-top: 40px;

    height: fit-content;
  }
`;

export const StyledInput = styled.input<{ error: boolean }>`
  border: none;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  min-height: 44px;

  ${genTextStyle({
    maxFontSize: 40,
    maxLineHeight: 48,
    minFontSize: 22,
    minLineHeight: 24,
  })}

  color: ${({ error, theme }) =>
    error ? theme.colors.red : theme.colors.black};

  &::-webkit-input-placeholder {
    opacity: 0.1;
  }

  &::placeholder {
    opacity: 0.1;
  }

  @media all and (max-width: ${sizes.mediumS}) {
    font-size: 22px;
    line-height: 24px;
  }
`;

export const StyledIconContainer = styled.div`
  position: absolute;
  bottom: 14px;
  right: 8px;

  @media all and (max-width: ${sizes.s}) {
    bottom: 5px;
  }
`;

export const StyledInputSeparator = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.black};
`;
