import styled from "styled-components";
import { sizes } from "utility/device";
import { ContentFrame, genCustomPropStyle, genCustomPropsStyle } from "src/styles/globalStyle";

export const CategorizedListWrapper = styled(ContentFrame)<{
  justifyContent?: "flex-start" | "space-between";
}>`
  display: flex;
  justify-content: ${({ justifyContent = "space-between" }) => justifyContent};
  gap: ${({ justifyContent }) => justifyContent === "flex-start" ? "9.375vw" : "1.667vw"};
  padding: 4.167vw;

  @media all and (min-width: ${sizes.xl}){
    gap: ${({ justifyContent }) => justifyContent === "flex-start" ? "180px" : "32px"};
    padding: 80px;
  }
`;

export const SectionWrapper = styled.div<{
  alignItems?: "flex-start" | "center";
}>`
  height: auto;
  ${({ alignItems = "center" }) => alignItems === "center" && `
    display: flex;
    flex-direction: column;
    align-items: center;
  `};
`;

export const SectionHeading = styled.div`
  border: ${(props) => `2px solid ${props.theme.colors.black}`};
  white-space: nowrap;
  box-sizing: border-box;
  text-align: center;

  padding: 0.833vw 1.667vw;
  min-width: 26.25vw;
  font-size: 2vw;
  line-height: 2.5vw;
  border-radius: 5.208vw;

  @media all and (min-width: ${sizes.xl}){
    padding: 16px 32px;
    font-size: 40px;
    line-height: 48px;
    border-radius: 100px;
    min-width: 504px;
  }
`;

export const SectionDescription = styled.div<{ align?: "center" | "start" }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${({ align = "center" }) => align === "start" ? genCustomPropsStyle({
    props: [
      {
        propName: "padding-left",
        maxSize: 60,
        minSize: 32,
      },
      {
        propName: "gap",
        maxSize: 24,
        minSize: 16,
      }
    ]

  }) : `
    text-align: start;
    ${genCustomPropStyle({
      propName: "gap",
      maxSize: 24,
      minSize: 16,
    })}`
  }

  margin: auto;
  margin-top: 40px;

  font-size: 1.563vw;
  line-height: 1.875vw;
  white-space: pre-wrap;

  @media all and (min-width: ${sizes.xl}){
    font-size: 30px;
    line-height: 36px;
  }

  @media all and (max-width: ${sizes.minM}){
    font-size: 18px;
    line-height: 23.4px;
  }
`;
