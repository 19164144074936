const BLOG_URL = '/blog'
export default {
  HOME: "/",
  PRODUCT_INNOVATION: "/product-innovation",
  TECH_EXCELLENCE: "/tech-excellence",
  CAREERS: "/careers",
  CONTACT: "/contact",
  THANKS: "/thanks",
  COMPANY: "/company",
  WORK: "/work",
  APPLY: "/apply",
  APPLIED: "/applied",
  PRIVACY_POLICY: "/cookie-and-privacy-policy",
  PROJECTS: "/projects",
  INTERNSHIP: "/internship",
  BLOG: BLOG_URL,
  SUPER_PHONE: "/projects/superphone",
  THEMES_KINGDOM: "/projects/themeskingdom",
  EL3MENT: "/projects/el3ment",
  MANILO: "/projects/manilo",
  NOVAK_DJOKOVIC_FOUNDATION: "/projects/novak-djokovic-foundation",
  LIFE_LOGS: "/projects/lifelogs",
  BIZZIT: "/projects/bizzit",
  NAG_NAG: "/projects/nagnag",
  BEXIO: "/projects/bexio",
  POSTA_KOPILOT: "/projects/posta-kopilot",
  NOON: "/projects/noon",
  TRADER_FYLES: "/projects/trader-fyles",
  NFME: "/projects/nfme",
  EXPORT_ONLINE: "/projects/export-online",
  HERALD: "/projects/herald",
  KNOWLE: "/knowle",
  KNOWLE_PROJECT: "/projects/knowle",
  LAMBERT: "/projects/lambert",
  SHELFIE: "/projects/shelfie",
  ZIOES: "/projects/zioes",
  EVERYBITE: "/projects/everybite",
  SCALA: "/scala",
  SCALA_PROJECT_PROPOSAL: "/scala-project-proposal",
  NOT_FOUND: "/404",
};

export const BLOG_ROUTES = {
  DATA_DRIVEN: `${BLOG_URL}/data-driven-approach-for-growing-smbs/`,
  DLC: `${BLOG_URL}/10-dlc/`,
  DAILY_RITUALS: `${BLOG_URL}/tiny-daily-rituals/`,
  DUE_DILIGENCE: `${BLOG_URL}/technical-due-diligence/`,
  SOURCE_MAP: `${BLOG_URL}/the-importance-of-source-maps-on-sentry/`,
  DESIGN_SPRINTS: `${BLOG_URL}/micro-design-sprints/`,
  DOMAIN_DRIVEN: `${BLOG_URL}/domain-driven-vs-storage-driven-object-modeling/`,
  AI_HUMAN_EXPERTISE: `${BLOG_URL}/ai-and-human-expertise/`,
  ELEVATING_DESIGN_EFFICIENCY: `${BLOG_URL}/elevating-design-efficiency-on-inherited-projects/`,
  UPDATE_HEAVY_TABLES: `${BLOG_URL}/update-heavy-tables/`,
}
