import { genTextStyle } from "src/styles/globalStyle";
import styled from "styled-components";

export const StyledMainContainer = styled.div`
  position: relative;
`;

export const StyledLabel = styled.p`
  display: flex;
  align-items: flex-end;
  height: 65px;
  font-size: 18px;
`;

export const StyledInput = styled.textarea`
  ${genTextStyle({
    maxFontSize: 40,
    maxLineHeight: 48,
    minFontSize: 18,
    minLineHeight: 24,
  })}

  border-style: solid;
  border-width: 2px;
  border-color: ${(props) => props.theme.colors.black};
  border-radius: 40px;
  max-width: 932px;
  width: 100%;
  height: 256px;
  margin-top: 30px;

  padding: 32px;
  box-sizing: border-box;

  resize: none;

  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;

  &::-webkit-input-placeholder {
    opacity: 0.2;
  }

  &::placeholder {
    opacity: 0.2;
  }
  
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.grey};
    border-radius: 6px;
  }

  &:focus {
    outline-color: black;
  }
`;
