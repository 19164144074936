import React from "react";

import BadInputIcon from "src/images/badInputIcon.svg";
import GoodInputIcon from "src/images/goodInputIcon.svg";

import {
  StyledIconContainer,
  StyledInput,
  StyledInputSeparator,
  StyledLabel,
  StyledMainContainer,
} from "./inputFieldStyle";

type InputFieldProps = {
  label: string;
  placeholder: string;
  id: string;
  name: string;
  autoFocus?: boolean;
  type?: string;
  required?: boolean;
  error?: boolean | string;
  isGoodInput?: boolean;
  value: string;
  onChange: (e: string | React.ChangeEvent<any>) => void;
  onBlur: (e: string | React.ChangeEvent<any>) => void;
};

const InputField = ({
  label,
  placeholder,
  autoFocus = false,
  type = "text",
  required = true,
  id,
  name,
  value,
  error,
  isGoodInput,
  onChange,
  onBlur,
}: InputFieldProps): JSX.Element => {
  return (
    <StyledMainContainer>
      <StyledLabel>{label}</StyledLabel>
      <StyledInput
        placeholder={placeholder}
        type={type}
        id={id}
        name={name}
        autoFocus={autoFocus}
        required={required}
        error={typeof error === "string"}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <StyledIconContainer>
        {error ? <BadInputIcon /> : isGoodInput ? <GoodInputIcon /> : null}
      </StyledIconContainer>
      <StyledInputSeparator />
    </StyledMainContainer>
  );
};

export default React.memo(InputField);
