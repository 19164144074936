import styled from "styled-components";

import { sizes } from "utility/device";
import { ContentFrame, genTextStyle } from "src/styles/globalStyle";

export const StyledWebMainContainer = styled.div`
`;

export const StyledWebContainer = styled.div<{ alignItems?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
`;

export const StyledWebReference = styled.div`
  color: #000000;
  opacity: 0.3;
  ${genTextStyle({
    maxFontSize: 24,
    maxLineHeight: 36,
    minFontSize: 18,
    minLineHeight: 23.4,
  })}
`;

export const StyledInitiativesWrapper = styled(ContentFrame)`
  margin-top: 80px;
  position: relative;
`;

export const StyledWebReferencesColContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const StyledWebReferencesRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  flex: 1;
`;

export const StyledWebReferencesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledWebReferenceItem = styled.div<{ isSelected: boolean }>`
  ${genTextStyle({
    maxFontSize: 24,
    maxLineHeight: 36,
    minFontSize: 18,
    minLineHeight: 23.4,
  })}

  color: #000000;
  opacity: ${({ isSelected }) => isSelected ? "1" : "0.3"};
`;

export const StyledWebInitiativesRow = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: space-between;

  @media all and (max-width: ${sizes.m}) {
    flex-direction: column;
    margin-bottom: 0;
  }

  a {
    cursor: auto;
  }
`;

export const StyledMobileMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  gap: 60px;
`;

export const StyledJapaneseSymbolBigContainer = styled.div`
  margin: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  @media all and (max-width: 1400px) {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export const StyledRowContainer = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: space-between;
`;
