import React, { useEffect, useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useSpring, animated } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import {
  defaultSectionOffset,
  sectionAnimationDelay,
  sectionAnimationDuration,
} from "utility/constants";

interface SectionAnimationProps {
  children: JSX.Element | JSX.Element[];
  forceAnimation?: boolean;
}

const SectionAnimation = ({
  children,
  forceAnimation = false,
}: SectionAnimationProps): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isDesktop = breakpoints.midS;
  const [offset, setOffset] = useState<number>(defaultSectionOffset);
  const [isDisplayed, setDisplayed] = useState<boolean>(false);

  const offsetDivider = isDesktop ? 2 : 4

  const updateOffset = (offset: number | undefined): void => {
    if (offset && window?.innerHeight && offset <= window?.innerHeight) {
      setOffset(offset / offsetDivider);
    } else if (window?.innerHeight) {
      if (isDesktop) {
        setOffset(window?.innerHeight / 3);
      } else {
        setOffset(100);
      }
    } else {
      setOffset(defaultSectionOffset);
    }
  };

  useEffect(() => {
    if (forceAnimation) {
      setDisplayed(false);
    }
  }, [forceAnimation]);

  const forceAnimationStyle = useSpring({
    delay: sectionAnimationDelay,
    config: { duration: sectionAnimationDuration },
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: `0px 0px -${offset}px 0px`,
  });

  const animationStyle = useSpring({
    delay: sectionAnimationDelay,
    config: { duration: sectionAnimationDuration },
    to: { opacity: inView || isDisplayed ? 1 : 0 },
  });

  return forceAnimation ? (
    <animated.div style={forceAnimationStyle}>{children}</animated.div>
  ) : (
    <div ref={ref}>
      <animated.div
        style={animationStyle}
        ref={(divElement) => {
          updateOffset(divElement?.offsetHeight);
        }}
      >
        {children}
      </animated.div>
    </div>
  );
};

export default SectionAnimation;
