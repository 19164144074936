import React from "react";

import { TechStackItem } from "utility/models";

import {
  StyledTechStackItem,
  StyledTechStackItemText,
  StyledTechStackItemTitle,
} from "./techStackStyle";

interface TechStackColumnProps {
  item: TechStackItem;
}

const TechStackColumn = ({ item }: TechStackColumnProps): JSX.Element => {
  return (
    <StyledTechStackItem>
      <StyledTechStackItemTitle>{item.title}</StyledTechStackItemTitle>
      <br />
      <StyledTechStackItemText>{item.items}</StyledTechStackItemText>
    </StyledTechStackItem>
  );
};

export default TechStackColumn;
