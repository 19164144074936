import React from "react";

import { ourCapabilitiesTechExcellenceList } from "utility/lists";
import DottedList from "src/components/DottedList/dottedList";

import {
  StyledCircle,
  StyledOCMainContainer,
} from "./ourCapabilitiesStyle";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const OurCapabilities = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMediumScreen = breakpoints.m;

  return (
    <StyledOCMainContainer>
      <DottedList
        title={isMediumScreen ? "Our capabilities." : "Our\ncapabilities."}
        list={ourCapabilitiesTechExcellenceList}
      />
      <StyledCircle />
    </StyledOCMainContainer>
  );
};

export default React.memo(OurCapabilities);
