import { query } from "services/GraphqlClient";
import { IGetBlogsProps } from "utility/models";

import GET_BLOGS from "gql/blogs.graphql";
import GET_BLOG from "gql/blog.graphql";

export default class BlogApi {
  getBlogs = (variables: IGetBlogsProps) => query(GET_BLOGS, variables);
  getBlog = (variables: IGetBlogsProps) => query(GET_BLOG, variables);
}
