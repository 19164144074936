import styled from "styled-components";
import { sizes } from "utility/device";
import { ContentFrame, genTextStyle } from "src/styles/globalStyle";
import { StyledSectionTitle } from "components/SectionTitle/sectionTitleStyle";

export const StyledTechStackMainContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 40px;

  @media all and (max-width: ${sizes.l}) {
    grid-template-columns: auto auto;
    column-gap: 20px;
    row-gap: 20px;
  }

  @media all and (max-width: 350px) {
    grid-template-columns: auto;
  }
`;

export const StyledTechStackItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledTechStackItemText= styled.div`
  ${genTextStyle({
    maxFontSize: 36,
    maxLineHeight: 39.6,
    minFontSize: 18,
    minLineHeight: 23.4,
  })}
`;

export const StyledTechStackItemTitle = styled.p`
  opacity: 0.3;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

export const ContentWrapper = styled(ContentFrame)`
  padding: 40px 80px;

  @media all and (max-width: ${sizes.m}) {
    padding: 20px;
  }
`;

export const MobileTitle = styled(StyledSectionTitle)`
  font-size: 44px;
  margin-bottom: 32px;

  @media all and (max-width: ${sizes.m}) {
    font-size: 44px;
  }
`;
