import React, { useState } from "react";

import Layout from "src/components/Layout/layout";
import CookieConsent from "src/components/CookieConsent/cookieConsent";
import { theme } from "utility/theme";
import Projects from "src/components/Projects/projects";
import routes from "utility/routes";
import { LocationPropI } from "utility/models";

interface PageProps {
  location: LocationPropI;
}

const PostaKopilotPage = ({ location }: PageProps): JSX.Element => {
  const [isCookieVisible, setCookieVisible] = useState<boolean>(false);

  const handleVisibility = (a: boolean) => {
    setCookieVisible(a);
  };

  const locationPath = location.pathname;

  return (
    <Layout
      backgroundColor={theme.colors.white}
      headerColor={theme.colors.cream}
      location={locationPath}
      headerRoute={locationPath}
      isCookieVisible={isCookieVisible}
    >
      <Projects route={routes.POSTA_KOPILOT} />
      <CookieConsent getCookieVisibility={handleVisibility} />
    </Layout>
  );
};

export default PostaKopilotPage;
