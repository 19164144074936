import React from "react";

import LetsTalkSection from "src/components/LetsTalkSection/letsTalkSection";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const DontFitDescriptionSection = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMediumScreen = breakpoints.m;

  return (
    <LetsTalkSection
      link="/apply"
      title={isMediumScreen ? "Don't fit\nthe description?" : "Don't fit the\ndescription?"}
      paragraph={
        "If you don't see yourself in the job posts, but you'd like to be a part of LambdaWorks just send us an email. We'd love to get to meet you!"
      }
      question={<div>Feel free to drop us your portfolio at<br /> jobs@lambdaworks.io</div>}
      buttonText="Email us"
      page="careers"
    />
  );
};

export default DontFitDescriptionSection;
