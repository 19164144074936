import styled from "styled-components";
import { sizes } from "utility/device";
import { genCustomPropStyle } from "src/styles/globalStyle";

export const AIProcessingContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 300px;
  ${genCustomPropStyle({
    propName: "gap",
    maxSize: 90,
    minSize: 40,
  })}
`;
