import styled from "styled-components";

import { sizes } from "utility/device";
import { genCustomPropsStyle } from "src/styles/globalStyle";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
`;

export const StyledLogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
  box-sizing: border-box;
  align-self: center;
  ${genCustomPropsStyle({
    props: [
      { propName: "gap", maxSize: 160, minSize: 32 },
      { propName: "padding-left", maxSize: 200, minSize: 0 },
      { propName: "padding-right", maxSize: 200, minSize: 0 }
    ]
  })}
`;

export const StyledLogoWrapper = styled.div`
  display: flex;
  flex-shrink: 0;

  @media (max-width: ${sizes.m}) {
    max-width: 140px;
  }
`;
