import React, { useEffect, useRef } from "react";
import { isScrolledIntoView } from "utility/functions";

interface Props {
  width?: number | string;
  height?: number | string;
  strokeWidth?: number;
}

const SparkYourInnovationSVG = ({
  width = 440,
  height = 328,
  strokeWidth = 2,
}: Props) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const lineRef = useRef<SVGPathElement>(null);
  const leftArrow = useRef<SVGPathElement>(null);
  const rightArrow = useRef<SVGPathElement>(null);

  useEffect(() => {
    const animateCTA = () => {
      const isBrowser = typeof window !== "undefined";
      if (isBrowser) {
        window.addEventListener("scroll", () => {
          if (isScrolledIntoView(svgRef?.current)) {
            lineRef?.current?.setAttribute("class", "cta_wirl");
            leftArrow?.current?.setAttribute("class", "cta_arrow");
            rightArrow?.current?.setAttribute("class", "cta_arrow");
          }
        });
      }
    };

    animateCTA();
  }, []);

  return (
    <svg
      ref={svgRef}
      width={width}
      height={height}
      viewBox="0 0 440 328"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>
        {`
          .cta_wirl {
            stroke-dasharray: 1700;
            stroke-dashoffset: 1700;
            animation-name: dash;
            animation-duration: 1s;
            animation-timing-function: linear; 
            animation-fill-mode: forwards;
            animation-delay: 500ms;
          }
      
          .cta_arrow {
            stroke-dasharray: 1700;
            stroke-dashoffset: 1700;
            animation-delay: 1000ms;
            animation-name: dash;
            animation-duration: 1s;
            animation-timing-function: linear; 
            animation-fill-mode: forwards;
          }
      
          @keyframes dash {
            to {
              stroke-dashoffset: 0;
            }
          }
        `}
      </style>
      <path
        ref={leftArrow}
        strokeDasharray="1700"
        d="M387 301.102L417.809 327.087"
        stroke="black"
        strokeWidth={strokeWidth}
      />
      <path
        ref={rightArrow}
        strokeDasharray="1700"
        d="M439 300.102L416.615 326.891"
        stroke="black"
        strokeWidth={strokeWidth}
      />
      <path
        ref={lineRef}
        strokeDasharray="100"
        d="M1,54.1085C142.5,-76.3912 280,69.6075 175.5,150.609C140.004,178.123 105.332,158 108.5,131C109.611,121.532 115,110.609 123,103.609C135,93.1089 154,85.7443 170.5,83.609C340.5,61.609 414.193,189.917 417,325.608"
        stroke="black"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default SparkYourInnovationSVG;

