import styled from "styled-components";
import { sizes } from "utility/device";

export const StyledOpenPositionMainContainer = styled.div`
  width: 100%;
`;

export const StyledOpenPositionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media all and (max-width: ${sizes.m}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledOpenPositionParagraph = styled.p`
  font-size: 0.73vw;
  line-height: 150%;

  width: 21.5vw;
  max-width: 413px;
  height: 100%;
  padding-bottom: 24px;

  @media all and (min-width: ${sizes.xl}) {
    font-size: 18px;
    line-height: 150%;
    font-size: 14px;
  }

  @media all and (max-width: ${sizes.m}) {
    font-size: 14px;

    margin-top: 21px;

    width: 100%;
  }
`;
