import React from "react";

import {
  StyledContainer,
  StyledLogosContainer,
  StyledLogoWrapper,
} from "./technologyStyled";
import { MediumHeading } from "src/styles/globalStyle";

type TechnologyProps = {
  title: string;
  listItems: JSX.Element[];
};

const Technology = ({
  title,
  listItems,
}: TechnologyProps): JSX.Element => (
  <StyledContainer>
    <MediumHeading>{title}</MediumHeading>
    <StyledLogosContainer>
      {listItems.map((item) => (
        <StyledLogoWrapper key={item.key}>{item}</StyledLogoWrapper>
      ))}
    </StyledLogosContainer>
    
  </StyledContainer>
);

export default Technology;
