import React from "react";

import {
  StyledContainer,
} from "./openSourceProjectsStyled";

type OpenSourceProjectsProps = {
  title: string;
  listItems: string[];
};

const OpenSourceProjects = ({
  title,
  listItems,
}: OpenSourceProjectsProps): JSX.Element => (
  <StyledContainer>
    <h2>{title}</h2>
    <ul>
      {listItems.map((item) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  </StyledContainer>
);

export default OpenSourceProjects;
