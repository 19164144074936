import React, { lazy, Suspense } from "react";

import Footer from "src/components/Footer/footer";
import { StyledSeparator } from "src/components/Layout/layoutStyle";
import SectionAnimation from "src/components/SectionAnimation/sectionAnimation";
import AreaOfExpertise from "./AreaOfExpertise/areaOfExpertise";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import MoreThanCode from "./MoreThanCode/moreThanCode";
import Hero from "components/Hero/hero";
import { StyledProductInnovationContainer } from "./productInnovationStyle";
import HeroParagraph from "components/HeroParagraph/heroParagraph";
import { ColumnContainer } from "src/styles/globalStyle";

const CraftingProductsCarousel = lazy(() => import("./CraftingProductsCarousel/craftingProductsCarousel"))

const ProductInnovation = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMediumScreen = breakpoints.m;

  return (
    <StyledProductInnovationContainer>
      <SectionAnimation>
        <Hero
          title={isMediumScreen ? (
            <>
              Building digital <br />
              products that matter.
            </>
          ) : (
            <>
              Building 
              digital
              <br />
              products
              that&nbsp;matter.
              <br />
          </>
          )}
          image={{
            src: "hero/productInnovationHero.png",
            mobileSrc: "hero/productInnovationHeroMobile.png",
          }}
        />
        <ColumnContainer>
          <HeroParagraph
            text="We can engage in any phase of your product. From an idea on a piece of paper to fine-tuning an existing product. We can help."
          />
          <StyledSeparator />
        </ColumnContainer>
      </SectionAnimation>

      <SectionAnimation>
        <AreaOfExpertise />
      </SectionAnimation>

      <StyledSeparator />

      <Suspense fallback={<div></div>}>
        <SectionAnimation>
          <CraftingProductsCarousel />
        </SectionAnimation>
      </Suspense>

      {isMediumScreen ? <div /> : <StyledSeparator />}

      <Suspense fallback={<div></div>}>
        <SectionAnimation>
          <MoreThanCode />
        </SectionAnimation>
      </Suspense>

      {isMediumScreen && <div />}

      <Footer />
    </StyledProductInnovationContainer>
  );
};

export default React.memo(ProductInnovation);
