import styled, { createGlobalStyle } from "styled-components";
import { sizes } from "utility/device";
import { genCustomPropsStyle, genTextStyle } from "src/styles/globalStyle";

const genGlobalStyle = ({
  backgroundColor,
  isScrollable,
}: {
  backgroundColor?: string;
  isScrollable?: boolean;
}) => {
  return createGlobalStyle`
    html {
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
      box-sizing: border-box;
      max-width: 100%;
      overflow-x: hidden;
      ${isScrollable ? "overflow-y: scroll" : "overflow-y: hidden"};
    }

    body {
      margin: 0;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: normal;
      word-wrap: break-word;
      font-kerning: normal;
      -moz-font-feature-settings: "kern", "liga", "clig", "calt";
      -ms-font-feature-settings: "kern", "liga", "clig", "calt";
      -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
      font-feature-settings: "kern", "liga", "clig", "calt";
      font-family: Moderat-Regular, Arial, Helvetica, sans-serif;
      background-color: ${backgroundColor ?? "transparent"};
      overflow-x: hidden;
      letter-spacing: 0.01em;
      min-width: 320px;
      max-width: 100%;
      box-sizing: border-box;
    }

    h1 {
      ${genTextStyle({
        maxFontSize: 96,
        minFontSize: 44,
        maxLineHeight: 109.44,
        minLineHeight: 49.36,
      })}
      font-family: Moderat-Regular, Arial, Helvetica, sans-serif;
    }

    h2 {
      ${genTextStyle({
        maxFontSize: 64,
        minFontSize: 44,
        maxLineHeight: 76.8,
        minLineHeight: 49.36,
      })}
      font-family: Moderat-Regular, Arial, Helvetica, sans-serif;
    }

    h3 {
      ${genTextStyle({
        maxFontSize: 48,
        minFontSize: 32,
        maxLineHeight: 52.8,
        minLineHeight: 38.4,
      })}
      font-family: Moderat-Regular, Arial, Helvetica, sans-serif;
    }

    h4 {
      ${genTextStyle({
        maxFontSize: 40,
        maxLineHeight: 48,
        minFontSize: 18,
        minLineHeight: 23.4,
      })}
      font-family: Moderat-Regular, Arial, Helvetica, sans-serif;
    }
    
    h5 {
      ${genTextStyle({
        maxFontSize: 36,
        minFontSize: 22,
        maxLineHeight: 39.6,
        minLineHeight: 24,
      })}
      font-family: Moderat-Regular, Arial, Helvetica, sans-serif;
    }

    h6 {
      ${genTextStyle({
        maxFontSize: 30,
        maxLineHeight: 36,
        minFontSize: 24,
        minLineHeight: 28.8,
      })}
      font-family: Moderat-Regular, Arial, Helvetica, sans-serif;
    }

    a {
      font-style: normal;
      font-weight: normal;
      line-height: 1.813rem;
      color: #000000;
      text-decoration: none;
      cursor: pointer;
    }

    p {
      font-style: normal;
      font-weight: normal;
      text-decoration: none;
      margin: 0;
    }

    &::-moz-selection {
      color: #ffffff;
      background: #000000;
    }
    
    &::selection {
      color: #ffffff;
      background: #000000;
    }

    input {
      font-family: Moderat-Regular, Arial, Helvetica, sans-serif;
    }

    textarea {
      font-family: Moderat-Regular, Arial, Helvetica, sans-serif;
    }

    ul {
      padding: 0;
      list-style-type: none;
    }

    li {
      position: relative;
      ${genCustomPropsStyle({
        props: [
          { propName: "font-size", maxSize: 30, minSize: 24 },
          { propName: "line-height", maxSize: 36, minSize: 28.8 },
          { propName: "padding-bottom", maxSize: 24, minSize: 20 },
          { propName: "padding-left", maxSize: 40, minSize: 28 }
        ]
      })}

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 100%;
        background: ${(props) => props.theme.colors.black};
        ${genCustomPropsStyle({
          props: [
            { propName: "width", maxSize: 16, minSize: 9.6 },
            { propName: "height", maxSize: 16, minSize: 9.6 },
            { propName: "margin-top", maxSize: 12, minSize: 9.6 },
          ]
        })}
      }
    }
  `;
};

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  max-width: 1920px;
  position: relative;
`;

export const Container = styled.div<{ background: string }>`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.background};
  transition: background-color 0.3s linear 1s;
  padding-left: 3.4rem;
  padding-right: 3.4rem;
  box-sizing: border-box;
  width: 100%;
  ${(props) =>
    props.background !== `${props.theme.colors.black}` &&
    `
      transition: background-color 0.01s linear;
  `};

  @media (max-width: ${sizes.l}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const StyledSeparator = styled.div<{ marginBottom?: string }>`
  height: 2px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.black};
`;

export const LoaderWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FloatingButton = styled.div<{
  runAnimation: boolean;
  isCookieVisible?: boolean;
}>`
  height: 106px;
  width: 106px;
  border-radius: 53px;
  background-color: ${(props) => props.theme.colors.black};
  position: fixed;
  bottom: 20px;
  right: 20px;
  overflow: hidden;
  transition: bottom 0.4s ease-in-out;
  ${(props) => props.isCookieVisible && `bottom: 250px`};

  @media (min-width: ${sizes.m}) {
    ${(props) => props.runAnimation && `bottom: 70vh`};
  }

  @media (max-width: ${sizes.m}) {
    ${({ runAnimation, isCookieVisible }) => runAnimation && (!isCookieVisible ? `bottom: 150px;` : "bottom: 250px;")};
  }

  &::before {
    content: "Get in touch";
    color: white;
    font-size: 24px;
    text-align: center;
    height: inherit;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
  }
`;

export default genGlobalStyle;
