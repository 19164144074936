import React from "react";

import {
  StyledContainer,
  StyledLi,
} from "./ourMindsetStyled";
import { MediumHeading } from "src/styles/globalStyle";

type OurMindsetProps = {
  title: string;
  listItems: string[];
};

const OurMindset = ({
  title,
  listItems,
}: OurMindsetProps): JSX.Element => (
  <StyledContainer>
    <MediumHeading>{title}</MediumHeading>
    <ul style={{ margin: 0, padding: 0 }}>
      {listItems.map((item) => (
        <StyledLi key={item}>{item}</StyledLi>
      ))}
    </ul>
  </StyledContainer>
);

export default OurMindset;
