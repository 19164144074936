export const sizes = {
  xl: "1920px",
  minl: "1078px",
  bigL: "1201px",
  l: "1200px",
  minM: "979px",
  m: "978px",
  minMediumS: "646px",
  mediumS: "645px",
  minS: "481px",
  s: "480px",
  smallS: "375px",
  xs: "320px",
};

export const specificBreakpoints = {
  badgesMobile: "619px",
  ourCapabilitiesMobile: "1829px",
  footerItemsMobile: "1547px",
  reviewsMobile: "968px",
  arrowPaddingReviewMobile: "430px"
}
