import React, { lazy, Suspense } from "react";

import { PROJECT_CONTENT_DATA } from 'utility/projects'
import Footer from "src/components/Footer/footer";
import SectionAnimation from "src/components/SectionAnimation/sectionAnimation";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { findStaticImg, mapStaticImgList } from "utility/functions";
import { StyledScalaContainer } from "./scalaStyled";
import ScalaHero from "./ScalaHero";
import { StyledSeparator } from "components/Layout/layoutStyle";
import WhyLambdaWorks from "./WhyLambdaWorks";
import OurMindset from "./OurMindset";
import KeyValues from "./KeyValues";
import IndustryKnowledge from "./IndustryKnowledge";
import OpenSourceProjects from "./OpenSourceProjects";
import SuccessStories from "./SuccessStories";
import ExtendDevelopmentTeam from "./ExtendDevelopment";
import routes from "utility/routes";

const Technology = lazy(() => import("./Technology"));
const Akka = lazy(() => import("src/images/logos/akka.svg"));
const Kafka = lazy(() => import("src/images/logos/kafka.svg"));
const Play = lazy(() => import("src/images/logos/play.svg"));
const TypeLevelScala = lazy(() => import("src/images/logos/typeLevelScala.svg"));
const Zio = lazy(() => import("src/images/logos/zio.svg"));

const Scala = (): JSX.Element => {
  const projectImgs = mapStaticImgList("projects", [
    PROJECT_CONTENT_DATA.superphone.poster,
    PROJECT_CONTENT_DATA.bexio.poster,
    PROJECT_CONTENT_DATA.traderFyles.poster,
  ]);

  const heroImgs = mapStaticImgList("hero", ["scalaHero.png", "scalaHeroMobile.png", ]);

  const breakpoints = useBreakpoint();
  const isMediumScreen = breakpoints.m;

  return (
    <StyledScalaContainer>
      <SectionAnimation>
        <>
          <ScalaHero
            heading="Scala development services"
            title={isMediumScreen ? (
              <div>
                Extend your Scala <br />
                team and accelerate <br />
                your development.
              </div>
            ) : (
              <>
                Extend your Scala team and accelerate your development.
            </>
            )}
            paragraph={<>Get your Scala team ready in as little as two weeks<br />and achieve your goals faster.</>}
            image={{
              src: findStaticImg(heroImgs, "scalaHero.png"),
              mobileSrc: findStaticImg(heroImgs, "scalaHeroMobile.png"),
            }}
          />
          <StyledSeparator />
        </>
      </SectionAnimation>

      <SectionAnimation>
        {isMediumScreen ? <div /> : <></>}
      </SectionAnimation>

      <SectionAnimation>
        <WhyLambdaWorks text="Why LambdaWorks." />
      </SectionAnimation>

      <SectionAnimation>
        <OurMindset
          title="Our mindset."
          listItems={[
            "Scala-first company",
            "Contributing to Scala's open source\ncommunity",
            "Developing Scala open source libraries",
            "Organizing Scala events"
          ]}
        />
      </SectionAnimation>

      <SectionAnimation>
        <KeyValues
          title="Key values."
          listItems={[
            "Robust and scalable backend systems using major tools like Akka, Cats, ZIO, Play, Kafka.",
            "Implement microservices-based architecture.",
            "Concise, expressive, and maintainable code using functional programming principles.",
            "Scale your team up or down as needed, ensuring optimal team utilization."
          ]}
        />
      </SectionAnimation>

      <SectionAnimation>
        <IndustryKnowledge
          title="Industry knowledge."
          listItems={[
            "Fintech",
            "Marketing Tech",
            "AI",
            "Media",
            "Non-profit",
            "Music",
            "Food industry",
            "Education"
          ]}
        />
      </SectionAnimation>

      <SectionAnimation>
        <Suspense fallback={ <div></div>}>
          <Technology
            title="Technology."
            listItems={[
              <Akka />,
              <Zio />,
              <Play />,
              <TypeLevelScala />,
              <Kafka />
            ]}
          />
        </Suspense>
      </SectionAnimation>

      <SectionAnimation>
        <StyledSeparator />
      </SectionAnimation>

      <SectionAnimation>
        <OpenSourceProjects
          title="Our open source projects."
          listItems={[
            "Zio Elasticsearch",
            "Linthaal",
            "scurl-detector",
            "scountries"
          ]}
        />
      </SectionAnimation>

      <SectionAnimation>
        <StyledSeparator />
      </SectionAnimation>

      <SuccessStories
        title="Scala success stories."
        stories={[
          {
            src: findStaticImg(projectImgs, PROJECT_CONTENT_DATA.superphone.poster),
            projectName: "SuperPhone",
            description: "We helped SuperPhone build their complete Scala team, enabling them to launch their product and continuously deliver improvements.",
            route: routes.SUPER_PHONE,
          },
          {
            src: findStaticImg(projectImgs, PROJECT_CONTENT_DATA.bexio.poster),
            projectName: "bexio",
            description: "Our Scala developers work closely with bexio's team to build a scalable and reliable accounting platform, handling 80k Swiss customers with ease.",
            route: routes.BEXIO,
          },
          {
            src: findStaticImg(projectImgs, PROJECT_CONTENT_DATA.traderFyles.poster),
            projectName: "TraderFyles",
            description: "We provided TraderFyles with a dedicated Scala team to develop a complex application that ensures accurate and automated trader tax reports.",
            route: routes.TRADER_FYLES,
          },
        ]}
      />
      <SectionAnimation>
        <ExtendDevelopmentTeam />
      </SectionAnimation>

      <SectionAnimation>
        <Footer />
      </SectionAnimation>
    </StyledScalaContainer>
  );
};

export default React.memo(Scala);
