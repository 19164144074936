import React from "react";
import { theme } from "utility/theme";

interface LogoSVGProps {
  color?: string;
  width?: string | number;
  height?: string | number;
}

const LogoSVG = ({
  color = theme.colors.black,
  width = 107,
  height = 48,
}: LogoSVGProps): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 107 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M22.6684 6.62777L29.4662 22.734C29.7417 23.2932 30.0082 23.892 30.2704 24.526C31.5189 27.5333 33.0517 31.1658 34.5801 34.7895C35.8686 37.8408 37.1571 40.9009 38.6011 44.3748L40.0051 47.6991H39.9784L40.0539 47.884H31.5233L31.0746 46.8009C30.1549 44.595 28.7997 41.3764 27.4224 38.1093C26.0317 34.8115 24.6322 31.4828 22.9749 27.4893C22.6684 26.7452 21.6154 24.4688 21.1266 23.8832C20.86 23.5618 20.4557 23.3592 20.0025 23.3592C19.5049 23.3592 19.0695 23.6058 18.8074 23.9801C18.1676 24.9003 17.35 26.7187 16.888 27.8371L16.1638 29.5807C15.3951 31.4432 14.6176 33.3277 14.0489 34.675C13.067 36.9954 12.2272 38.9988 11.4897 40.782L8.52173 47.9677L0 47.9413L0.981907 45.5637C1.65725 43.9213 2.7458 41.2883 4.19422 37.8143C5.20279 35.3883 6.12694 33.1824 6.7623 31.6765C7.76642 29.3033 9.81466 24.6889 10.7077 22.6856C12.1917 19.3437 14.7642 16.4949 18.1142 15.7861L16.0216 10.8283L15.4218 9.56019C14.9241 8.64436 13.5779 7.91346 12.1695 7.91346L10.4322 7.89145V0.0540754L12.1428 0.00564865C17.2523 -0.126442 20.8645 2.05744 22.6684 6.62777Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M56.4932 39.6107C56.231 39.9849 55.7956 40.2315 55.298 40.2315C54.8448 40.2315 54.4405 40.0246 54.1739 39.7075C53.6852 39.1219 52.6366 36.8456 52.3256 36.1015C50.6684 32.1079 49.2688 28.7837 47.8781 25.4814C46.5008 22.2144 45.1457 18.9914 44.2259 16.7898L43.7772 15.7067H35.2466L36.2418 18.1063C37.8769 22.0514 39.3031 25.433 40.7248 28.8057C42.2533 32.4294 43.7861 36.0618 45.0346 39.0691C45.5766 40.3768 46.132 41.5128 46.7274 42.4947C48.7534 45.8233 51.4059 47.9412 55.5024 47.972C59.6788 48.0028 62.8689 44.7842 64.5973 40.9052C65.4903 38.8974 67.543 34.2874 68.5427 31.9142C69.1647 30.4392 70.0622 28.2949 71.0441 25.9349C72.026 28.2993 72.9235 30.4436 73.5455 31.9142C74.5497 34.2874 76.5979 38.9018 77.4909 40.9052C79.2148 44.7842 82.4094 48.0028 86.5858 47.972C90.6823 47.9412 93.3392 45.8233 95.3608 42.4947C95.9562 41.5128 96.5116 40.3768 97.0536 39.0691C98.3021 36.0618 99.835 32.4294 101.363 28.8057C102.785 25.4374 104.211 22.0514 105.846 18.1063L106.842 15.7067H98.311L97.8622 16.7898C96.9425 18.9958 95.5874 22.2144 94.2101 25.4814C92.8194 28.7837 91.4199 32.1079 89.7626 36.1015C89.456 36.8456 88.403 39.1219 87.9143 39.7075C87.6477 40.029 87.2434 40.2315 86.7947 40.2315C86.297 40.2315 85.8616 39.9849 85.5995 39.6107C85.2307 39.0823 84.5465 37.6161 84.2132 36.9336C84.0178 36.533 83.84 36.1367 83.6801 35.7536L82.9559 34.01C82.1872 32.1476 81.4097 30.2631 80.841 28.9158C79.8591 26.5954 79.0194 24.592 78.2818 22.8088L75.3139 15.623L71.0485 15.6363L66.7832 15.623L63.8153 22.8088C63.0733 24.592 62.2336 26.5954 61.2561 28.9158C60.6874 30.2631 59.9099 32.1476 59.1368 34.01L58.4126 35.7536C58.2526 36.1367 58.0749 36.533 57.8794 36.9336C57.5462 37.6205 56.8619 39.0867 56.4932 39.6107Z" fill={color} />
  </svg>
)

export default LogoSVG;
