import styled from "styled-components";

import { sizes, specificBreakpoints } from "utility/device";

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledReviewsImage = styled.div`
  width: 215px;
  height: 52px;

  iframe{
    height: inherit;
  }

  @media all and (max-width: ${specificBreakpoints.badgesMobile}) {
    width: 214px;
  }
`;
