import styled from "styled-components";
import StarVector from "src/images/star.svg";
import { sizes } from "utility/device";

export const StyledScaleYourTeamRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  gap: 20px;
`;

export const StyledScaleYourTeamRowMobile = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const StyledScaleYourTeamTextContainer = styled.div`
  margin-left: 98px;
  justify-content: center;

  display: flex;
  flex-direction: column;
  gap: 2vw;

  @media all and (max-width: ${sizes.m}) {
    margin-left: 0px;
  }

  @media all and (min-width: ${sizes.xl}) {
    margin-bottom: 63px;
  }
`;

export const StyledScaleYourTeamFirstRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-self: flex-start;

  @media all and (max-width: ${sizes.s}) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: 12px;
  }
`;

export const StyledScaleYourTeamTitle = styled.p`
  font-size: 36px;
  line-height: 109.8%;
  letter-spacing: -0.02em;
  margin-left: 2.083vw;

  @media all and (max-width: ${sizes.m}) {
    font-size: 32px;
  }

  @media all and (max-width: ${sizes.s}) {
    font-size: 20px;

    margin-right: 12px;
  }

  @media all and (min-width: ${sizes.xl}) {
    margin-left: 40px;
  }
`;

export const StyledScaleYourTeamParagraph = styled.div`
  max-width: 1116px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: max(2.083vw, 18px);
  line-height: max(2.5vw, 23.4px);

  @media all and (min-width: ${sizes.xl}) {
    font-size: 40px;
    line-height: 48px;
  }

  @media all and (max-width: ${sizes.s}) {
    font-size: 18px;
    line-height: 23.4px;
  }
`;

export const StarVectorStyled = styled(StarVector)`
  max-width: 261px;
  max-height: 261px;
  width: 13.594vw;
  height: 13.594vw;
  min-width: 108px;
  min-height: 108px;

  @media all and (max-width: ${sizes.s}) {
    width: 108px;
    height: 108px;
  }
`;
