import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import BlogApi from "services/Blogs.api";

import Layout from "src/components/Layout/layout";
import { Blog } from "src/components/Blog/blog";
import CookieConsent from "src/components/CookieConsent/cookieConsent";
import { theme } from "utility/theme";
import { capitalizeFirstLetters, mapBlogs } from "utility/functions";
import { IBlog, LocationPropI } from "utility/models";
import { DEFAULT_PAGE, BLOG_PAGE_SIZE } from "utility/constants";
import routes from "utility/routes";
import { BlogError } from "components/Blog/blogError";

interface PageProps {
  location: LocationPropI;
}

const blogApi = new BlogApi();

const BlogPage = ({ location }: PageProps): JSX.Element => {
  const queryTag = decodeURI(queryString.parse(location.search)?.tag as string ?? "");
  const [menuColor, setMenuColor] = useState(theme.colors.white);
  const [isCookieVisible, setCookieVisible] = useState<boolean>(false);
  const [blogs, setBlogs] = useState<IBlog[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [isEndReached, setEndReached] = useState(false);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [isFetchingError, setFetchingError] = useState(false);
  const [isTagError, setTagError] = useState(false);

  const handleColor = (isMenuOpened: boolean) => {
    isMenuOpened
      ? setMenuColor(theme.colors.black)
      : setMenuColor(theme.colors.white);
  };

  const breakpoints = useBreakpoint();
  const isDesktop = breakpoints.l;
  const locationPath = location.pathname;
  const tag = queryString.parse(location.search)?.tag ?? null;

  useEffect(() => {
    if (isDesktop) {
      setMenuColor(theme.colors.white);
    }
  }, [isDesktop]);

  const resetData = (): void => {
    setPage(DEFAULT_PAGE);
    setLoading(false);
    setEndReached(false);
    setFetchingError(false);
    setTagError(false);
    setBlogs([]);
  }

  const fetchBlogs = async (page = DEFAULT_PAGE, tag = queryTag) => {
    const pagination = { pageSize: BLOG_PAGE_SIZE, page };
    if (isLoading) return;
    setLoading(true);
    setFetchingError(false);
    setTagError(false);
    try {

      const response = await blogApi.getBlogs(!!tag ? {
        pagination,
        filters: { tags: { tag: { eq: tag } } },
      } : {
        pagination
      });
      const data = mapBlogs(response?.data?.blogs?.data ?? []);
      if (!data?.length && page === DEFAULT_PAGE) {
        !!tag ? setTagError(true) : setFetchingError(true);
        setEndReached(true);
        setBlogs([]);
      } else {
        setBlogs(page === DEFAULT_PAGE ? data : [...blogs, ...data]);
        data.length < BLOG_PAGE_SIZE ? setEndReached(true) : setEndReached(false);
      }
    } catch (err) {
      setFetchingError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    resetData();
    fetchBlogs();
  }, [location.search]);

  useEffect(() => {
    const handleScroll = () => {
      const {
        scrollTop,
        clientHeight,
        scrollHeight,
      } = document.documentElement;
      const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight - clientHeight;
      if (isScrolledToBottom && !isEndReached && !isLoading) {
        const newPage = page + 1;
        fetchBlogs(newPage, queryTag);
        setPage(newPage);
      }
    }
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading, isEndReached, queryTag]);

  const handleVisibility = (isVisible: boolean) => {
    setCookieVisible(isVisible);
  };

  return (
    <Layout
      backgroundColor={menuColor}
      headerColor={theme.colors.black}
      setBackgroundColor={handleColor}
      location={locationPath}
      headerRoute={routes.BLOG}
      isCookieVisible={isCookieVisible}
      isGetInTouchHidden
    >
      {isTagError ? (
        <BlogError type="tag" location={location} />
      ) : (
        <Blog
          blogs={blogs}
          loading={isLoading}
          isError={isFetchingError}
          tag={tag as string | null}
          title={!!tag ? `${capitalizeFirstLetters(tag as string)} Articles` : null}
        />
      )}
      <CookieConsent getCookieVisibility={handleVisibility} />
    </Layout>
  );
};

export default BlogPage;
