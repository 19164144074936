import React from "react";

import { aiProcessingList } from "utility/lists";
import DottedList from "src/components/DottedList/dottedList";

import {
  CustomizedApproachContainer,
} from "./customizedApproachStyle";

const CustomizedApproach = (): JSX.Element => {
  return (
    <CustomizedApproachContainer>
      <DottedList
        title="Customized approach to creating AI knowledge bots."
        list={aiProcessingList}
      />
    </CustomizedApproachContainer>
  );
};

export default React.memo(CustomizedApproach);
