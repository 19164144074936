import React, {lazy, Suspense} from "react";

import Footer from "src/components/Footer/footer";
import SectionAnimation from "src/components/SectionAnimation/sectionAnimation";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import TechStack from "./TechStack/techStack";
import OurCapabilities from "./OurCapabilities/ourCapabilities";
import Hero from "components/Hero/hero";
import HeroParagraph from "components/HeroParagraph/heroParagraph";
import ScaleYourTeam from "./ScaleYourTeam/scaleYourTeam";
import { PageContainer } from "src/styles/globalStyle";
import { StyledSeparator } from "components/Layout/layoutStyle";

const LetsTalkSection = lazy(() => import("src/components/LetsTalkSection/letsTalkSection"))
const WorkAndEngagements = lazy(() => import("./WorkAndEngagements/workAndEngagements"))

const TechExcellence = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMediumScreen = breakpoints.m;

  return (
    <PageContainer>
      <SectionAnimation>
        <Hero
          title={isMediumScreen ? (
            <>
              Forward.
              <div>Digital. Thinking.&nbsp;</div>
            </>
          ) : (
            <>
              Forward.
              <br />
              Digital.
              Thinking.
              <br />
              <br />
            </>
          )}
          image={{
            src: "hero/techExcellenceHero.png",
            mobileSrc: "hero/techExcellenceHeroMobile.png",
          }}
        />
        <HeroParagraph
          text="We tailor our engagements to meet you where you are. Whether you’re looking for technology expertise or you're looking for a scalable team to complement the in-h ouse team."
        />
      </SectionAnimation>
      
      <SectionAnimation>
        <TechStack />
      </SectionAnimation>

      {!isMediumScreen ? (
        <StyledSeparator />
      ) : (
        <div />
      )}
      
      <SectionAnimation>
        <OurCapabilities />
      </SectionAnimation>

      <StyledSeparator />

      <Suspense fallback={<div></div>}>
        <SectionAnimation>
          <WorkAndEngagements />
        </SectionAnimation>
      </Suspense>

      <StyledSeparator />

      <Suspense fallback={<div></div>}>
        <SectionAnimation>
          <ScaleYourTeam />
        </SectionAnimation>
      </Suspense>

      <div />

      <Suspense fallback={<div></div>}>
        <SectionAnimation>
          <LetsTalkSection
            link="/contact"
            title={"Partnering for\ninnovation."}
            mobileTitle={"Partnering\nfor\ninnovation."}
            paragraph={
              "From concepts to fully operational products, we stand as a strategic partner for companies we truly believe in."
            }
            question={isMediumScreen ? <div>Want to work<br />together?</div> : "Want to work together?"}
            buttonText="Let's talk"
            page="enterprises"
          />
        </SectionAnimation>
      </Suspense>

      {isMediumScreen && <div />}

      <Footer />
    </PageContainer>
  );
};

export default React.memo(TechExcellence);
