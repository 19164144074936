import styled from "styled-components";

import { sizes } from "utility/device";
import { ColumnContainer, genCustomPropsStyle, genTextStyle, MediumHeading } from "src/styles/globalStyle";

export const StyledContainer = styled.div`
  padding-bottom: 60px;
  padding-top: 60px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 32px;

  @media (max-width: ${sizes.mediumS}) {
    flex-direction: column;
  }
`;

export const StyledTitle = styled(MediumHeading)`
  flex: 1;
  max-width: 663px;
  width: 304vw;
  min-width: 255px;

  @media (max-width: ${sizes.mediumS}) {
    width: 100%;
  }
`;

export const StyledLstContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1090px;
  width: 63vw;
  box-sizing: border-box;
  border: 2px solid black;
  border-radius: 40px;
  padding: 40px 80px;
  gap: 24px;
  ${genTextStyle({
    maxFontSize: 24,
    maxLineHeight: 28.8,
    minFontSize: 18,
    minLineHeight: 23.4,
    isCustomised: true,
  })}

  @media (max-width: ${sizes.m}) {
    width: 100%;
    padding: 20px;
  }
`;

export const StyledLi = styled.li`
  font-size: 30px;
  line-height: 36px;

  @media (max-width: ${sizes.m}) {
    font-size: 18px;
    line-height: 23.4px;
  }
`;
