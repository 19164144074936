import styled from "styled-components";
import { isSafari } from "react-device-detect";
import { animated } from "@react-spring/web";
import { sizes } from "utility/device";
import { Link } from "gatsby";
import { genSizeStyle, genTextStyle } from "src/styles/globalStyle";

export const StyledClientContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media all and (max-width: ${sizes.m}) {
    flex-direction: column;
  }
`;

export const StyledClientMiddleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  @media all and (max-width: ${sizes.m}) {
    flex-direction: column;
  }
`;

export const StyledClientHeroContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;

  @media all and (max-width: ${sizes.m}) {
    flex-direction: column;
  }
`;

export const StyledClientHeroTitle = styled.div`
  flex: 2;
  font-size: 5vw;
  line-height: 106.8%;
  white-space: pre-wrap;

  @media all and (min-width: ${sizes.xl}) {
    font-size: 96px;
    line-hight: 108px;
  }

  @media all and (max-width: ${sizes.m}) {
    margin-bottom: 20px;
    font-size: 46px;
    line-height: 49px;
    letter-spacing: -0.01em;
  }

  @media all and (max-width: ${sizes.s}) {
    font-size: 36px;
    line-height: 39px;
  }
`;

export const StyledClientListContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 8%;
  gap: 60px;
  height: fit-content;

  @media all and (max-width: ${sizes.m}) {
    flex-direction: column;
    padding-left: 0px;
    gap: 20px;
    margin-top: 0px;
  }
`;

export const StyledClientListImage = styled(animated.div)<{ src?: string }>`
  position: relative;
  background-image: ${({ src }) => `url(/${src})`};
  background-size: ${isSafari ? "cover" : "contain"};
  background-repeat: no-repeat;
  align-self: flex-start;

  display: flex;
  justify-content: center;
  width: 420px;
  height: 560px;
  border-radius: 80px;

  @media all and (max-width: ${sizes.m}) {
    border-radius: 50px;
    width: 300px;
    height: 400px;
    clip-path: polygon(0 0, 100% 1%, 98% 50%, 0 50%);
  }
`;

export const StyledClientList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-start;
  width: 100%;
  max-width: 1140px;
  position: relative;

  @media all and (max-width: ${sizes.m}) {
    justify-content: flex-start;
    margin-top: -220px;
  }
`;

export const LinkWrapper = styled(Link)`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media all and (max-width: ${sizes.m}) {
    align-self: center;
  }
`;

export const StyledClientListTitle = styled.div`
  font-size: 1.25vw;
  margin-bottom: 30px;
  display: flex;
  opacity: 0.3;

  left: 0;
  position: absolute;
  margin-top: -5vw;

  @media all and (max-width: ${sizes.m}) {
    font-size: 18px;
    margin-top: -50px;
  }

  @media all and (min-width: ${sizes.xl}) {
    font-size: 24px;
    line-height: 28.8px;
    margin-top: -75px;
  }
`;

export const StyledClientListTitleMobile = styled.div`
  font-size: 18px;
  display: none;
  align-self: start;
  color: ${({ theme }) => theme.colors.black};

  @media all and (max-width: ${sizes.m}) {
    display: flex;
  }
`;

export const StyledClientItem = styled(Link)<{ selected?: boolean }>`
  border-bottom: 2px solid ${(props) => props.theme.colors.black};
  height: 68px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledViewProjectIndicator = styled.span`
  flex-wrap: no-wrap;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  gap: 16px;
`

export const StyledViewProjectText = styled.span`
  color: ${(props) => props.theme.colors.black};
  ${genTextStyle({
    maxFontSize: 18,
    maxLineHeight: 21.6,
    minFontSize: 14,
    minLineHeight: 16,
  })}
`

export const StyledViewProjectCircle = styled.div`
  border-radius: 100%;
  background: ${(props) => props.theme.colors.black};
  ${genSizeStyle({
    maxHeight: 24,
    maxWidth: 24,
    minHeight: 16,
    minWidth: 16,
  })}
`

export const ListInnerWrapper = styled.div<{ isOpened?: boolean }>`
  position: relative;
  overflow-y: hidden;
  max-height: 560px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const ExpanderStyled = styled(Link)`
  margin-top: 50px;
  cursor: pointer;
  line-height: 24px;
  font-size: 18px;
  text-decoration: underline;
  text-underline-offset: 14px;

  @media all and (max-width: ${sizes.minM}) {
    margin-top: 28px;
  }
`;
