import React from "react";

import {
  LogoListContainer,
  LogoListTitle,
  LogoWrapper,
  Wrapper,
} from "./logoListStyle";

interface LogoListProps {
  heading?: string | JSX.Element;
  logos: JSX.Element[];
  breakOnSmallDevices?: boolean;
}

export const LogoList = ({ heading, logos, breakOnSmallDevices = false }: LogoListProps) => (
  <LogoListContainer>
    <LogoListTitle>{heading}</LogoListTitle>
    <Wrapper breakOnSmallDevices={breakOnSmallDevices}>
      {logos.map((logo) => (
        <LogoWrapper>{logo}</LogoWrapper>
      ))}
    </Wrapper>
  </LogoListContainer>
);
