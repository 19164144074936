import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import {
  BlogBody,
  MainContainer,
  TagsWrapper,
  UpperContainer,
} from "./singleBlogStyle";
import { BlogTag } from "../Tag/tag";
import { BlogNameAndDate } from "../NameAndDate/nameAndDate";
import { Separator } from "../blogStyle";
import Footer from "src/components/Footer/footer";
import { StyledSeparator } from "src/components/Layout/layoutStyle";
import { IBlogWithSuggestions, LocationPropI } from "utility/models";
import { SubscribeForm } from "components/SubscribeForm/subscribeForm";
import { BlogSuggestions } from "../BlogSuggestions/blogSuggestions";
import { PageContainer } from "src/styles/globalStyle";

interface ISingleBlog {
  blog: IBlogWithSuggestions | null;
  location: LocationPropI;
}

export const SingleBlog = ({ blog, location }: ISingleBlog): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMobile = !breakpoints.midS;

  return (
    <PageContainer>
      <MainContainer>
        <UpperContainer>
          <h2>{blog?.title ?? ""}</h2>
          {blog?.publishedAt &&
            <BlogNameAndDate
              date={blog?.publishedAt}
              creator={blog?.creator ?? ""}
              isNodePage
            />
          }
          {!!blog?.tags.length && (
            <TagsWrapper>
              {(blog?.tags ?? []).map((item) => (
                <BlogTag tag={item.tag} />
              ))}
            </TagsWrapper>
          )}
          <Separator />
        </UpperContainer>
        <BlogBody dangerouslySetInnerHTML={{ __html: blog?.content ?? "" }} />
        <BlogSuggestions blog={blog} location={location} />
        {isMobile && (
          <StyledSeparator />
        )}
        <SubscribeForm />
      </MainContainer>

      <StyledSeparator />
      
      <Footer />
    </PageContainer>
  );
};
