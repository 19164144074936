import styled from "styled-components";
import { genCustomPropsStyle } from "src/styles/globalStyle";

export const StyledWrapper = styled.div`
  ${genCustomPropsStyle({
    props: [
      { propName: "padding-top", maxSize: 120, minSize: 32 },
      { propName: "padding-bottom", maxSize: 120, minSize: 32 },
    ]
  })}
`;
