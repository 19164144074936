import React from "react";

import EnterprisesMobileSvg from "src/images/partneringForInnovationMobile.svg";
import CareersMobileSvg from "src/images/careersTalkSectionMobile.svg";
import ProductInnovationMobileSvg from "src/images/moreThanJustCodeMobile.svg";
import KnowleMobileLogo from "src/images/knowleMobileLogo.svg";
import RockerMobile from "src/images/ctaSeesaw.svg";
import ScalaMobileSvg from "src/images/scalaIconMobile.svg";
import ScalaSvg from "src/images/scalaIcon.svg";

import {
  StyledContentContainer,
  StyledLetsTalkContainer,
  StyledLetsTalkLine,
  StyledLetsTalkParagraph,
  StyledLetsTalkQuestions,
  StyledProductInnovationSVG,
  StyledEnterprisesSVG,
  StyledCareersSVG,
  StyledKnowleSVGContainer,
  StyledKnowleLogoSVG,
  StyledKnowleTitleSVG,
  StyledTitleMobileContainer,
  StyledContentSVGContainer,
  StyledMobileSVGContainer,
} from "./letsTalkSectionStyle";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import Button from "components/Button/button";
import { navigate } from "gatsby";
import RockerSVG from "src/svg/rocker";

const SVG_MAPPER: Record<string, JSX.Element> = {
  "startups": <StyledProductInnovationSVG />,
  "enterprises": <StyledEnterprisesSVG />,
  "careers": <StyledCareersSVG />,
  "potential": <RockerSVG width={438} height={260} />,
  "knowle": <StyledKnowleSVGContainer><StyledKnowleLogoSVG /><StyledKnowleTitleSVG /></StyledKnowleSVGContainer>,
  "scala": <ScalaSvg />,
}

const SVG_MAPPER_MOBILE: Record<string, JSX.Element> = {
  "startups": <ProductInnovationMobileSvg />,
  "enterprises": <EnterprisesMobileSvg />,
  "careers": <CareersMobileSvg />,
  "potential": <RockerMobile width={68.5} height={58.5} />,
  "knowle": <KnowleMobileLogo width={69} height={70} style={{ display: "flex", flexShrink: 0 }} />,
  "scala": <ScalaMobileSvg />,
}

type LetsTalkProps = {
  title: string;
  mobileTitle?: string | JSX.Element;
  paragraph?: string;
  paragraphFontSize?: number | string;
  maxParagraphWidth?: string;
  question?: any;
  buttonText?: string;
  page?: "startups" | "enterprises" | "careers" | "knowle" | "potential" | "scala";
  limitParagraph?: boolean;
  link: string;
  noOpenings?: boolean;
  mobileButtonFontSize?: number;
};

const LetsTalkSection = ({
  title,
  mobileTitle,
  paragraph,
  paragraphFontSize,
  question,
  buttonText = "Let's talk",
  maxParagraphWidth,
  limitParagraph = true,
  page = "startups",
  link = "/",
  noOpenings,
  mobileButtonFontSize = 24,
}: LetsTalkProps): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMediumScreen = breakpoints.m;
  
  return (
    <StyledLetsTalkContainer isCentered={noOpenings}>
      <StyledContentContainer>
        {!isMediumScreen && (page === "potential" || page === "scala") && (
          <StyledContentSVGContainer>
            {SVG_MAPPER_MOBILE[page]}
          </StyledContentSVGContainer>
        )}
        {isMediumScreen ? (
          <h1>{title}</h1>
        ): (
            <StyledTitleMobileContainer>
              <h2>{mobileTitle ?? title}</h2>
              <StyledMobileSVGContainer>
                {page !== "potential" && page !== "scala" && (
                  SVG_MAPPER_MOBILE[page]
                )}
              </StyledMobileSVGContainer>
              
            </StyledTitleMobileContainer>
        )}
        
        {!!paragraph && (
          <StyledLetsTalkParagraph
            limitParagraph={limitParagraph}
            fontSize={paragraphFontSize}
            maxParagraphWidth={maxParagraphWidth}
          >
            {paragraph}
          </StyledLetsTalkParagraph>
        )}

        {!noOpenings && (
          <StyledLetsTalkLine>
            {!!question && (
              <StyledLetsTalkQuestions>{question}</StyledLetsTalkQuestions>
            )}
            <Button
              onClick={() => navigate(link)}
              text={buttonText}
              fontSize={36}
              centerContent
              justifyContent="center"
              mobileFontSize={24}
              isfullwidth={!isMediumScreen}
              customPadding={isMediumScreen ? {
                paddingTopBottom: 8,
                paddingLeftRight: 8,
                paddingLeftRightMobile: 8,
                paddingTopBottomMobile: 8,
              } : {}}
            />
          </StyledLetsTalkLine>
        )}
      </StyledContentContainer>
      {isMediumScreen && SVG_MAPPER[page]}
    </StyledLetsTalkContainer>
  )
}

export default React.memo(LetsTalkSection);
